// region imports

import {combineReducers} from "redux";
import {confirmReducer} from "./confirm/confirmReducer";
import {inputReducer} from "./input/inputReducer";
import {DialogsState} from "./DialogsState";
import {alertReducer} from "./alert/alertReducer";
import {editReducer} from "./edit/editReducer";
import {selectImageReducer} from "./selectImage/selectImageReducer";
import {testReducer} from "./test/testReducer";
import {applicationDialogReducer} from "./applicationDialog/applicationDialogReducer";

// endregion

// region exports

/**
 * Store reducer for all dialogs.
 */
export const dialogsReducer = combineReducers<DialogsState>({
  confirm: confirmReducer,
  input: inputReducer,
  alert: alertReducer,
  edit: editReducer,
  selectImage: selectImageReducer,
  test: testReducer,
  applicationDialog: applicationDialogReducer,
});

// endregion
