// region imports

import {PropertyGroup} from "../PropertyGroup";
import {Box, InputLabel, Link, Stack} from "@mui/material";
import * as React from "react";
import {TT} from "../../TT";
import {UFThunkPropsType} from "../../../UF-react/tools/UFRedux";
import {connect} from "react-redux";
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import {LandingPageStoreActions} from "../../../store/landingPage/LandingPageStoreActions";
import {ConfirmStoreActions} from "../../../store/dialogs/confirm/ConfirmStoreActions";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { ApplicationButton } from "../../controls/ApplicationButton";
import {ApplicationState} from "../../../store/ApplicationState";
import {EditorMode} from "../../../constants/EditorMode";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {UserStoreActions} from "../../../store/user/UserStoreActions";
import {MainController} from "../../../controllers/MainController";
import { Config } from "../../../constants/Config";
import {UFCopyBotClipboardButton} from "../../../UF-mui/components/controls/UFCopyToClipboardButton";
import {ApplicationDialogStoreActions} from "../../../store/dialogs/applicationDialog/ApplicationDialogStoreActions";
import {ApplicationDialogType} from "../../../constants/ApplicationDialogType";

// endregion

// region local types

interface LandingPageActionsProps {
  readonly confirmPopup: UFThunkPropsType<typeof ConfirmStoreActions.popup>;
  readonly setLocation: typeof UserStoreActions.setLocation;
  readonly reset: typeof LandingPageStoreActions.reset;
  readonly show: typeof ApplicationDialogStoreActions.show;
  readonly dirty: boolean;
  readonly mode: EditorMode;
  readonly landingPageCode: string;
  readonly webmasterCode: string;
}

function mapStateToProps(anApplicationState: ApplicationState) {
  return {
    dirty: anApplicationState.landingPage.dirty,
    mode: anApplicationState.editor.mode,
    landingPageCode: anApplicationState.landingPage.configuration.urlCode,
    webmasterCode: anApplicationState.user.urlCode
  }
}

// endregion

// region component

/**
 * Shows the actions for the landing page.
 */
export const LandingPageActions = connect(
  mapStateToProps,
  {
    confirmPopup: ConfirmStoreActions.popup,
    reset: LandingPageStoreActions.reset,
    setLocation: UserStoreActions.setLocation,
    show: ApplicationDialogStoreActions.show,
  }
)(
  class extends React.Component<LandingPageActionsProps> {
    // region event handlers

    private async handleChangeCampaignClick() {
      if (
        !this.props.dirty ||
        await this.props.confirmPopup(
          <TT ttid="switch-campaign.title">Confirm campaign switch</TT>,
          <TT ttid="switch-campaign.text">There are unsaved changes. Continue with campaign switch?</TT>,
          <TT>Yes</TT>,
          <TT>No</TT>
        )
      ) {
        this.props.show(ApplicationDialogType.SelectCampaign);
      }
    }

    private async handleResetClick() {
      const result = await this.props.confirmPopup(
        <TT ttid="reset.title">Confirm reset</TT>,
        <TT ttid="reset.text">Resetting the landing page will remove all modules, titles and images. Continue?</TT>,
        <TT>Yes</TT>,
        <TT>No</TT>
      );
      if (result) {
        MainController.instance.resetLandingPage();
      }
    }

    private async handleChangeTemplateClick() {
      if (
        !this.props.dirty ||
        await this.props.confirmPopup(
          <TT ttid="switch-template.title">Confirm selecting template</TT>,
          <TT ttid="switch-template.text">There are unsaved changes. Continue with selecting a template?</TT>,
          <TT>Yes</TT>,
          <TT>No</TT>
        )
      ) {
        this.props.show(ApplicationDialogType.SelectCampaign);
      }
    }

    private handleDeleteTemplateClick() {
      this.props.show(ApplicationDialogType.DeleteTemplate);
    }

    private handleCopyLandingPageClick() {
      this.props.show(ApplicationDialogType.SelectTargetCampaign);
    }

    // endregion

    // region react callbacks

    render() {
      const isTemplate = this.props.mode == EditorMode.Template;
      const url = Config.urlDomain + this.props.webmasterCode + '/' + this.props.landingPageCode;
      return (
        <PropertyGroup title={<TT>Actions</TT>}>
          {
            !isTemplate &&
            <Box>
              <InputLabel shrink={true} sx={{marginBottom: '-0.5em'}}>
                <TT ttid="url.label">Url (click to open in a new tab)</TT>
              </InputLabel>
              <Link
                sx={{cursor: 'pointer'}}
                href={url}
                target="_blank"
              >
                {url}
              </Link>
            </Box>
          }
          <Stack direction="row" gap={2} flexWrap="wrap">
            {
              !isTemplate &&
              <UFCopyBotClipboardButton
                startIcon={<NoteAltOutlinedIcon/>}
                variant="contained"
                size="small"
                value={url}
                message={<TT ttid="url-copy.message">The url has been copied to the clipboard.</TT>}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom'
                }}
              >
                <TT ttid="url-copy.caption">Copy URL</TT>
              </UFCopyBotClipboardButton>
            }
            <ApplicationButton
              color="error"
              startIcon={<ClearAllIcon/>}
              onClick={async () => await this.handleResetClick()}
            >
              <TT ttid="reset.caption">Reset landing page</TT>
            </ApplicationButton>
            {
              !isTemplate &&
              <ApplicationButton
                startIcon={<SwapHorizIcon/>}
                onClick={async () => await this.handleChangeCampaignClick()}
              >
                <TT ttid="change-landing-page.caption">Switch landing page</TT>
              </ApplicationButton>
            }
            {
              !isTemplate &&
              <ApplicationButton
                startIcon={<FileCopyIcon />}
                onClick={() => this.handleCopyLandingPageClick()}
              >
                <TT ttid="change-landing-page.caption">Copy landing page</TT>
              </ApplicationButton>
            }
            {
              isTemplate &&
              <ApplicationButton
                startIcon={<DeleteOutlineIcon/>}
                color="error"
                onClick={() => this.handleDeleteTemplateClick()}
              >
                <TT ttid="delete-template.caption">Delete template</TT>
              </ApplicationButton>

            }
            {
              isTemplate &&
              <ApplicationButton
                startIcon={<SwapHorizIcon/>}
                onClick={async () => await this.handleChangeTemplateClick()}
              >
                <TT ttid="change-template.caption">Select template</TT>
              </ApplicationButton>

            }
          </Stack>
        </PropertyGroup>
      );
    }

    // endregion
  }
);

// endregion