/**
 *  Scoring theme.
 */

import React from "react";
import {createTheme} from "@mui/material";
import {common, green, grey, orange, red} from "@mui/material/colors";

declare module '@mui/material/styles' {

  interface Theme {
    warning: {
      backgroundColor: React.CSSProperties['color'],
      textColor: React.CSSProperties['color']
    }
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    warning?: {
      backgroundColor?: React.CSSProperties['color'],
      textColor?: React.CSSProperties['color']
    }
  }
}

export const appTheme = createTheme({
    palette: {
      primary: {
        main: '#0195DD',
        contrastText: common.white
      },
      secondary: {
        main: green[500],
        contrastText: common.white
      },
      error: red,
      text: {
        disabled: grey[500],
        //hint: grey[600],
        primary: grey[900],
        secondary: grey[600]
      },
    },
    warning: {
      backgroundColor: orange[300],
      textColor: common.white
    },
    typography: {
      body1: {
        fontWeight: 300
      },
      h1: {
        fontWeight: 900,
        fontSize: '2.5rem'
      },
      h3: {
        fontWeight: 700,
        fontSize: '1.75rem'
      }
    },
    mixins: {
      toolbar: {
        '@media (min-width:0px) and (orientation: landscape)': {
          minHeight: '44px'
        }
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#4D76A2',
            color: common.white,
            height: '100%',
            margin: 0,
            padding: 0,
            overflow: 'hidden'
          },
          html: {
            height: '100%'
          },
          '#root': {
            height: '100%'
          }
        }
      }
    }
  })
;
