// region imports

import * as React from 'react';
import {DialogContent, DialogTitle} from "@mui/material";
import {Config} from "../../constants/Config";
import {UFDialog} from "../../UF-mui/components/dialogs/UFDialog";

// endregion

// region local types

interface DialogPageProps {
  /**
   * Title to show.
   */
  title?: React.ReactNode;

  /**
   * Minimal width
   */
  width?: string;
}

// region component

/**
 * {@link DialogPage} shows a dialog styled content in the center of the page.
 */
export const DialogPage: React.FC<DialogPageProps> = (
  {
    title,
    children,
    width = '400px'
  }
) => (
  <UFDialog
    open={true}
    modal={false}
    fullWidth={false}
    maxWidth={false}
    PaperProps={{style: {width: width}}}
  >
    {title && <DialogTitle>{title}</DialogTitle>}
    <DialogContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: Config.propertiesGapSize
      }}
    >
      {children}
    </DialogContent>
  </UFDialog>
);

// endregion
