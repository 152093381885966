// region imports

import {PropertyGroup} from "../PropertyGroup";
import {MenuItem} from "@mui/material";
import * as React from "react";
import {UFFormSelect} from "../../../UF-mui/components/form/UFFormSelect";
import {FontFamily} from "../../../constants/FontFamily";
import {LandingPageFont} from "../../../types/LandingPageFont";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {UFFormNumberSliderField} from "../../../UF-mui/components/form/UFFormNumberSliderField";
import {TT} from "../../TT";
import {LandingPageStoreActions} from "../../../store/landingPage/LandingPageStoreActions";

// endregion

// region local types and functions

/**
 * Properties for the component.
 */
interface LandingPageFontPropertiesProps {
  font: LandingPageFont;
  setFont: typeof LandingPageStoreActions.setFont;
}

/**
 * Maps application state to component properties.
 */
function mapStateToProps(state: ApplicationState) {
  return {
    font: state.landingPage.font
  };
}

// endregion

// region component

/**
 * The landing page font properties.
 */
export const LandingPageFontProperties = connect(mapStateToProps, {setFont: LandingPageStoreActions.setFont})(
  ({font, setFont}: LandingPageFontPropertiesProps) => (
    <PropertyGroup title={<TT ttid="font-properties.title">Font</TT>}>
      <UFFormSelect
        label={<TT ttid="font-properties.family">Font family</TT>}
        onFieldChange={({value}) => setFont({
          ...font,
          family: value
        })}
        value={font.family}
        sx={{
          fontFamily: font.family
        }}
      >
        <MenuItem value={FontFamily.Arial} sx={{fontFamily: FontFamily.Arial}}>Arial</MenuItem>
        <MenuItem value={FontFamily.Verdana} sx={{fontFamily: FontFamily.Verdana}}>Verdana</MenuItem>
        <MenuItem value={FontFamily.OpenSans} sx={{fontFamily: FontFamily.OpenSans}}>Open Sans</MenuItem>
        <MenuItem value={FontFamily.Montserrat} sx={{fontFamily: FontFamily.Montserrat}}>Montserrat</MenuItem>
        <MenuItem value={FontFamily.Roboto} sx={{fontFamily: FontFamily.Roboto}}>Roboto</MenuItem>
        <MenuItem value={FontFamily.RobotoSlab} sx={{fontFamily: FontFamily.RobotoSlab}}>Roboto Slab</MenuItem>
      </UFFormSelect>
      <UFFormNumberSliderField
        label={<TT ttid="font-properties.size">Size</TT>}
        leftLabel="$minValue$px"
        rightLabel="$maxValue$px"
        value={font.size}
        showInput={true}
        minValue={6}
        maxValue={150}
        onFieldChange={({value}) => setFont({...font, size: value})}
      />
    </PropertyGroup>
  )
);