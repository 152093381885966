// region imports

import * as React from 'react';
import {useState} from 'react';
import {UFFormTextField} from "../../UF-mui/components/form/UFFormTextField";
import {toHex} from "color2k";
import {Box, ClickAwayListener, Paper, Popper} from "@mui/material";
import {HexColorInput, HexColorPicker, RgbaStringColorPicker} from "react-colorful";
import {UFKeyboard} from "../../UF-react/components/UFKeyboard";

// endregion

// region local types and functions

/**
 * Properties for the component
 */
interface ColorFieldProps {
  /**
   * True to hide control
   */
  hidden?: boolean;

  /**
   * Label to show above input field
   */
  label: React.ReactNode,

  /**
   * Value to edit
   */
  value: string;

  /**
   * True to include alpha
   */
  alpha?: boolean;

  /**
   * Callback that is called whenever the value changes.
   */
  onChange: (value: string) => void;
}

// endregion

// region component

/**
 * {@link ColorField} shows an input field to edit a color. The input field will show the color. Clicking it will show
 * a color picker popup.
 */
export const ColorField = ({label, value, onChange, alpha = false, hidden = false}: ColorFieldProps) => {
  const [color, setColor] = useState(value);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const handleChange = (newColor: string) => {
    setColor(newColor);
    onChange(newColor);
  }
  // cut off any alpha
  const rgb = toHex(color).substring(0, 7);
  const open = Boolean(anchorElement);
  return (
    <ClickAwayListener onClickAway={() => setAnchorElement(null)}>
      <Box
        sx={{
          width: '100%'
        }}
      >
        <UFKeyboard keys={UFKeyboard.ESCAPE} onDown={() => setAnchorElement(null)} />
        <UFFormTextField
          label={label}
          value={rgb}
          readOnly={true}
          type="color"
          onClick={(event) => {
            event.preventDefault();
            setAnchorElement(open ? null : event.currentTarget);
          }}
        />
        <Popper open={open} anchorEl={anchorElement}>
          <Paper
            sx={{
              padding: '0.5rem',
              boxShadow: '0 0 8px -1px rgba(0,0,0,0.5)'
            }}
          >
            {!alpha &&
              <HexColorPicker color={color} onChange={handleChange}/>
            }
            {alpha &&
              <RgbaStringColorPicker color={color} onChange={handleChange}/>
            }
            {!alpha &&
              <HexColorInput
                color={color}
                onChange={handleChange}
                alpha={false}
                prefixed={true}
                style={{
                  marginTop: '0.5rem',
                  marginBottom: '2px',
                  width: '100%',
                  borderRadius: '4px',
                  fontSize: '16px',
                  border: '1px solid #cccccc',
                  padding: '4px'
                }}
              />
            }
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  )
};

// endregion