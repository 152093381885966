// region imports

import * as React from 'react';
import {Button, Typography} from "@mui/material";

// endregion

// region local types and functions

interface ModuleButtonProps {
  onClick: () => any;
  caption: React.ReactNode;
}

// endregion

// region component

export const ModuleButton: React.FC<ModuleButtonProps> = (
  {onClick, caption}
) => (
  <Button
    sx={{
      width: '125px',
      height: '125px'
    }}
    onClick={onClick}
    variant="contained"
  >
    <Typography variant="caption">{caption}</Typography>
  </Button>
);

// endregion