// region imports

import * as React from 'react';
import {DialogPage} from "./DialogPage";
import {SelectCampaignDialog} from "../dialogs/SelectCampaignDialog";

// endregion

// region component

export const SelectCampaignPage: React.FC = () => {
  return (
    <SelectCampaignDialog dialog={false} />
  );
};

// endregion
