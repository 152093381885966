// region Imports

import {ReducerActionType} from "../../ReducerActionType";
import {TT} from "../../../components/TT";
import {InputState} from "./InputState";
import {InputStoreActions} from "./InputStoreActions";

// endregion

// region Local variables

/**
 * Initial system store state
 */
const initialState: InputState = {
  show: false,
  title: '',
  content: '',
  value: '',
  allowEmpty: false,
  ok: <TT>Ok</TT>,
  cancel: <TT>Cancel</TT>,
  callback: (value: string | false) => {}
};

// endregion

// region local types

/**
 * All input action models.
 */
type InputAction = ReturnType<typeof InputStoreActions.show> | ReturnType<typeof InputStoreActions.hide>;

// endregion

// region exports

/**
 * Reduces the input store to a new state.
 *
 * @param aState
 *   Current state
 * @param anActionData
 *   Action to apply to the store
 *
 * @return new state
 */
export function inputReducer(
  aState: InputState = initialState, anActionData: InputAction
): InputState {
  switch(anActionData.type) {
    case ReducerActionType.ShowInput:
      return {
        show: true,
        title: anActionData.title,
        content: anActionData.content,
        value: anActionData.value,
        allowEmpty: anActionData.allowEmpty,
        ok: anActionData.ok,
        cancel: anActionData.cancel,
        callback: anActionData.callback
      };
    case ReducerActionType.HideInput:
      return {
        ...aState,
        show: false
      };
    default:
      return aState;
  }
}

// endregion