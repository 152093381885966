// region Imports

import {SelectImageState} from "./SelectImageState";
import {ReducerActionType} from "../../ReducerActionType";
import {SelectImageStoreActions} from "./SelectImageStoreActions";

// endregion

// region Local variables

/**
 * Initial system store state
 */
const initialState: SelectImageState = {
  show: false,
  callback: (value) => {
  }
};

// endregion

// region local types

/**
 * All input action models.
 */
type NewModuleAction = ReturnType<typeof SelectImageStoreActions.show> | ReturnType<typeof SelectImageStoreActions.hide>;

// endregion

// region exports

/**
 * Reduces the new module store to a new state.
 *
 * @param aState
 *   Current state
 * @param anAction
 *   Action to apply to the store
 *
 * @return new state
 */
export function selectImageReducer(aState: SelectImageState = initialState, anAction: NewModuleAction): SelectImageState {
  switch (anAction.type) {
    case ReducerActionType.ShowSelectImage:
      return {
        ...aState,
        show: true,
        callback: anAction.callback,
      };
    case ReducerActionType.HideSelectImage:
      return {
        ...aState,
        show: false
      };
    default:
      return aState;
  }
}

// endregion