// region imports

import {PropertyGroup} from "../PropertyGroup";
import * as React from "react";
import {UFFormNumberSliderField} from "../../../UF-mui/components/form/UFFormNumberSliderField";
import {LandingPageBorder} from "../../../types/LandingPageBorder";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {TT} from "../../TT";
import {LandingPageStoreActions} from "../../../store/landingPage/LandingPageStoreActions";

// endregion

// region local types and functions

/**
 * Properties for the component.
 */
interface LandingPageBorderPropertiesProps {
  border: LandingPageBorder;
  setBorder: typeof LandingPageStoreActions.setBorder;
}

/**
 * Maps application state to component properties.
 */
function mapStateToProps(state: ApplicationState) {
  return {
    border: state.landingPage.border
  };
}

// endregion

// region component

/**
 * The landing page border properties.
 */
export const LandingPageBorderProperties = connect(mapStateToProps, {setBorder: LandingPageStoreActions.setBorder})(
  ({border, setBorder}: LandingPageBorderPropertiesProps) => (
    <PropertyGroup title={<TT ttid="border-properties.title">Border</TT>}>
      <UFFormNumberSliderField
        label={<TT ttid="border-properties.radius">Radius</TT>}
        minValue={0}
        maxValue={50}
        leftLabel="$minValue$"
        rightLabel="$maxValue$"
        showInput={true}
        value={border.radius}
        onFieldChange={({value}) => setBorder({radius: value as number})}
      />
    </PropertyGroup>
  )
);

// endregion
