// region imports

import * as React from "react";
import {SvgIcon} from "@mui/material";
import {ReactComponent as GbIcon} from '../images/gb.svg';

// endregion

// region component

export const EnglishIcon: React.FC = () => (
  <SvgIcon component={GbIcon} inheritViewBox />
);

// endregion