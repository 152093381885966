// region imports

import * as React from 'react';
import {MenuItem, Stack, Typography} from "@mui/material";
import {TT} from "../TT";
import {UFFormSelect} from "../../UF-mui/components/form/UFFormSelect";
import {useEffect} from "react";
import {Campaign} from "../../types/Campaign";
import {NetworkController} from "../../controllers/NetworkController";
import {UFBusy} from "../../UF-mui/components/wrappers/UFBusy";
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";

// endregion

// region local types and functions

interface CampaignSelectProps {
  readonly value: string;
  readonly onChange: (value: string) => any;
  readonly label?: React.ReactNode;
  readonly disabled?: boolean;
  readonly networkBusy: boolean;
  readonly campaigns: Campaign[];
  readonly withLandingPage?: boolean;
  readonly skipKey?: string;
}

function mapStateToProps(anApplicationState: ApplicationState) {
  return {
    networkBusy: anApplicationState.network.networkBusy,
    campaigns: anApplicationState.user.campaigns
  };
}

// endregion

// region component

/**
 * {@link CampaignSelect} renders a selection control to select a campaign.
 */
export const CampaignSelect = connect(mapStateToProps)(
  ({
     value,
     label = <TT ttid="select-campaign.campaign-label">Campaign</TT>,
     disabled = false,
     onChange,
     networkBusy,
     campaigns,
     withLandingPage,
     skipKey = ''
   }: CampaignSelectProps
  ) => {
    useEffect(
      () => {
        // noinspection JSIgnoredPromiseFromCall
        NetworkController.instance.refreshCampaigns();
      },
      []
    );
    const filteredCampaigns = campaigns.filter(
      campaign => (campaign.key != skipKey)
        && (
          ((withLandingPage === true) && campaign.landingPage)
          || ((withLandingPage === false) && !campaign.landingPage)
          || (withLandingPage === undefined)
        )
    );
    return (
      <UFBusy busy={networkBusy} sx={{width: '100%'}}>
        <UFFormSelect
          value={value}
          onFieldChange={({value}) => onChange(value)}
          label={label}
          sx={withLandingPage ? {height: '55px'} : {}}
          disabled={disabled || networkBusy}
        >
          {filteredCampaigns.map(campaign =>
            <MenuItem value={campaign.key} key={campaign.key}>
              <Stack direction="column">
                <Typography>{campaign.name}</Typography>
                <Typography variant="caption">
                  {
                    campaign.landingPage &&
                    <TT
                      ttid="select-campaign.campaign-info"
                      map={{'$title$': campaign.landingPage.title}}
                    >
                      Landing page title: $title$
                    </TT>
                  }
                  {
                    !campaign.landingPage && (withLandingPage !== false) &&
                    <em>
                      <TT ttid="select-campaign.no-landing-page">(no landing page)</TT>
                    </em>
                  }
                </Typography>
              </Stack>
            </MenuItem>
          )}
        </UFFormSelect>
      </UFBusy>
    );
  });

// endregion