// region imports

import * as React from 'react';
import {Box, Typography} from "@mui/material";

// endregion

// region local types

/**
 * Properties for component
 */
interface PropertiesContainerProps {
  /**
   * Title to show
   */
  title: React.ReactNode;

  /**
   * Show or hide the container
   */
  visible: boolean;
}

// endregion

// region component

/**
 * {@link PropertyGroupsContainer} is container for one or more property groups.
 */
export const PropertyGroupsContainer: React.FC<PropertiesContainerProps> =
  ({title, visible, children}) => (
    <Box sx={{background: '#fff', display: visible ? 'block' : 'none'}}>
      <Typography variant="h6" sx={{color: '#222', padding: '0.5rem 1rem'}}>
        {title}
      </Typography>
      {children}
    </Box>
  );