// region imports

import * as React from 'react';
import {PropertyGroupsContainer} from "../PropertyGroupsContainer";
import {ModuleActions} from "../ModuleActions";
import {PropertyGroup} from "../PropertyGroup";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {LandingPageModule} from "../../../types/LandingPageModule";
import {TT} from "../../TT";
import {LandingPageStoreActions} from "../../../store/landingPage/LandingPageStoreActions";

// endregion

// region local types

/**
 * Properties for component.
 */
interface UserRegistrationModulePropertiesProps {
  readonly visible: boolean;
  readonly moduleKey: string;
  readonly module: LandingPageModule;
  readonly updateModule: typeof LandingPageStoreActions.updateModule;
}

/**
 * Maps state to local properties.
 */
function mapStateToProps(anApplicationState: ApplicationState, aProps: Partial<UserRegistrationModulePropertiesProps>) {
  return {
    module: anApplicationState.landingPage.modules[aProps.moduleKey!],
  }
}

// region

/**
 * All properties for the module.
 */
export const UserRegistrationModuleProperties = connect(
  mapStateToProps,
  {
    updateModule: LandingPageStoreActions.updateModule
  }
)(
  ({visible, module, updateModule}: UserRegistrationModulePropertiesProps) => {
    return (
      <PropertyGroupsContainer title={
        <TT ttid="user-registration-properties.title">UserRegistration Module Settings</TT>} visible={visible}>
        <ModuleActions module={module}/>
      </PropertyGroupsContainer>
    );
  }
);

// endregion