// region imports

import * as React from 'react';
import {useState} from 'react';
import {FormControl, InputLabel, Mark, Slider, SliderProps} from "@mui/material";
import {UFFormFieldChangeEvent} from "../../events/UFFormFieldChangeEvent";

// endregion

// region exports

/**
 * Props for {@link UFFormSlider}
 */
export interface UFFormSliderFieldProps extends SliderProps {
  /**
   * Label to show above slider
   */
  label: React.ReactNode;

  /**
   * True to show full width
   */
  fullWidth?: boolean;

  /**
   * A simplified change event.
   *
   * @param anEvent
   *   Event data
   */
  readonly onFieldChange?: (anEvent: UFFormFieldChangeEvent) => any;
}

/**
 * {@link UFFormSlider} wraps a {@link Slider} in a {@link FormControl} and adds a {@link InputLabel} above it.
 */
export const UFFormSlider: React.FC<UFFormSliderFieldProps> = (
  {label, fullWidth = true, marks, onFieldChange, name, ...other}
) => {
  const [focus, setFocus] = useState(false);
  const labels = marks && (marks as Mark[]).length && (marks as Mark[]).find(mark => !!mark.label);
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel shrink={true} focused={focus} variant="standard">{label}</InputLabel>
      <Slider
        {...other}
        sx={{
          margin: `1rem 12px ${labels ? '1rem' : '0'}`,
          width: 'calc(100% - 24px)'
        }}
        marks={marks}
        name={name}
        onChange={
          (event, value) =>
            onFieldChange && onFieldChange({value, name: name || '', type: 'number'})
        }
        onMouseDown={() => setFocus(true)}
        onMouseUp={() => setFocus(false)}
      />
    </FormControl>
  );
}

// endregion