// region imports

import * as React from 'react';
import {Stack, Typography} from "@mui/material";
import {DialogPage} from "./DialogPage";
import CircularProgress from "@mui/material/CircularProgress";
import {green} from "@mui/material/colors";

// endregion

// region local types

interface BusyPageProps {
  readonly title: React.ReactNode;
  readonly text: React.ReactNode;
}

// endregion

// region component

/**
 * {@link BusyPage} shows a progress animation and no buttons.
 */
export const BusyPage: React.FC<BusyPageProps> = (
  {title, text}
) => (
  <DialogPage title={title}>
    <Typography>{text}</Typography>
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="200px"
      minWidth="250px"
      width="100%"
    >
      <CircularProgress
        size="70px"
        thickness={6}
        sx={{
          color: green[500],
        }}
      />
    </Stack>
  </DialogPage>
);

// endregion
