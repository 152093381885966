// region imports

import {Config} from "../constants/Config";
import {NetworkStoreActions} from "../store/network/NetworkStoreActions";
import {UserLocation} from "../constants/UserLocation";
import {ApplicationStore} from "../store/buildStore";
import {UserStoreActions} from "../store/user/UserStoreActions";
import {EditorMode} from "../constants/EditorMode";
import {EditorStoreActions} from "../store/editor/EditorStoreActions";
import {LandingPageStoreActions} from "../store/landingPage/LandingPageStoreActions";
import {NetworkController} from "./NetworkController";
import {ServerResult} from "../constants/ServerResult";
import {CredentialsStoreActions} from "../store/credentials/CredentialsStoreActions";
import {MainStoreActions} from "../store/main/MainStoreActions";
import {LandingPageTemplate} from "../types/LandingPageTemplate";
import {ModuleType} from "../constants/ModuleType";
import {LandingPageModuleTools} from "../tools/LandingPageModuleTools";
import {ApplicationDialogType} from "../constants/ApplicationDialogType";

// endregion

// region local functions

// endregion

// region exports

/**
 * {@link MainController} is a singleton class that handles the flow of the application.
 */
export class MainController {
  // region private variables

  /**
   * Reference to the redux store.
   */
  private readonly m_store: ApplicationStore;

  /**
   * Singleton instance.
   */
  private static s_instance: MainController;

  /**
   * Timer used to wait for background saving.
   */
  private m_timer: NodeJS.Timer | null = null;

  // endregion

  // region constructor

  private constructor(aStore: ApplicationStore) {
    this.m_store = aStore;
    aStore.subscribe(() => this.handleStoreChange());
  }

  // endregion

  // region public methods

  /**
   * Initialize the singleton. This method has to be called before accessing the {@link instance} property.
   *
   * @param aStore
   *   Store to use
   */
  static init(aStore: ApplicationStore) {
    MainController.s_instance = new MainController(aStore);
  }

  /**
   * Starts the controller by trying to load the user with the stored credentials and switching to the correct location.
   *
   * @returns {Promise<boolean>}
   */
  async start() {
    this.setLocation(UserLocation.Start);
    if (!await NetworkController.instance.getUser()) {
      this.setLocation(UserLocation.Login);
    } else {
      const user = this.m_store.getState().user;
      if (user.administrator) {
        this.setLocation(user.selectedTemplate ? UserLocation.EditLandingPage : UserLocation.SelectTemplate);
      } else {
        if (!user.selectedCampaign) {
          this.setLocation(UserLocation.SelectCampaign);
        } else if (true) {
          // todo check if a new landing page needs to be created
          this.setLocation(UserLocation.CreateLandingPage);
        } else {
          this.setLocation(UserLocation.EditLandingPage);
        }
      }
    }
    this.m_store.dispatch(MainStoreActions.setInitialized());
  }

  /**
   * This method should be called if a location is finished.
   */
  finishLocation() {
    const {user} = this.m_store.getState();
    switch (user.location) {
      case UserLocation.Login:
        this.setLocation(user.administrator ? UserLocation.SelectTemplate : UserLocation.SelectCampaign);
        break;
      case UserLocation.SelectCampaign:
        // todo handle existing or new landing page
        this.setLocation(UserLocation.CreateLandingPage);
        break;
      case UserLocation.SelectTemplate:
        this.setLocation(UserLocation.EditLandingPage);
        break;
      case UserLocation.CreateLandingPage:
        this.setLocation(UserLocation.EditLandingPage);
        break;
      case UserLocation.EditLandingPage:
        console.error('Trying to finish the landing page.');
        break;
    }
  }

  /**
   * Performs a login.
   *
   * Todo remove administrator parameter
   *
   * @param anEmail
   * @param aPassword
   * @param anAdministrator
   *   For testing only.
   *
   * @returns {Promise<boolean>}
   */
  async login(anEmail: string, aPassword: string, anAdministrator: boolean): Promise<boolean> {
    const result = await NetworkController.instance.authenticate(anEmail, aPassword);
    // for testing purposes only, todo remove once server is working
    if (anAdministrator) {
      const user = this.m_store.getState().user;
      this.m_store.dispatch(UserStoreActions.setUser(user.fullName, anAdministrator));
    }
    this.m_store.dispatch(
      EditorStoreActions.setEditorMode(anAdministrator ? EditorMode.Template : EditorMode.Campaign)
    );
    return result == ServerResult.Success;
  }

  /**
   * Perform logout of the user.
   */
  logout() {
    // todo notify server of logout
    this.m_store.dispatch(UserStoreActions.clear());
    this.m_store.dispatch(CredentialsStoreActions.setToken(''));
    this.m_store.dispatch(LandingPageStoreActions.reset());
  }

  /**
   * Shows a page to change the campaign.
   */
  changeCampaign() {
    this.setLocation(UserLocation.SelectCampaign);
  }

  /**
   * Resets the landing page.
   */
  resetLandingPage() {
    this.m_store.dispatch(LandingPageStoreActions.reset());
    if (this.m_store.getState().editor.mode == EditorMode.Campaign) {
      this.setLocation(UserLocation.CreateLandingPage);
    }
  }

  /**
   * Shows a page to change the template.
   */
  changeTemplate() {
    this.setLocation(UserLocation.SelectTemplate);
  }

  /**
   * Selects a template and gets the landing page data for the template.
   *
   * @param {string} aTemplateKey
   *
   * @returns {Promise<void>}
   */
  async selectTemplate(aTemplateKey: string) {
    this.setLocation(UserLocation.LoadingPage);
    await NetworkController.instance.loadForTemplate(aTemplateKey);
    this.m_store.dispatch(UserStoreActions.setSelectedTemplate(aTemplateKey));
    this.setLocation(UserLocation.EditLandingPage);
  }

  /**
   * Creates a new template and landing page.
   *
   * @returns {Promise<void>}
   */
  async newTemplate() {
    this.setLocation(UserLocation.LoadingPage);
    const key = await NetworkController.instance.createTemplate();
    this.m_store.dispatch(UserStoreActions.setSelectedTemplate(key));
    this.setLocation(UserLocation.EditLandingPage);
  }

  /**
   * Selects a new campaign and gets the landing page data for a campaign.
   *
   * @param {string} aCampaignKey
   *
   * @returns {Promise<void>}
   */
  async selectCampaign(aCampaignKey: string) {
    this.m_store.dispatch(UserStoreActions.setSelectedCampaign(aCampaignKey));
    if (this.m_store.getState().user.campaigns.find(campaign => campaign.key == aCampaignKey)?.landingPage) {
      this.setLocation(UserLocation.LoadingPage);
      await NetworkController.instance.loadForCampaign(aCampaignKey);
      this.setLocation(UserLocation.EditLandingPage);
    } else {
      this.setLocation(UserLocation.CreateLandingPage);
    }
  }

  /**
   * Deletes a template. This will refresh the templates list in the store.
   *
   * @param {string} aTemplateKey
   *
   * @returns {Promise<LandingPageTemplate[]>}
   */
  async deleteTemplate(aTemplateKey: string) {
    await NetworkController.instance.deleteTemplate(aTemplateKey);
  }

  /**
   * Deletes the current template and show the page to select a new template.
   *
   * @returns {Promise<void>}
   */
  async deleteSelectedTemplate() {
    await NetworkController.instance.deleteTemplate(this.m_store.getState().user.selectedTemplate);
    this.setLocation(UserLocation.SelectTemplate);
  }

  /**
   * Copies the current landing page to a new campaign.
   *
   * @param {string} aCampaignKey
   *
   * @returns {Promise<void>}
   */
  async copyToCampaign(aCampaignKey: string) {
    this.setLocation(UserLocation.LoadingPage);
    await NetworkController.instance.saveToCampaign(aCampaignKey);
    this.setLocation(UserLocation.EditLandingPage);
  }

  /**
   * Adds a module to the current landing page.
   *
   * @param {ModuleType} aModuleType
   */
  addModule(aModuleType: ModuleType) {
    const configuration = this.m_store.getState().landingPage.configuration;
    this.m_store.dispatch(LandingPageStoreActions.addModule(
      LandingPageModuleTools.create(aModuleType, configuration.initialCategory, configuration.initialClassification)
    ));
  }

  // endregion

  // region public properties

  /**
   * Reference to singleton instance.
   */
  static get instance(): MainController {
    return MainController.s_instance;
  }

  // endregion

  // region private methods

  /**
   * Changes the location of the user.
   *
   * @param aLocation
   *   New location
   */
  private setLocation(aLocation: UserLocation): boolean {
    this.m_store.dispatch(UserStoreActions.setLocation(aLocation));
    return true;
  }

  /**
   * Stops the countdown timer.
   */
  private stopCountDownTimer() {
    if (this.m_timer != null) {
      clearInterval(this.m_timer);
      this.m_timer = null;
    }
  }

  /**
   * Start the countdown timer.
   */
  private startCountDownTimer() {
    if (this.m_timer) {
      return;
    }
    this.m_timer = setInterval(() => this.handleTimerTick(), 1000);
    this.m_store.dispatch(NetworkStoreActions.setSaveDelayTime(Config.autoSaveDelay));
  }

  // endregion

  // region event handlers

  /**
   * Handles changes to the store.
   *
   * @private
   */
  private handleStoreChange() {
    const state = this.m_store.getState();
    if (state.landingPage.dirty && !state.network.busySaving) {
      this.startCountDownTimer();
    }
    if (!state.landingPage.dirty || state.network.busySaving) {
      this.stopCountDownTimer();
    }
  }

  /**
   * Handles countdown timer ticks.
   */
  private handleTimerTick() {
    const state = this.m_store.getState();
    const {dialogs} = state;
    // don't update timer while certain dialogs are active or user is not in the editor page
    if (
      dialogs.alert.show || dialogs.confirm.show || dialogs.applicationDialog.dialog != ApplicationDialogType.None
      || dialogs.edit.show || (state.user.location != UserLocation.EditLandingPage)
    ) {
      return;
    }
    const newValue = this.m_store.getState().network.saveDelayTime - 1;
    if (newValue > 0) {
      this.m_store.dispatch(NetworkStoreActions.setSaveDelayTime(newValue));
      return;
    }
    this.stopCountDownTimer();
    NetworkController.instance.save();
  }

  // endregion
}

// endregion
