// region imports

import * as React from 'react';
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {TT} from "../TT";
import {connect} from "react-redux";
import {ApplicationState} from "../../store/ApplicationState";
import {ApplicationDialogStoreActions} from "../../store/dialogs/applicationDialog/ApplicationDialogStoreActions";
import {ApplicationDialogType} from "../../constants/ApplicationDialogType";

// endregion

// region local types

/**
 * Properties for component
 */
interface NewModuleButtonProps {
  show: typeof ApplicationDialogStoreActions.show;
}

/**
 * Copy global state to local props.
 */
function mapStateToProps(anApplicationState: ApplicationState) {
  return {
  }
}

// endregion

// region component

/**
 * {@link NewModuleButton} shows the popup to select a module type and adds a module.
 */
export const NewModuleButton = connect(
  mapStateToProps,
  {
    show: ApplicationDialogStoreActions.show,
  }
)(
  ({show}: NewModuleButtonProps) => (
    <Button
      startIcon={<AddIcon/>}
      variant="outlined"
      color="inherit"
      size="small"
      onClick={() => show(ApplicationDialogType.NewModule)}
    >
      <TT ttid="add-module.caption">Add module</TT>
    </Button>
  )
);

// endregion