// region imports

import {ReducerActionType} from "../ReducerActionType";
import {UFRedux} from "../../UF-react/tools/UFRedux";
import {LandingPageStoreActions} from "../landingPage/LandingPageStoreActions";
import {ThunkStoreAction} from "../buildStore";

// endregion

// region exports

export class NetworkStoreActions {
  /**
   * Sets the saving busy state.
   */
  static setBusySaving(aBusy: boolean) {
    return UFRedux.reducerAction<ReducerActionType.SetBusySaving>(ReducerActionType.SetBusySaving)({
      busy: aBusy
    })
  }

  /**
   * Sets the delay time.
   */
  static setSaveDelayTime(aTime: number) {
    return UFRedux.reducerAction<ReducerActionType.SetSaveDelayTime>(ReducerActionType.SetSaveDelayTime)({
      time: aTime
    })
  }

  /**
   * Sets the network busy state.
   */
  static setNetworkBusy(aBusy: boolean) {
    return UFRedux.reducerAction<ReducerActionType.SetNetworkBusy>(ReducerActionType.SetNetworkBusy)({
      busy: aBusy
    })
  }

}


// endregion
