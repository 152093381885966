export enum ModuleType {
  HeaderBanner = 1,
  UserRegistration = 2,
  Voucher = 3,
  Cams = 4,
  Videos = 5,
  StarShows = 6,
  SingleImageAndText = 7,
  TwoImagesAndTexts = 8,
  Banner = 9,
  Button = 10
}
