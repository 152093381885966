import * as React from "react";
import {UFRedux} from "../../../UF-react/tools/UFRedux";
import {ReducerActionType} from "../../ReducerActionType";
import {ThunkAction} from "redux-thunk";
import {ApplicationState} from "../../ApplicationState";
import {Action} from "redux";

export class InputStoreActions {
  /**
   * Shows the input popup
   *
   * @param aTitle
   *   Title to use
   * @param aContent
   *   Content to use
   * @param aCallback
   *   Callback to call
   * @param aValue
   *   Initial value to show
   * @param anOk
   *   Ok caption
   * @param aCancel
   *   Cancel caption
   * @param anAllowEmpty
   *   Allow empty option
   */
  static show(
    aTitle: React.ReactNode, aContent: React.ReactNode, aCallback: (value: string | false) => void,
    aValue: string = '', anOk: React.ReactNode = 'Ok', aCancel: React.ReactNode = 'Cancel', anAllowEmpty: boolean = false
  ) {
    return UFRedux.reducerAction<ReducerActionType.ShowInput>(ReducerActionType.ShowInput)({
      title: aTitle,
      content: aContent,
      callback: aCallback,
      value: aValue,
      ok: anOk,
      cancel: aCancel,
      allowEmpty: anAllowEmpty
    });
  }

  /**
   * Hides the input popup.
   */
  static hide() {
    return UFRedux.reducerAction<ReducerActionType.HideInput>(ReducerActionType.HideInput)({});
  }

  /**
   * Thunk action to perform an input popup.
   *
   * @param aTitle
   *   Title to use
   * @param aContent
   *   Content to use
   * @param aValue
   *   Initial value (default is '')
   * @param anOk
   *   Ok caption (default is 'Ok')
   * @param aCancel
   *   Cancel caption (default is 'Cancel')
   * @param anAllowEmpty
   *   When true allow for empty value, else disable Ok button if input field is empty (default is false)
   *
   * @returns A function that expects a dispatch parameter and returns a Promise.
   */
  static popup = (
    aTitle: React.ReactNode,
    aContent: React.ReactNode,
    aValue: string = '',
    anOk: React.ReactNode = 'Ok',
    aCancel: React.ReactNode = 'Cancel',
    anAllowEmpty: boolean = false
  ): ThunkAction<Promise<string | false>, ApplicationState, null, Action<ReducerActionType>> => dispatch => new Promise<string | false>(resolve => {
    dispatch(InputStoreActions.show(
      aTitle,
      aContent,
      value => {
        dispatch(InputStoreActions.hide());
        resolve(value);
      },
      aValue,
      anOk,
      aCancel,
      anAllowEmpty
    ));
  });

}