// region imports

import * as React from 'react';
import {TT} from "../TT";
import {BusyPage} from "./BusyPage";

// endregion

// region component

/**
 * {@link LoadingPage} is shown while the app is loading the landing page.
 */
export const LoadingPage: React.FC = () => <BusyPage
  title={<TT ttid="loading-page.title">Loading Landing Page</TT>}
  text={<TT ttid="loading-page.content">One moment please, busy retrieving the landing page</TT>}
/>;

// endregion
