// region imports

import {ApplicationDialogType} from "../../../constants/ApplicationDialogType";
import {UFRedux} from "../../../UF-react/tools/UFRedux";
import {ReducerActionType} from "../../ReducerActionType";

// endregion

// region exports

export class ApplicationDialogStoreActions {
  static show(aDialog: ApplicationDialogType) {
    return UFRedux.reducerAction<ReducerActionType.ShowApplicationDialog>(ReducerActionType.ShowApplicationDialog)({
      dialog: aDialog
    });
  }

  static hide() {
    return UFRedux.reducerAction<ReducerActionType.HideApplicationDialog>(ReducerActionType.HideApplicationDialog)({});
  }
}

// endregion