// region imports

import {ReducerActionType} from "../ReducerActionType";
import {ViewMode} from "../../constants/ViewMode";
import {EditorState} from "./EditorState";
import {EditorMode} from "../../constants/EditorMode";
import {EditorStoreActions} from "./EditorStoreActions";

// endregion

// region local types

const initialState : EditorState = {
  viewScale: 100,
  viewMode: ViewMode.Desktop,
  mode: EditorMode.None
}

/**
 * All system action models.
 */
type EditorAction = ReturnType<typeof EditorStoreActions.setViewMode>
  | ReturnType<typeof EditorStoreActions.setViewScale> | ReturnType<typeof EditorStoreActions.setEditorMode>;

// endregion

// region exports

export function editorReducer(aState: EditorState = initialState, anAction: EditorAction): EditorState {
  switch(anAction.type) {
    case ReducerActionType.SetViewMode:
      return {
        ...aState,
        viewMode: anAction.viewMode
      };
    case ReducerActionType.SetViewScale:
      return {
        ...aState,
        viewScale: anAction.viewScale
      };
    case ReducerActionType.SetEditorMode:
      return {
        ...aState,
        mode: anAction.mode
      };
    default:
      return aState;
  }
}

// endregion