// region imports

import * as React from 'react';
import {TT} from "../../TT";
import {LandingPageActions} from "./LandingPageActions";
import {LandingPageConfigurationProperties} from "./LandingPageConfigurationProperties";
import {LandingPageBorderProperties} from "./LandingPageBorderProperties";
import {LandingPageColorProperties} from "./LandingPageColorProperties";
import {LandingPageFontProperties} from "./LandingPageFontProperties";
import {LandingPageDropShadowProperties} from "./LandingPageDropShadowProperties";
import {LandingPageTopBarProperties} from "./LandingPageTopBarProperties";
import {PropertyGroupsContainer} from "../PropertyGroupsContainer";
import {EditorMode} from "../../../constants/EditorMode";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {LandingPageTemplateProperties} from "./LandingPageTemplateProperties";

// endregion

// region local types

interface LandingPagePropertiesProps {
  visible: boolean;
  mode: EditorMode;
}

function mapStateToProps(anApplicationState: ApplicationState) {
  return {
    mode: anApplicationState.editor.mode,
  }
}

// region

/**
 * All properties for the landing page.
 */
export const LandingPageProperties = connect(mapStateToProps)(({visible, mode}: LandingPagePropertiesProps) => {
  return (
    <PropertyGroupsContainer
      title={<TT ttid="landing-page-properties.title">Landing page settings</TT>}
      visible={visible}
    >
      <LandingPageActions/>
      {(mode == EditorMode.Template) && <LandingPageTemplateProperties />}
      <LandingPageConfigurationProperties/>
      <LandingPageBorderProperties/>
      <LandingPageColorProperties/>
      <LandingPageFontProperties/>
      <LandingPageDropShadowProperties/>
      <LandingPageTopBarProperties/>
    </PropertyGroupsContainer>
  );
});

// endregion