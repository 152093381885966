// region imports

import {PropertyGroup} from "../PropertyGroup";
import * as React from "react";
import {connect} from "react-redux";
import {LandingPageDropShadow} from "../../../types/LandingPageDropShadow";
import {ApplicationState} from "../../../store/ApplicationState";
import {UFFormNumberSliderField} from "../../../UF-mui/components/form/UFFormNumberSliderField";
import {ColorField} from "../../form/ColorField";
import {UFFormSwitch} from "../../../UF-mui/components/form/UFFormSwitch";
import {TT} from "../../TT";
import {LandingPageStoreActions} from "../../../store/landingPage/LandingPageStoreActions";

// endregion

// region local types and functions

/**
 * Properties for the component.
 */
interface LandingPageDropShadowPropertiesProps {
  dropShadow: LandingPageDropShadow;
  setDropShadow: typeof LandingPageStoreActions.setDropShadow;
}

/**
 * Maps application state to component properties.
 */
function mapStateToProps(state: ApplicationState) {
  return {
    dropShadow: state.landingPage.dropShadow
  };
}

// endregion

// region component

/**
 * The landing page drop shadow properties.
 */
export const LandingPageDropShadowProperties = connect(
  mapStateToProps,
  {
    setDropShadow: LandingPageStoreActions.setDropShadow
  }
)(
  ({dropShadow, setDropShadow}: LandingPageDropShadowPropertiesProps) => (
    <PropertyGroup title={<TT ttid="drop-shadow-properties.title">Drop shadow</TT>}>
      <UFFormSwitch
        label={<TT ttid="drop-shadow-properties.enable">Enable drop shadow</TT>}
        checked={dropShadow.enabled}
        onFieldChange={({value}) => setDropShadow({...dropShadow, enabled: value})}
      />
      {
        dropShadow.enabled &&
        <UFFormNumberSliderField
          label={<TT ttid="drop-shadow-properties.left">Left position</TT>}
          leftLabel="$minValue$px"
          rightLabel="$maxValue$px"
          showInput={true}
          minValue={-20}
          maxValue={20}
          value={dropShadow.left}
          onFieldChange={({value}) => setDropShadow({...dropShadow, left: value})}
        />
      }
      {
        dropShadow.enabled &&
        <UFFormNumberSliderField
          label={<TT ttid="drop-shadow-properties.top">Top position</TT>}
          leftLabel="$minValue$px"
          rightLabel="$maxValue$px"
          showInput={true}
          minValue={-20}
          maxValue={20}
          value={dropShadow.top}
          onFieldChange={({value}) => setDropShadow({...dropShadow, top: value})}
        />
      }
      {
        dropShadow.enabled &&
        <UFFormNumberSliderField
          label={<TT ttid="drop-shadow-properties.size">Size</TT>}
          leftLabel="$minValue$px"
          rightLabel="$maxValue$px"
          showInput={true}
          minValue={0}
          maxValue={20}
          value={dropShadow.size}
          onFieldChange={({value}) => setDropShadow({...dropShadow, size: value})}
        />
      }
      {
        dropShadow.enabled &&
        <UFFormNumberSliderField
          label={<TT ttid="drop-shadow-properties.blur-size">Blur size</TT>}
          leftLabel="$minValue$px"
          rightLabel="$maxValue$px"
          showInput={true}
          minValue={0}
          maxValue={20}
          value={dropShadow.blurSize}
          onFieldChange={({value}) => setDropShadow({...dropShadow, blurSize: value})}
        />
      }
      {
        dropShadow.enabled &&
        <ColorField
          label={<TT ttid="drop-shadow-properties.color">Shadow color</TT>}
          value={dropShadow.color}
          alpha={true}
          onChange={(value) => setDropShadow({...dropShadow, color: value})}
        />
      }
    </PropertyGroup>
  )
);

// endregion