// region imports

import {ReducerActionType} from "../ReducerActionType";
import {UFRedux} from "../../UF-react/tools/UFRedux";

// endregion

// region type

export class CredentialsStoreActions {
  static setToken(aToken: string) {
    return UFRedux.reducerAction<ReducerActionType.SetToken>(ReducerActionType.SetToken)({
      token: aToken
    });
  }
}

// endregion