// region imports

import * as React from 'react';
import {PropertyGroupsContainer} from "../PropertyGroupsContainer";
import {ModuleActions} from "../ModuleActions";
import {PropertyGroup} from "../PropertyGroup";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {LandingPageModule} from "../../../types/LandingPageModule";
import {UFFormSelect} from "../../../UF-mui/components/form/UFFormSelect";
import {TextImagePosition} from "../../../constants/TextImagePosition";
import {MenuItem, Typography} from "@mui/material";
import {UFFormSlider} from "../../../UF-mui/components/form/UFFormSlider";
import {UFFormTextField} from "../../../UF-mui/components/form/UFFormTextField";
import {HtmlTextField} from '../../form/HtmlTextField';
import {LandingPageFont} from "../../../types/LandingPageFont";
import {TT} from "../../TT";
import {LandingPageStoreActions} from "../../../store/landingPage/LandingPageStoreActions";
import {StylingTools} from "../../../tools/StylingTools";
import {ImageFitSelect} from "../../form/ImageFitSelect";
import {ImageField} from "../../form/ImageField";

// endregion

// region local types and functions

/**
 * Properties for component.
 */
interface SingleImageAndTextModulePropertiesProps {
  readonly visible: boolean;
  readonly moduleKey: string;
  readonly module: LandingPageModule;
  readonly updateModule: typeof LandingPageStoreActions.updateModule;
  readonly font: LandingPageFont;
}

/**
 * Maps state to local properties.
 */
function mapStateToProps(
  anApplicationState: ApplicationState, aProps: Partial<SingleImageAndTextModulePropertiesProps>
) {
  return {
    module: anApplicationState.landingPage.modules[aProps.moduleKey!],
    font: anApplicationState.landingPage.font
  }
}

// region

/**
 * All properties for the module.
 */
export const SingleImageAndTextModuleProperties = connect(
  mapStateToProps,
  {
    updateModule: LandingPageStoreActions.updateModule
  }
)(
  ({visible, module, updateModule, font}: SingleImageAndTextModulePropertiesProps) => {
    const widths = [20, 33, 50, 66, 80];
    return (
      <PropertyGroupsContainer
        title={<TT ttid="single-image-and-text-properties.title">Single image and text module settings</TT>}
        visible={visible}
      >
        <ModuleActions module={module}/>
        <PropertyGroup title={<TT>Content</TT>} defaultExpanded={true}>
          <UFFormSelect
            label={<TT ttid="single-image-and-text-properties.text-and-image-position">Text and image position</TT>}
            value={module.textImagePosition}
            onFieldChange={({value}) => updateModule({...module, textImagePosition: value})}
          >
            <MenuItem value={TextImagePosition.TextLeftImageRight}>Text on left, image on right</MenuItem>
            <MenuItem value={TextImagePosition.TextRightImageLeft}>Text on right, image on left</MenuItem>
          </UFFormSelect>
          <UFFormSlider
            label={<TT ttid="single-image-and-text-properties.left-width">Left (and right) width</TT>}
            onFieldChange={({value}) => updateModule({...module, leftPercentage: widths[value]})}
            value={widths.indexOf(module.leftPercentage)}
            marks={[
              {value: 0, label: <Typography variant="caption">20%</Typography>},
              {value: 1, label: <Typography variant="caption">33%</Typography>},
              {value: 2, label: <Typography variant="caption">50%</Typography>},
              {value: 3, label: <Typography variant="caption">66%</Typography>},
              {value: 4, label: <Typography variant="caption">80%</Typography>},
            ]}
            min={0}
            max={4}
            step={1}
          />
          <UFFormTextField
            label={<TT ttid="single-image-and-text-properties.top-title">Top title</TT>}
            value={module.title2}
            fontFamily={StylingTools.getFontFamily(font.family)}
            onFieldChange={({value}) => updateModule({...module, title2: value})}
          />
          <ImageFitSelect
            value={module.imageFit}
            onChange={(value) => updateModule({...module, imageFit: value})}
          />
          <ImageField
            value={module.image}
            onChange={(value) => updateModule({...module, image: value})}
          />
          <UFFormTextField
            label={<TT ttid="single-image-and-text-properties.block-title">Text block title</TT>}
            value={module.title}
            fontFamily={StylingTools.getFontFamily(font.family)}
            onFieldChange={({value}) => updateModule({...module, title: value})}
          />
          <HtmlTextField
            label={<TT ttid="single-image-and-text-properties.block-content">Text block content</TT>}
            fontSize={font.size}
            fontFamily={font.family}
            value={module.text}
            onChange={(value) => updateModule({...module, text: value})}
          />
        </PropertyGroup>
      </PropertyGroupsContainer>
    );
  }
);

// endregion