// region imports

import {ServerResult} from "../constants/ServerResult";
import {ApplicationStore} from "../store/buildStore";
import {UFTypescript} from "../UF/tools/UFTypescript";
import {LandingPageStoreActions} from "../store/landingPage/LandingPageStoreActions";
import {NetworkStoreActions} from "../store/network/NetworkStoreActions";
import {LandingPageCopySource} from "../constants/LandingPageCopySource";
import {UserStoreActions} from "../store/user/UserStoreActions";
import {Campaign} from "../types/Campaign";
import {LandingPageTemplate} from "../types/LandingPageTemplate";
import {CredentialsStoreActions} from "../store/credentials/CredentialsStoreActions";
import {LandingPageConfiguration} from "../types/LandingPageConfiguration";
import {CategoryType} from "../constants/CategoryType";
import {Config} from "../constants/Config"
import urlJoin from 'url-join';
import {AlertStoreActions} from "../store/dialogs/alert/AlertStoreActions";
import {TT} from "../components/TT";
import {MainController} from "./MainController";
import {UFFetchMethod} from "../UF/types/UFFetchMethod";
import {UFNetwork} from "../UF/tools/UFNetwork";

// endregion

// region local functions

// endregion

// region test data

const CAMPAIGNS: Campaign[] = [
  {
    key: '1',
    name: 'First campaign',
    landingPage: {
      title: 'The reality',
      code: 'first',
      initialCategory: CategoryType.Reality
    }
  },
  {
    key: '2',
    name: 'Second campaign'
  },
  {
    key: '3',
    name: 'Third campaign',
    landingPage: {
      title: 'The third dimension',
      code: 'third',
      initialCategory: CategoryType.Fetish
    }
  },
  {
    key: '4',
    name: 'Fourth campaign'
  },
  {
    key: '5',
    name: 'Fifth campaign'
  },
  {
    key: '6',
    name: 'Sixth campaign'
  },
  {
    key: '7',
    name: 'Seventh campaign'
  },
  {
    key: '8',
    name: 'Eighth campaign'
  },
  {
    key: '9',
    name: 'Nineth campaign'
  },
  {
    key: '10',
    name: 'Tenth campaign'
  },
  {
    key: '11',
    name: 'Eleventh campaign'
  },
];

const TEMPLATES: LandingPageTemplate[] = [
  {
    key: '1',
    image: '',
    title: 'First template',
    description: 'First template with some modules.',
    available: true,
    createdBy: 'Lorem ipsum'
  },
  {
    key: '2',
    image: '',
    title: 'Second template',
    description: 'Second template with some modules.',
    available: false,
    createdBy: 'Lorem ipsum'
  },
  {
    key: '3',
    image: '',
    title: 'Third template',
    description: 'Third template with some modules.',
    available: false,
    createdBy: 'Lorem ipsum'
  },
  {
    key: '4',
    image: '',
    title: 'Fourth template',
    description: 'Fourth template with some modules.',
    available: false,
    createdBy: 'Lorem ipsum'
  }
];

// endregion

// region controller

/**
 * The network controller handles all communication with the server.
 *
 * If an error occurres, a popup is shown to ask the user to retry or contact the helpdesk.
 */
export class NetworkController {
  // region private variables

  /**
   * Reference to the singleton instance.
   */
  private static s_instance: NetworkController;

  /**
   * Reference to the redux store.
   */
  private readonly m_store: ApplicationStore;

  /**
   * Determines if the calls return success or failure.
   */
  private m_testResult: number;

  /**
   * Id of last test call.
   */
  private m_testId: string;

  // endregion

  // region constructor

  /**
   * The constructor is private, use {@link init} to create the singleton.
   *
   * @param aStore
   */
  private constructor(aStore: ApplicationStore) {
    this.m_store = aStore;
    this.m_testResult = 0;
    this.m_testId = '';
  }

  // endregion

  // public methods

  /**
   * Initialize the singleton. After this the {@link instance} property can be used.
   *
   * @param {ApplicationStore} aStore
   */
  static init(aStore: ApplicationStore) {
    NetworkController.s_instance = new NetworkController(aStore);
  }

  /**
   * Gets and updates the user for the stored token.
   *
   * @returns {Promise<boolean>} True if the token is valid and the user has been retrieved.
   */
  async getUser(): Promise<boolean> {
    const token = this.m_store.getState().credentials.token;
    if (!token) {
      return false;
    }
    // todo implement get user communication
    await this.simulateCall();
    return true;
  }

  /**
   * Authenticate the user at the server.
   *
   * @param anEmail
   *   Email address to authenticate
   * @param aPassword
   *   Password to authenticate
   *
   * @returns {Promise<ServerResult>}
   */
  async authenticate(anEmail: string, aPassword: string): Promise<ServerResult> {
    // todo implement authenticate communication
    if (this.getTrueOrFalse('authenticate')) {
      await this.simulateCall(
        UserStoreActions.setUser('Lorum Ipsum', false),
        CredentialsStoreActions.setToken('1234')
      );
      return ServerResult.Success;
    }
    await this.simulateCall();
    return ServerResult.AuthenticationFailed;
  }

  /**
   * Creates a landing page.
   *
   * @param aConfiguration
   *   Initial configuration
   * @param aSource
   *   Source to copy data from
   * @param aSourceId
   *   Id of source
   */
  async createLandingPage(
    aConfiguration: LandingPageConfiguration, aSource: LandingPageCopySource, aSourceId: string
  ): Promise<ServerResult> {
    // todo implement create landing page communication
    await this.simulateCall(LandingPageStoreActions.setConfiguration(aConfiguration));
    return (this.getTrueOrFalse('createLandingPage')) ? ServerResult.Success : ServerResult.CodeNotAvailable;
  }

  /**
   * Creates a new template and returns the template key.
   */
  async createTemplate(): Promise<string> {
    // todo implement create template communication
    await this.simulateCall();
    return '1234';
  }

  /**
   * Validates the code at the server.
   *
   * @param aCode
   *   Code to validate
   *
   * @returns {Promise<ServerResult>} Result of server
   */
  async validateCode(aCode: string): Promise<ServerResult> {
    // todo implement validate code communication
    await this.simulateCall();
    return (this.getTrueOrFalse('validateCode')) ? ServerResult.Success : ServerResult.CodeNotAvailable;
  }

  /**
   * Starts the background saving. This method is not asynchronous, the background saving will update the store state.
   */
  save() {
    this.m_store.dispatch(NetworkStoreActions.setBusySaving(true));
    this.m_store.dispatch(LandingPageStoreActions.clearDirty());
    // todo implement save communication
    setTimeout(
      () => {
        this.m_store.dispatch(NetworkStoreActions.setBusySaving(false));
      },
      2000
    );
  }

  /**
   * Retrieves landing page data for a template.
   *
   * @param {string} aTemplate
   *
   * @returns {Promise<void>}
   */
  async loadForTemplate(aTemplate: string) {
    await this.simulateCall();
  }

  /**
   * Retrieves landing page data for a campaign.
   *
   * @param {string} aCampaign
   *
   * @returns {Promise<void>}
   */
  async loadForCampaign(aCampaign: string) {
    await this.simulateCall();
  }

  /**
   * Gets the campaigns for the user.
   */
  async refreshCampaigns() {
    // todo implement campaigns communication
    await this.simulateCall(UserStoreActions.setCampaigns(CAMPAIGNS));
  }

  /**
   * Gets the templates from the server and refresh the store.
   */
  async refreshTemplates() {
    // todo implement templates communication
    await this.simulateCall(UserStoreActions.setTemplates(TEMPLATES));
  }

  /**
   * Deletes a template and returns all available templates.
   */
  async deleteTemplate(aTemplateKey: string) {
    // todo implement delete template communication
    await this.simulateCall(UserStoreActions.setTemplates(TEMPLATES));
  }

  /**
   * Saves the current landing page to a new campaign.
   *
   * @param {string} aCampaignKey
   *
   * @returns {Promise<void>}
   */
  async saveToCampaign(aCampaignKey: string) {
    await this.simulateCall(UserStoreActions.setSelectedCampaign(aCampaignKey));
  }

  // endregion

  // region public properties

  static get instance(): NetworkController {
    return NetworkController.s_instance;
  }

  // endregion

  // region private methods

  /**
   * Simulates a call to the server.
   *
   * @param anAction
   *   One or more actions to dispatch after a delay.
   */
  private async simulateCall(...anAction: any[]) {
    this.m_store.dispatch(NetworkStoreActions.setNetworkBusy(true));
    await UFTypescript.delay(750);
    anAction.forEach((action: any) => this.m_store.dispatch(action));
    this.m_store.dispatch(NetworkStoreActions.setNetworkBusy(false));
  }

  /**
   * Gets a true or false value. It will change after each call. If a different id is used, it will reset and will
   * return false.
   *
   * @returns {boolean}
   */
  private getTrueOrFalse(anId: string): boolean {
    if (this.m_testId != anId) {
      this.m_testId = anId;
      this.m_testResult = 0;
    }
    return (this.m_testResult++ % 2) == 1;
  }

  /**
   * Performs an api call. The function will handle errors.
   *
   * @param aPath
   *   Path to API call (not including /api/v1, for example: '/users/authenticate')
   * @param aMethod
   *   Method to use
   * @param aBodyData
   *   When not null, include body data as JSON encoded string
   * @param aReturnError
   *   When true return response even if the status is not ok.
   *
   * @returns the response or null if the login token has expired.
   */
  private async api(
    aPath: string, aMethod: UFFetchMethod, aBodyData: FormData | object | null = null, aReturnError: boolean = false
  ): Promise<Response | null> {
    const options = this.buildFetchOptions(aMethod, aPath, aBodyData);
    const url = urlJoin(Config.apiRoot, aPath);
    while (true) {
      try {
        const response = await fetch(url, options);
        UFNetwork.logApiResult(response, aMethod, aPath, aBodyData);
        if (response.ok) {
          const newToken = response.headers.get(Config.TokenHeaderKey);
          if (newToken) {
            this.m_store.dispatch(CredentialsStoreActions.setToken(newToken));
          }
          return response;
        } else if (aReturnError) {
          return response;
        } else if (response.status === 403) {
          await AlertStoreActions.popup(
            <TT ttid="token-expired.title">Session expired</TT>,
            <TT ttid="token-expired.text">Your login session has expired, you need to login.</TT>,
            <TT>Login</TT>
          );
          MainController.instance.logout();
          return null;
        }
      } catch (error: any) {
        UFNetwork.logApiError(error, aMethod, aPath);
      }
      await AlertStoreActions.popup(
        <TT ttid="io-error.title">Network error</TT>,
        <TT ttid="io-error.text" html={true}>
          An error has occurred while communicating with the server. Please contact Fundorado.<br/><br/>
          Click retry to resend the message to the server.
        </TT>,
        <TT>Retry</TT>
      );
    }
  }

  /**
   * Build the options for fetch.
   *
   * @param aMethod
   * @param aPath
   * @param aBodyData
   *
   * @returns
   */
  private buildFetchOptions(aMethod: UFFetchMethod, aPath: string, aBodyData: FormData | object | null): RequestInit {
    return UFNetwork.buildFetchOptions(aMethod, aPath, aBodyData, (headers) => {
      const token = this.m_store.getState().credentials.token;
      if (token) {
        headers.append(Config.TokenHeaderKey, token);
        console.log('token', token);
      }
    });
  }
}

// endregion

// endregion