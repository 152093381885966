// region Imports

import {EditState} from "./EditState";
import {ReducerActionType} from "../../ReducerActionType";
import {FontFamily} from "../../../constants/FontFamily";
import { EditStoreActions } from "./EditStoreActions";

// endregion

// region Local variables

/**
 * Initial system store state
 */
const initialState: EditState = {
  show: false,
  value: '',
  allowFontSize: false,
  backgroundColor: '#ffffff',
  textColor: '#222222',
  fontFamily: FontFamily.Roboto,
  fontSize: 14,
  callback: (value: string ) => {}
};

// endregion

// region local types

/**
 * All input action models.
 */
type EditAction = ReturnType<typeof EditStoreActions.show> | ReturnType<typeof EditStoreActions.hide>;

// endregion

// region exports

/**
 * Reduces the edit store to a new state.
 *
 * @param aState
 *   Current state
 * @param anAction
 *   Action to apply to the store
 *
 * @return new state
 */
export function editReducer(  aState: EditState = initialState, anAction: EditAction): EditState {
  switch(anAction.type) {
    case ReducerActionType.ShowEdit:
      return {
        show: true,
        value: anAction.value,
        allowFontSize: anAction.allowFontSize,
        backgroundColor: anAction.backgroundColor,
        textColor: anAction.textColor,
        callback: anAction.callback,
        fontFamily: anAction.fontFamily,
        fontSize: anAction.fontSize
      };
    case ReducerActionType.HideEdit:
      return {
        ...aState,
        show: false
      };
    default:
      return aState;
  }
}

// endregion