// region imports

import * as React from 'react';
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {AccordionSummaryProps} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {Box, Stack} from "@mui/material";
import {Config} from "../../constants/Config";

// endregion

// region local types

/**
 * Properties for component.
 */
interface PropertyGroupProps extends Omit<AccordionProps, 'title'> {
  /**
   * Title to show in summary block
   */
  title: React.ReactNode;

  /**
   * Optional content that will be shown on the right
   */
  rightTitleContent?: React.ReactNode;
}

// endregion

// region local component

/**
 * Custom styled details component.
 */
const PropertyGroupDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

/**
 * Custom styled summary component.
 */
const PropertyGroupSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
    {...props}
  />
))(({theme}) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

// endregion

// region components

/**
 * {@link PropertyGroup} is an accordion container that show one or more property.
 *
 * The styling is based on the custom styling example of the accordion at mui.com
 */
export const PropertyGroup = styled(({title, rightTitleContent = null, children, ...props}: PropertyGroupProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props}>
    <PropertyGroupSummary>
      <Box flex="1">
        {title}
      </Box>
      {rightTitleContent}
    </PropertyGroupSummary>
    <PropertyGroupDetails>
      <Stack spacing={0} gap={Config.propertiesGapSize} sx={{alignItems: 'flex-start'}}>
        {children}
      </Stack>
    </PropertyGroupDetails>
  </MuiAccordion>
))(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

// endregion
