/**
 * Maps to the value table on the server
 */
export enum CategoryType {
  General = 1,
  Fetish = 2,
  Teens = 3,
  MILFs = 4,
  Lesbians = 5,
  Reality = 6
}
