// region imports

import {PropertyGroup} from "../PropertyGroup";
import * as React from "react";
import {UFFormTextField} from "../../../UF-mui/components/form/UFFormTextField";
import {LandingPageConfiguration} from "../../../types/LandingPageConfiguration";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {TT} from "../../TT";
import {LandingPageStoreActions} from "../../../store/landingPage/LandingPageStoreActions";
import {EditorMode} from "../../../constants/EditorMode";
import {DescriptionField} from "../../form/DescriptionField";
import {CategorySelect} from "../../form/CategorySelect";
import {ClassificationSelect} from "../../form/ClassificationSelect";
import {ApplicationDialogStoreActions} from "../../../store/dialogs/applicationDialog/ApplicationDialogStoreActions";
import {ApplicationDialogType} from "../../../constants/ApplicationDialogType";

// endregion

// region local types and functions

/**
 * Properties for the component.
 */
interface LandingPageConfigurationPropertiesProps {
  configuration: LandingPageConfiguration;
  setConfiguration: typeof LandingPageStoreActions.setConfiguration;
  show: typeof ApplicationDialogStoreActions.show;
  mode: EditorMode;
}

/**
 * Maps application state to component properties.
 */
function mapStateToProps(state: ApplicationState) {
  return {
    configuration: state.landingPage.configuration,
    mode: state.editor.mode,
  };
}

// endregion

// region component

/**
 * The landing page configuration properties.
 */
export const LandingPageConfigurationProperties = connect(
  mapStateToProps,
  {
    setConfiguration: LandingPageStoreActions.setConfiguration,
    show: ApplicationDialogStoreActions.show,
  }
)(
  ({configuration, setConfiguration, show, mode}: LandingPageConfigurationPropertiesProps) => (
    <PropertyGroup title={<TT ttid="page-configuration.title">Configuration</TT>}>
      {
        (mode == EditorMode.Campaign) &&
        <UFFormTextField
          label={<TT ttid="page-configuration.page-title">Title</TT>}
          value={configuration.title}
          onFieldChange={({value}) => setConfiguration({...configuration, title: value})}
        />
      }
      {
        (mode == EditorMode.Campaign) &&
        <UFFormTextField
          label={<TT ttid="page-configuration.page-code">Code for url</TT>}
          value={configuration.urlCode}
          readOnly={true}
          onClick={() => show(ApplicationDialogType.ChangeCode)}
        />
      }
      {
        (mode == EditorMode.Campaign) &&
        <DescriptionField
          value={configuration.description}
          onChange={(value) => setConfiguration({...configuration, description: value})}
          shortHelp={true}
        />
      }
      <CategorySelect
        label={<TT ttid="page-configuration.initial-category">Initial category</TT>}
        value={configuration.initialCategory}
        onChange={(value) => setConfiguration({...configuration, initialCategory: value})}
      />
      <ClassificationSelect
        label={<TT ttid="page-configuration.initial-classification">Initial classification</TT>}
        value={configuration.initialClassification}
        onChange={(value) => setConfiguration({...configuration, initialClassification: value})}
      />
    </PropertyGroup>
  )
);

// endregion
