// region imports

import * as React from 'react';
import {useState} from 'react';
import {DialogContent, DialogTitle, Typography} from "@mui/material";
import {TT} from "../TT";
import {UFStyledDialogActions} from "../../UF-mui/components/dialogs/UFStyledDialogActions";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import {DialogButton} from "../controls/DialogButton";
import {CampaignSelect} from "../form/CampaignSelect";
import {MainController} from "../../controllers/MainController";
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {UFDialog} from '../../UF-mui/components/dialogs/UFDialog';

// endregion

// region local types and functions

/**
 * Properties for component
 */
interface SelectCampaignDialogProps {
  readonly onClose?: () => any;
  readonly selectedCampaign: string;
  readonly dialog?: boolean;
}

function mapStateToProps(anApplicationState: ApplicationState) {
  return {
    selectedCampaign: anApplicationState.user.selectedCampaign
  }
}

// endregion

// region component

/**
 * {@link SelectCampaignDialog} shows the module types for the user to select one.
 */
export const SelectCampaignDialog = connect(mapStateToProps)(
  ({onClose = () => 0, selectedCampaign, dialog = true}: SelectCampaignDialogProps) => {
    const [campaign, setCampaign] = useState('');
    const [step, setStep] = useState(1);
    const [newLandingPage, setNewLandingPage] = useState(true);
    return (
      <UFDialog
        open={true}
        onClose={() => dialog && onClose()}
        fullWidth={true}
        modal={dialog}
        maxWidth="sm"
        hideBackdrop={!dialog}
        BackdropProps={{
          style: {
            pointerEvents: 'none'
          }
        }}
        PaperProps={{
          style: {
            pointerEvents: 'all'
          }
        }}
      >
        {
          (step == 1) &&
          <React.Fragment>
            <DialogTitle>
              <TT ttid="select-campaign.new-landing-page-title">Create new landing page</TT>
            </DialogTitle>
            <DialogContent>
              <Typography marginBottom={1}>
                <TT ttid="select-campaign.new-landing-page-text">
                  Select a campaign to start creating a landing page for.
                </TT>
              </Typography>
              <DialogButton
                onClick={() => {
                  setCampaign('');
                  setNewLandingPage(true);
                  setStep(2);
                }}
                startIcon={<InsertDriveFileOutlinedIcon/>}
                size="large"
              >
                <TT ttid="select-campaign.new-landing-page-caption">New landing page</TT>
              </DialogButton>
            </DialogContent>
            <DialogTitle>
              <TT ttid="select-campaign.existing-landing-page-title">Edit existing landing page</TT>
            </DialogTitle>
            <DialogContent>
              <Typography marginBottom={1}>
                <TT ttid="select-campaign.existing-landing-page-text">
                  Select a campaign to continue editing the landing page of.
                </TT>
              </Typography>
              <DialogButton
                onClick={() => {
                  setCampaign('');
                  setNewLandingPage(false);
                  setStep(2);
                }}
                startIcon={<EditIcon/>}
                size="large"
              >
                <TT ttid="select-campaign.existing-landing-page-caption">Edit existing landing page</TT>
              </DialogButton>
            </DialogContent>
            {
              dialog &&
              <UFStyledDialogActions>
                <DialogButton
                  onClick={onClose}
                  startIcon={<CloseIcon/>}
                  color="secondary"
                >
                  <TT>Cancel</TT>
                </DialogButton>
              </UFStyledDialogActions>
            }
          </React.Fragment>
        }
        {
          (step == 2) &&
          <React.Fragment>
            <DialogTitle>
              {
                newLandingPage &&
                <TT ttid="select-campaign.new-landing-page-title">Create new landing page</TT>
              }
              {
                !newLandingPage &&
                <TT ttid="select-campaign.existing-landing-page-title">Edit existing landing page</TT>
              }
            </DialogTitle>
            <DialogContent>
              <Typography marginBottom={1}>
                <TT ttid="select-campaign.label">Select a campaign to edit the landing page for.</TT>
              </Typography>
              <CampaignSelect
                value={campaign}
                onChange={setCampaign}
                withLandingPage={!newLandingPage}
                skipKey={(dialog && !newLandingPage) ? selectedCampaign : ''}
              />
            </DialogContent>
            <UFStyledDialogActions>
              <DialogButton
                onClick={() => {
                  // noinspection JSIgnoredPromiseFromCall
                  MainController.instance.selectCampaign(campaign);
                  if (dialog) {
                    onClose();
                  }
                }}
                variant="contained"
                startIcon={<CheckIcon/>}
                disabled={campaign.length <= 0}
              >
                <TT ttid="select-campaign.select-caption">Select campaign</TT>
              </DialogButton>
              <DialogButton
                onClick={() => setStep(1)}
                startIcon={<ArrowBackIosIcon/>}
              >
                <TT>Back</TT>
              </DialogButton>
              {dialog &&
                <DialogButton
                  onClick={onClose}
                  startIcon={<CloseIcon/>}
                  color="secondary"
                >
                  <TT>Cancel</TT>
                </DialogButton>
              }
            </UFStyledDialogActions>
          </React.Fragment>
        }
      </UFDialog>
    );
  }
);

// endregion
