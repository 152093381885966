// region imports

import * as React from 'react';
import {MenuItem, Stack} from "@mui/material";
import {ImageFitType} from "../../constants/ImageFitType";
import {TT} from "../TT";
import {UFFormSelect} from "../../UF-mui/components/form/UFFormSelect";
import imageContain from '../images/image-contain.png';
import imageCover from '../images/image-cover.png';
import imageStretch from '../images/image-stretch.png';

// endregion

// region local types and functions

/**
 * Properties for the component.
 */
interface ImageFitSelectProps {
  readonly value: ImageFitType;
  readonly onChange: (value: ImageFitType) => any;
  readonly label?: React.ReactNode;
  readonly disabled?: boolean;
}

// endregion

// region component

/**
 * {@link ImageFitSelect} renders a selection control to select a {@link ImageFitType}
 */
export const ImageFitSelect: React.FC<ImageFitSelectProps> = (
  {
    value,
    label = <TT ttid="image-fit.title">Image fit</TT>,
    disabled = false,
    onChange
  }
) => (
  <UFFormSelect
    label={label}
    value={value}
    onFieldChange={({value}) => onChange(value)}
    disabled={disabled}
  >
    <MenuItem value={ImageFitType.Contain}>
      <Stack direction="row" gap={1} alignItems="center">
        <img src={imageContain} alt="" />
        <TT ttid="image-fit.contain">Contain the image</TT>
      </Stack>
    </MenuItem>
    <MenuItem value={ImageFitType.Cover}>
      <Stack direction="row" gap={1} alignItems="center">
        <img src={imageCover} alt="" />
      <TT ttid="image-fit.cover">Cover the whole area</TT>
      </Stack>
    </MenuItem>
    <MenuItem value={ImageFitType.Stretch}>
      <Stack direction="row" gap={1} alignItems="center">
        <img src={imageStretch} alt="" />
      <TT ttid="image-fit.contain">Stretch the image</TT>
      </Stack>
    </MenuItem>
  </UFFormSelect>
);

// endregion