// region imports

import {MainState} from "./MainState";
import {ReducerActionType} from "../ReducerActionType";
import {MainStoreActions} from "./MainStoreActions";

// endregion

// region local types

/**
 * Initial state to use
 */
const initialState : MainState = {
  initialized: false
}

/**
 * Combination of all actions.
 */
type MainAction = ReturnType<typeof MainStoreActions.setInitialized>;

// endregion

// region exports

export function mainReducer(aState: MainState = initialState, anAction: MainAction): MainState {
  switch(anAction.type) {
    case ReducerActionType.SetInitialized:
      return {
        ...aState,
        initialized: true
      };
    default:
      return aState;
  }
}

// endregion