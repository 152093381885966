// region imports

import {PropertyGroup} from "../PropertyGroup";
import * as React from "react";
import {LandingPageColors} from "../../../types/LandingPageColors";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {ColorField} from "../../form/ColorField";
import {TT} from "../../TT";
import {LandingPageStoreActions} from "../../../store/landingPage/LandingPageStoreActions";

// endregion

// region local types and functions

/**
 * Properties for the component.
 */
interface LandingPageColorsPropertiesProps {
  colors: LandingPageColors;
  setColors: typeof LandingPageStoreActions.setColors;
}

/**
 * Maps application state to component properties.
 */
function mapStateToProps(state: ApplicationState) {
  return {
    colors: state.landingPage.colors
  };
}

// endregion

// region component

/**
 * The landing page font properties.
 */
export const LandingPageColorProperties = connect(mapStateToProps, {setColors: LandingPageStoreActions.setColors})(
  ({colors, setColors}: LandingPageColorsPropertiesProps) => (
    <PropertyGroup title={<TT ttid="color-properties.title">Colors</TT>}>
      <ColorField
        label={<TT ttid="color-properties.text">Text color</TT>}
        value={colors.text}
        onChange={(value) => setColors({...colors, text: value})}
      />
      <ColorField
        label={<TT ttid="color-properties.background">Background color</TT>}
        value={colors.background}
        onChange={(value) => setColors({...colors, background: value})}
      />
      <ColorField
        label={<TT ttid="color-properties.link">Link color</TT>}
        value={colors.link}
        onChange={(value) => setColors({...colors, link: value})}
      />
      <ColorField
        label={<TT ttid="color-properties.button-text">Button text color</TT>}
        value={colors.buttonText}
        onChange={(value) => setColors({...colors, buttonText: value})}
      />
      <ColorField
        label={<TT ttid="color-properties.button-background">Button background color</TT>}
        value={colors.buttonBackground}
        onChange={(value) => setColors({...colors, buttonBackground: value})}
      />
    </PropertyGroup>
  )
);

// endregion