// region imports

import * as React from 'react';
import {Box, Stack, Step, StepLabel, Stepper, Typography} from "@mui/material";
import {TT} from "../TT";
import {DialogPage} from "./DialogPage";
import {SelectSourceTemplate} from "./parts/SelectSourceTemplate";
import {InitialLandingPageConfiguration} from "./parts/InitialLandingPageConfiguration";
import {DialogButton} from "../controls/DialogButton";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {SelectCopySource} from "./parts/SelectCopySource";
import {LandingPageCopySource} from "../../constants/LandingPageCopySource";
import {SelectSourceCampaign} from "./parts/SelectSourceCampaign";
import {CategoryType} from "../../constants/CategoryType";
import {ServerResultText} from "../texts/ServerResultText";
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";
import {UFBusy} from "../../UF-mui/components/wrappers/UFBusy";
import {LandingPageConfiguration} from "../../types/LandingPageConfiguration";
import {ServerResult} from "../../constants/ServerResult";
import {NetworkController} from "../../controllers/NetworkController";
import {MainController} from "../../controllers/MainController";
import {Config} from "../../constants/Config";
import {ClassificationType} from "../../constants/ClassificationType";
import {Campaign} from "../../types/Campaign";
import {UFCollapsableAlert} from "../../UF-mui/components/controls/UFCollapsableAlert";

// endregion

// region local types and functions

interface NewLandingPagePageProps {
  readonly networkBusy: boolean;
  readonly webmasterCode: string;
  readonly selectedCampaign: Campaign;
}

interface NewLandingPagePageState {
  step: number;
  source: LandingPageCopySource;
  campaign: string;
  template: string;
  nextEnabled: boolean;
  serverResult: ServerResult;
  configuration: LandingPageConfiguration;
  errorMessage: boolean;
}

function mapStateToProps(anApplicationState: ApplicationState) {
  return {
    networkBusy: anApplicationState.network.networkBusy,
    webmasterCode: anApplicationState.user.urlCode,
    selectedCampaign: anApplicationState.user.campaigns.find(
      campaign => campaign.key == anApplicationState.user.selectedCampaign
    )!
  }
}

// region component

/**
 * {@link ChangeCodeDialog} shows the module types for the user to select one.
 */
export const NewLandingPagePage = connect(mapStateToProps)(
  class extends React.Component<NewLandingPagePageProps, NewLandingPagePageState> {
    // region constructor

    constructor(aProps: NewLandingPagePageProps) {
      super(aProps);
      this.state = {
        errorMessage: false,
        step: 0,
        source: LandingPageCopySource.None,
        campaign: '',
        template: '',
        nextEnabled: false,
        serverResult: ServerResult.None,
        configuration: {
          title: '',
          urlCode: '',
          initialCategory: CategoryType.General,
          initialClassification: ClassificationType.HardCore,
          description: ''
        }
      }
    }

    // endregion

    // region event handlers

    private async handleCreateClick() {
      const result = await NetworkController.instance.createLandingPage(
        this.state.configuration,
        this.state.source,
        this.state.source == LandingPageCopySource.Campaign ? this.state.campaign : this.state.template
      );
      if (result == ServerResult.Success) {
        MainController.instance.finishLocation();
        return;
      }
      this.setState({
        serverResult: result,
        errorMessage: true
      });
    }

    // endregion

    // region render methods

    renderSteps() {
      return (
        <Stepper activeStep={this.state.step} sx={{width: '100%', marginBottom: 1}}>
          <Step>
            <StepLabel><TT ttid="new-landing-page.step-1">Create from</TT></StepLabel>
          </Step>
          <Step>
            <StepLabel><TT ttid="new-landing-page.step-2">Select source</TT></StepLabel>
          </Step>
          <Step>
            <StepLabel><TT ttid="new-landing-page.step-3">Initial properties</TT></StepLabel>
          </Step>
        </Stepper>
      )
    }

    renderContentStep0() {
      return (
        <SelectCopySource
          hasCampaigns={true}
          onSelect={
            (choice) => this.setState({
              source: choice,
              step: choice == LandingPageCopySource.None ? 2 : 1
            })
          }
        />
      );
    }

    renderContentStep1() {
      return (
        <React.Fragment>
          {
            (this.state.source == LandingPageCopySource.Template) &&
            <SelectSourceTemplate
              value={this.state.template}
              onChange={(value) => this.setState({template: value, nextEnabled: true})
              }
            />
          }
          {
            (this.state.source == LandingPageCopySource.Campaign) &&
            <SelectSourceCampaign
              onChange={(value) => this.setState({campaign: value, nextEnabled: true})}
              value={this.state.campaign}
            />
          }
        </React.Fragment>
      )
    }

    renderContentStep2() {
      return (
        <React.Fragment>
          <UFCollapsableAlert
            message={<ServerResultText result={this.state.serverResult}/>}
            severity="error"
            onClose={() => this.setState({errorMessage: false})}
            visible={this.state.errorMessage}
          />
          <InitialLandingPageConfiguration
            busy={this.props.networkBusy}
            configuration={this.state.configuration}
            onChange={(value) => this.setState({
              configuration: value,
              serverResult: ServerResult.None,
              errorMessage: false
            })}
            error={![ServerResult.None, ServerResult.Success].includes(this.state.serverResult)}
            webmasterCode={this.props.webmasterCode}
          />
        </React.Fragment>
      );
    }

    renderButtons() {
      return (
        <Stack
          direction="row"
          gap={Config.propertiesGapSize}
          width="100%"
          flex={1}
          alignItems="flex-end"
        >
          <Box flex={1}>
            {
              (this.state.step ==  2) &&
              <Typography><TT ttid="new-landing-page.required">(*) required field</TT></Typography>
            }
          </Box>
          {
            (this.state.step == 1) &&
            <DialogButton
              startIcon={<NavigateNextIcon/>}
              onClick={() => this.setState({step: 2})}
              disabled={!this.state.nextEnabled}
            >
              <TT ttid="new-landing-page.next-step">Next step</TT>
            </DialogButton>
          }
          {
            (this.state.step == 2) &&
            <React.Fragment>
              <UFBusy busy={this.props.networkBusy}>
                <DialogButton
                  disabled={this.props.networkBusy || !this.state.configuration.urlCode.length}
                  onClick={async () => await this.handleCreateClick()}
                >
                  <TT ttid="new-landing-page.create-caption">Create landing page</TT>
                </DialogButton>
              </UFBusy>
            </React.Fragment>
          }
          {
            (this.state.step > 0) &&
            <DialogButton
              color="secondary"
              startIcon={<NavigateBeforeIcon/>}
              onClick={() => this.setState({
                step: ((this.state.source == LandingPageCopySource.None) || (this.state.step == 1) ? 0 : 1)
              })}
              disabled={this.props.networkBusy}
            >
              <TT ttid="new-landing-page.previous-step">Previous step</TT>
            </DialogButton>
          }
        </Stack>
      );
    }

    // endregion

    // region react callbacks

    render() {
      return (
        <DialogPage
          title={
            <TT
              ttid="new-landing-page.title"
              map={{'$campaign$': this.props.selectedCampaign.name}}
              html={true}
            >
              Create a landing page for <em>$campaign$</em>
            </TT>
          }
          width="850px"
        >
          <Stack
            direction="column"
            gap="1rem"
            sx={{
              height: '600px'
            }}
            alignItems="flex-start"
            spacing={0}
          >
            {this.renderSteps()}
            {
              (this.state.step == 0) && this.renderContentStep0()
            }
            {
              (this.state.step == 1) && this.renderContentStep1()
            }
            {
              (this.state.step == 2) && this.renderContentStep2()
            }
            {this.renderButtons()}
          </Stack>
        </DialogPage>
      );
    }

    // endregion
  }
);

// endregion
