// region imports

import * as React from 'react';
import {useEffect, useRef} from 'react';
import {FontFamily} from "../../constants/FontFamily";
import MUIRichTextEditor, {TMUIRichTextEditorRef} from "mui-rte";
import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js";
import {stateToHTML} from "draft-js-export-html";
import {Theme, ThemeProvider} from "@mui/material/styles";
import {createTheme} from "@mui/material";

// endregion

// region local types and functions

/**
 * Properties for the component
 */
interface HtmlEditorProps {
  /**
   * Initial html value.
   */
  value: string;

  /**
   * Text color to use within editor area
   */
  textColor: string;

  /**
   * Font family to use
   */
  fontFamily: FontFamily;

  /**
   * Font size in pixels
   */
  fontSize: number;

  /**
   * Background color to use within editor area
   */
  backgroundColor: string;

  /**
   * This callback is called with every change.
   *
   * @param {string} value
   *   New value
   */
  onChange: (value: string) => void,

  /**
   * When true show controls to change the font size
   */
  showFontSize?: boolean;
}

// endregion

// region component

/**
 * {@link HtmlEditor} is an editor showing a some basic styling options.
 */
export const HtmlEditor: React.FC<HtmlEditorProps> = (
  {value, textColor, backgroundColor, fontFamily, onChange, showFontSize, fontSize}
) => {
  const contentHTML = convertFromHTML(value);
  const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap);
  const content = JSON.stringify(convertToRaw(state));
  const ref = useRef<TMUIRichTextEditorRef>(null);
  useEffect(() => {
    ref.current?.focus();
  });
  return (
    <ThemeProvider
      theme={(theme: Theme) => {
        // use any type and assign directly, since components type does not contain a MUIRichtTextEditor type
        const newTheme: any = createTheme(theme);
        newTheme.components.MUIRichTextEditor = {
          styleOverrides: {
            editor: {
              border: '1px solid #d0d0d0',
              borderRadius: '4px',
              minHeight: '2.6rem',
              padding: '0 0.5rem',
              color: textColor,
              background: backgroundColor,
              fontFamily: fontFamily,
              fontSize: fontSize + 'px',
            }
          }
        };
        return newTheme;
      }}
    >
      <MUIRichTextEditor
        defaultValue={content}
        controls={['bold', 'italic', 'undo', 'redo', 'clear']}
        toolbarButtonSize="small"
        onChange={(state: EditorState) => {
          onChange(stateToHTML(state.getCurrentContent()));
        }}
        ref={ref}
        inheritFontSize={true}
      />
    </ThemeProvider>
  )
};

// endregion