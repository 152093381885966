/**
 * Possible source to create new landing page with
 */
export enum LandingPageCopySource {
  /**
   * Create empty landing page.
   */
  None,

  /**
   * Create from a template.
   */
  Template,

  /**
   * Create from another campaign.
   */
  Campaign
}