// region imports

import * as React from 'react';
import {PropertyGroupsContainer} from "../PropertyGroupsContainer";
import {ModuleActions} from "../ModuleActions";
import {PropertyGroup} from "../PropertyGroup";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {LandingPageModule} from "../../../types/LandingPageModule";
import {LandingPageColors} from "../../../types/LandingPageColors";
import {LandingPageFont} from "../../../types/LandingPageFont";
import {TT} from "../../TT";
import {UFFormTextField} from "../../../UF-mui/components/form/UFFormTextField";
import {LandingPageStoreActions} from "../../../store/landingPage/LandingPageStoreActions";
import {StylingTools} from "../../../tools/StylingTools";

// endregion

// region local types

/**
 * Properties for component.
 */
interface ButtonModulePropertiesProps {
  readonly visible: boolean;
  readonly moduleKey: string;
  readonly module: LandingPageModule;
  readonly updateModule: typeof LandingPageStoreActions.updateModule;
  readonly colors: LandingPageColors;
  readonly font: LandingPageFont;
}

/**
 * Maps state to local properties.
 */
function mapStateToProps(anApplicationState: ApplicationState, aProps: Partial<ButtonModulePropertiesProps>) {
  return {
    module: anApplicationState.landingPage.modules[aProps.moduleKey!],
    colors: anApplicationState.landingPage.colors,
    font: anApplicationState.landingPage.font
  }
}

// region

/**
 * All properties for the module.
 */
export const ButtonModuleProperties = connect(mapStateToProps, {updateModule: LandingPageStoreActions.updateModule})(
  ({visible, module, colors, font, updateModule}: ButtonModulePropertiesProps) => {
    return (
      <PropertyGroupsContainer title={<TT ttid="button-properties.title">Button module settings</TT>} visible={visible}>
        <ModuleActions module={module} />
        <PropertyGroup title={<TT>Content</TT>} defaultExpanded={true}>
          <UFFormTextField
            label={<TT>Caption</TT>}
            value={module.text}
            fontFamily={StylingTools.getFontFamily(font.family)}
            fontSize={font.size + 'px'}
            onFieldChange={({value}) => updateModule({...module, text: value})}
          />
        </PropertyGroup>
      </PropertyGroupsContainer>
    );
  }
);

// endregion