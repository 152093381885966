// region imports

import * as React from 'react';
import {LandingPageColors} from "../../types/LandingPageColors";
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";
import {ModuleContent} from "./ModuleContent";
import {LandingPageModule} from "../../types/LandingPageModule";
import {ModuleBackgroundType} from "../../constants/ModuleBackgroundType";
import {StylingTools} from "../../tools/StylingTools";
import {ModuleType} from "../../constants/ModuleType";
import {HeaderBannerModule} from "./modules/HeaderBannerModule";
import {ButtonModule} from "./modules/ButtonModule";
import {BannerModule} from "./modules/BannerModule";
import {CamsModule} from "./modules/CamsModule";
import {VideosModule} from "./modules/VideosModule";
import {SingleImageAndTextModule} from "./modules/SingleImageAndTextModule";
import {StarShowsModule} from "./modules/StarShowsModule";
import {TwoImagesAndTextsModule} from "./modules/TwoImagesAndTextsModule";
import {UserRegistrationModule} from "./modules/UserRegistrationModule";
import {VoucherModule} from "./modules/VoucherModule";

// endregion

// region local types and functions

/**
 * Properties for component.
 */
interface ModuleProps {
  /**
   * Width of content
   */
  readonly contentWidth: number;

  /**
   * Background type
   */
  readonly moduleBackground: ModuleBackgroundType;

  /**
   * Unique key for element
   */
  readonly moduleKey: string;

  /**
   * Index of module
   */
  readonly module: LandingPageModule;

  /**
   * The selected module
   */
  readonly selectedModule: string;

  /**
   * Landing page colors
   */
  readonly colors: LandingPageColors;
}

/**
 * Maps store state to component properties.
 */
function mapStatsToProps(anApplicationState: ApplicationState, {moduleKey}: Partial<ModuleProps>) {
  return {
    colors: anApplicationState.landingPage.colors,
    selectedModule: anApplicationState.landingPage.selectedModule,
    module: anApplicationState.landingPage.modules[moduleKey!]
  }
}

// endregion

// region component

/**
 * The top bar in the landing page.
 */
export const Module = connect(mapStatsToProps)(
  ({contentWidth, moduleBackground, colors, module, selectedModule}: ModuleProps) => {
    const type = module!.type;
    return (
      <ModuleContent
        contentWidth={contentWidth}
        backgroundColor={StylingTools.getModuleBackground(moduleBackground, colors!)}
        selected={selectedModule == module!.key}
        moduleKey={module!.key}
      >
        {
          (type == ModuleType.Button) &&
          <ButtonModule moduleBackground={moduleBackground} module={module!}/>
        }
        {
          (type == ModuleType.Banner) &&
          <BannerModule moduleBackground={moduleBackground} module={module!}/>
        }
        {
          (type == ModuleType.Cams) &&
          <CamsModule moduleBackground={moduleBackground} module={module!}/>
        }
        {
          (type == ModuleType.HeaderBanner) &&
          <HeaderBannerModule moduleBackground={moduleBackground} module={module!}/>
        }
        {
          (type == ModuleType.SingleImageAndText) &&
          <SingleImageAndTextModule moduleBackground={moduleBackground} module={module!}/>
        }
        {
          (type == ModuleType.StarShows) &&
          <StarShowsModule moduleBackground={moduleBackground} module={module!}/>
        }
        {
          (type == ModuleType.TwoImagesAndTexts) &&
          <TwoImagesAndTextsModule moduleBackground={moduleBackground} module={module!}/>
        }
        {
          (type == ModuleType.UserRegistration) &&
          <UserRegistrationModule moduleBackground={moduleBackground} module={module!}/>
        }
        {
          (type == ModuleType.Videos) &&
          <VideosModule moduleBackground={moduleBackground} module={module!}/>
        }
        {
          (type == ModuleType.Voucher) &&
          <VoucherModule moduleBackground={moduleBackground} module={module!}/>
        }
      </ModuleContent>
    )
  }
);

// endregion
