// region imports

import {NetworkState} from "./NetworkState";
import {ReducerActionType} from "../ReducerActionType";
import {NetworkStoreActions} from "./NetworkStoreActions";

// endregion

// region local types

/**
 * Initial state to use
 */
const initialState : NetworkState = {
  busySaving: false,
  networkBusy: false,
  saveDelayTime: 0
}

/**
 * Combination of all actions.
 */
type NetworkAction = ReturnType<typeof NetworkStoreActions.setBusySaving>
  | ReturnType<typeof NetworkStoreActions.setSaveDelayTime> | ReturnType<typeof NetworkStoreActions.setNetworkBusy>;

// endregion

// region exports

export function networkReducer(aState: NetworkState = initialState, anAction: NetworkAction): NetworkState {
  switch(anAction.type) {
    case ReducerActionType.SetBusySaving:
      return {
        ...aState,
        busySaving: anAction.busy
      };
    case ReducerActionType.SetSaveDelayTime:
      return {
        ...aState,
        saveDelayTime: anAction.time
      }
    case ReducerActionType.SetNetworkBusy:
      return {
        ...aState,
        networkBusy: anAction.busy
      };
    default:
      return aState;
  }
}

// endregion