// region imports

import * as React from "react";
import {Button, Stack, Typography} from "@mui/material";
import {ApplicationState} from "../../store/ApplicationState";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import {connect} from "react-redux";
import {TT} from "../TT";
import {UFDotAnimation} from "../../UF-react/components/UFDotAnimation";
import {NetworkController} from "../../controllers/NetworkController";
import {UFBusy} from "../../UF-mui/components/wrappers/UFBusy";

// endregion

// region local types and functions

/**
 * Properties for the component.
 */
interface SaveButtonProps {
  dirty: boolean;
  saveDelayTime: number;
  busySaving: boolean;
}

/**
 * Maps application state to component properties.
 *
 * @param {ApplicationState} state
 * @returns {{viewScale: number}}
 */
const mapStateToProps = (state: ApplicationState) => {
  return {
    dirty: state.landingPage.dirty,
    saveDelayTime: state.network.saveDelayTime,
    busySaving: state.network.busySaving,
  };
};

// endregion

// region component

/**
 * {@link ViewScale} shows a slider to change the viewing scale.
 */
export const SaveButton = connect(mapStateToProps)(
  ({dirty, saveDelayTime, busySaving}: SaveButtonProps) => {
    return (
      <Stack spacing={1} direction="row" alignItems="center">
        <UFBusy busy={busySaving}>
          <Button
            disabled={!dirty || busySaving}
            color="inherit"
            variant="outlined"
            size="small"
            startIcon={<SaveOutlinedIcon/>}
            onClick={() => NetworkController.instance.save()}
          >
            <TT ttid="save.caption">Save now</TT>
          </Button>
        </UFBusy>
        {
          !dirty && !busySaving &&
          <Typography variant="caption">
            <TT ttid="save.nothing">Nothing to save</TT>
          </Typography>
        }
        {
          busySaving &&
          <Typography variant="caption">
            <TT ttid="save.busy">Saving in the background</TT><UFDotAnimation/>
          </Typography>
        }
        {
          dirty && !busySaving &&
          <Typography variant="caption">
            <TT ttid="save.waiting" map={{'$seconds$': saveDelayTime}}>Automatic saving in $seconds$ seconds</TT>
          </Typography>
        }
      </Stack>
    )
  });

// endregion