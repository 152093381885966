// region imports

import {Action, applyMiddleware, createStore, Reducer, Store} from 'redux';
import {throttle} from 'underscore';
import logger from 'redux-logger';
import thunk, {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {Config} from "../constants/Config";
import {ApplicationState} from "./ApplicationState";
import {appReducer} from "./appReducer";
import {ReducerActionType} from "./ReducerActionType";

// endregion

// region types

/**
 * Create dispatch function type for thunk actions.
 *
 * Based on:
 * https://stackoverflow.com/a/57102280/968451
 */
type DispatchFunctionType = ThunkDispatch<ApplicationState, undefined & null, Action<ReducerActionType>>;

// endregion

// region support functions

/**
 * Combines all reducers in the application.
 *
 * @returns All reducers combined into a single instance
 */
function createReducers() {
  // use special root reducer, that does not mutate the store
  return ((state: ApplicationState, action: Action<ReducerActionType>) => {
    return appReducer(state, action);
  }) as Reducer<ApplicationState, Action<ReducerActionType>>;
}

/**
 * Loads the redux state from local storage.
 *
 * @returns Loaded state or undefined if no state was stored or the version of the stored data no longer
 *   matches the {@link Config.storeVersion} value.
 */
function loadState() {
  try {
    const serializedState = localStorage.getItem(Config.storageKey);
    if (serializedState !== null) {
      let result: any = JSON.parse(serializedState);
      if (result.__version && (result.__version === Config.storeVersion)) {
        // remove version property from state data
        result.__version = undefined;
        delete result.__version;
        return result;
      }
      // remove store data from localstorage (no longer valid)
      localStorage.removeItem(Config.storageKey);
    }
  } catch (error) {
    console.error('loadState', error);
  }
  return undefined;
}

/**
 * Saves the redux state to the local storage.
 *
 * @param aState
 *   State (should be serializable as JSON)
 */
function saveState(aState: any) {
  try {
    aState.__version = Config.storeVersion;
    const serializedState = JSON.stringify(aState);
    localStorage.setItem(Config.storageKey, serializedState);
  } catch (error) {
    console.error('saveState', error);
  }
}

/**
 * Saves the redux store to the local storage.
 *
 * @param {ApplicationState} anApplicationState
 *   Complete store
 */
function saveStore(anApplicationState: ApplicationState) {
  saveState({
    // todo remove landing page from local storage
    landingPage: anApplicationState.landingPage,
    settings: anApplicationState.settings,
    editor: anApplicationState.editor,
    credentials: anApplicationState.credentials
  });
}

// endregion

// region exports

/**
 * The store type including extra dispatch type for thunk actions.
 */
export type ApplicationStore = Store<ApplicationState, Action<ReducerActionType>> & {dispatch: DispatchFunctionType};

/**
 * The type for thunk actions used with reducers.
 */
export type ThunkStoreAction = ThunkAction<void, ApplicationState, null, Action<ReducerActionType>>;

/**
 * Builds the store by adding middlewares and adding support for saving to and retrieving from local storage.
 */
export function buildStore(): ApplicationStore {
  // create initial used redux middlewares
  const middlewares: any[] = [thunk];
  // add logger in non production environments
  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(logger);
  }
  // create store
  const result = createStore(
    createReducers(), loadState(), applyMiddleware<DispatchFunctionType, ApplicationState>(...middlewares)
  );
  // save store with every change (minimal interval between save is 1 second)
  result.subscribe(throttle(() => saveStore(result.getState()), 1000));
  // return the created store
  return result;
}

// endregion
