// region imports

import * as React from 'react';
import {Box, ClickAwayListener, Paper, Portal} from "@mui/material";
import {HtmlEditor} from "../form/HtmlEditor";
import {FontFamily} from "../../constants/FontFamily";
import {UFKeyboard} from "../../UF-react/components/UFKeyboard";
import {DialogButton} from "../controls/DialogButton";
import {Config} from "../../constants/Config";
import {TT} from "../TT";

// endregion

// local types and functions

interface EditDialogProps {
  /**
   * Initial value to show
   */
  value: string;

  /**
   * When true show font size styling option.
   */
  allowFontSize: boolean;

  /**
   * Text color to use for editor
   */
  textColor: string;

  /**
   * Background color to use for editor
   */
  backgroundColor: string;

  /**
   * Font family to use
   */
  fontFamily: FontFamily;

  /**
   * Size of font
   */
  fontSize: number;

  /**
   * Callback that is called when user closes the dialog.
   */
  onClose: () => void

  /**
   * Callback that is called every time the text changes.
   *
   * @param {string} value
   */
  onChange: (value: string) => void
}

// endregion

// region component

/**
 * {@link EditDialog} shows a dialog at the bottom of a screen to edit a html formatted text. It can be closed
 * by either clicking outside or pressing the escape key.
 */
export const EditDialog: React.FC<EditDialogProps> = (
  {
    allowFontSize, textColor, backgroundColor, fontFamily, fontSize,
    onClose, onChange, value
  }
) => {
  return (
    <Portal>
      <UFKeyboard keys={UFKeyboard.ESCAPE} onDown={onClose}/>
      <ClickAwayListener
        onClickAway={onClose}
      >
        <Paper sx={{
          position: 'absolute',
          bottom: '0.75rem',
          left: '8rem',
          right: '8rem',
          padding: '0 0.5rem 0.5rem',
          height: 'auto',
          minHeight: '5.5rem',
          boxShadow: '0 0 8px -1px rgba(0,0,0,0.5)',
          display: 'flex',
          flexDirection: 'column',
          gap: Config.propertiesGapSize,
          alignItems: 'flex-end'
        }}>
          <Box
            width="100%">
            <HtmlEditor
              showFontSize={allowFontSize}
              textColor={textColor}
              backgroundColor={backgroundColor}
              fontFamily={fontFamily}
              fontSize={fontSize}
              value={value}
              onChange={onChange}
            />
          </Box>
          <DialogButton
            onClick={onClose}
          >
            <TT>Ok</TT>
          </DialogButton>
        </Paper>
      </ClickAwayListener>
    </Portal>
  );
}

// endregion
