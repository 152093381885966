// region imports

import * as React from 'react';
import {LandingPageModule} from "../../types/LandingPageModule";
import {ModuleType} from "../../constants/ModuleType";
import {BannerModuleProperties} from "./bannerModule/BannerModuleProperties";
import {ButtonModuleProperties} from "./buttonModule/ButtonModuleProperties";
import {CamsModuleProperties} from "./camsModule/CamsModuleProperties";
import {HeaderBannerModuleProperties} from "./headerBannerModule/HeaderBannerModuleProperties";
import {SingleImageAndTextModuleProperties} from "./singleImageAndTextModule/SingleImageAndTextModuleProperties";
import {StarShowsModuleProperties} from "./starShowsModule/StarShowsModuleProperties";
import {TwoImagesAndTextsModuleProperties} from "./twoImagesAndTextsModule/TwoImagesAndTextsModuleProperties";
import {UserRegistrationModuleProperties} from "./userRegistrationModule/UserRegistrationModuleProperties";
import {VideosModuleProperties} from "./videosModule/VideosModuleProperties";
import {VoucherModuleProperties} from "./voucherModule/VoucherModuleProperties";

// endregion

// region local types and functions

interface ModulePropertiesProps {
  type: ModuleType;
  moduleKey: string;
  visible: boolean;
}

// endregion

// region component

/**
 * {@link ModuleProperties} shows the properties for a module.
 */
export const ModuleProperties: React.FC<ModulePropertiesProps> = (
  {type, moduleKey, visible}
) => (
  <React.Fragment>
    {
      (type == ModuleType.Banner) &&
      <BannerModuleProperties moduleKey={moduleKey} visible={visible}/>
    }
    {
      (type == ModuleType.Button) &&
      <ButtonModuleProperties moduleKey={moduleKey} visible={visible}/>}
    {
      (type == ModuleType.Cams) && <CamsModuleProperties moduleKey={moduleKey} visible={visible}/>}
    {
      (type == ModuleType.HeaderBanner) &&
      <HeaderBannerModuleProperties moduleKey={moduleKey} visible={visible}/>}
    {
      (type == ModuleType.SingleImageAndText) &&
      <SingleImageAndTextModuleProperties moduleKey={moduleKey} visible={visible}/>}
    {
      (type == ModuleType.StarShows) &&
      <StarShowsModuleProperties moduleKey={moduleKey} visible={visible}/>}
    {
      (type == ModuleType.TwoImagesAndTexts) &&
      <TwoImagesAndTextsModuleProperties moduleKey={moduleKey} visible={visible}/>}
    {
      (type == ModuleType.UserRegistration) &&
      <UserRegistrationModuleProperties moduleKey={moduleKey} visible={visible}/>}
    {
      (type == ModuleType.Videos) &&
      <VideosModuleProperties moduleKey={moduleKey} visible={visible}/>}
    {
      (type == ModuleType.Voucher) &&
      <VoucherModuleProperties moduleKey={moduleKey} visible={visible}/>
    }
  </React.Fragment>
);

// endregion