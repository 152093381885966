// region imports

import * as React from 'react';
import {UFTT, UFTTProps} from "../UF-react/components/translation/UFTT";

// endregion

// region

/**
 * {@link TT} maps to {@link UFTT}.
 */
export const TT: React.FC<UFTTProps> = (props) => <UFTT {...props} />;

// endregion