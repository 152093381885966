// region imports

import {AlertState} from "./AlertState";
import {ReducerActionType} from "../../ReducerActionType";
import {TT} from "../../../components/TT";
import { AlertStoreActions } from "./AlertStoreActions";

// endregion

// region local variables

/**
 * Initial system store state
 */
const initialState: AlertState = {
  show: false,
  title: '',
  content: '',
  close: <TT>Close</TT>,
  callback: () => {
  }
};

// endregion

// region local types

/**
 * All confirmation action models.
 */
type AlertAction = ReturnType<typeof AlertStoreActions.show> | ReturnType<typeof AlertStoreActions.hide>;

// endregion

// region Exports

/**
 * Reduces the alert store to a new state.
 *
 * @param aState
 *   Current state
 * @param anAction
 *   Action to apply to the store
 *
 * @return new state
 */
export function alertReducer(aState: AlertState = initialState, anAction: AlertAction): AlertState {
  switch (anAction.type) {
    case ReducerActionType.ShowAlert:
      return {
        show: true,
        title: anAction.title,
        content: anAction.content,
        close: anAction.close,
        callback: anAction.callback
      };
    case ReducerActionType.HideAlert:
      return {
        ...aState,
        show: false
      };
    default:
      return aState;
  }
}

// endregion
