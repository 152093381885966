// region imports

import * as React from 'react';
import {LandingPageColors} from "../../../types/LandingPageColors";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {Box, Stack} from "@mui/material";
import {ModuleBaseProps} from "../../../types/ModuleBaseProps";
import {ViewMode} from "../../../constants/ViewMode";
import {LandingPageImage} from "../parts/LandingPageImage";
import {PlainText} from "../parts/PlainText";
import {Config} from "../../../constants/Config";
import {StylingTools} from "../../../tools/StylingTools";
import {LandingPageDropShadow} from "../../../types/LandingPageDropShadow";
import {LandingPageBorder} from "../../../types/LandingPageBorder";

// endregion

// region local types and functions

/**
 * Props for the component
 */
interface BannerModuleProps extends ModuleBaseProps {
  colors: LandingPageColors;
  viewMode: ViewMode;
  border: LandingPageBorder;
  dropShadow: LandingPageDropShadow;
}

/**
 * Maps store state to component properties.
 */
function mapStatsToProps(anApplicationState: ApplicationState) {
  return {
    colors: anApplicationState.landingPage.colors,
    border: anApplicationState.landingPage.border,
    dropShadow: anApplicationState.landingPage.dropShadow,
    viewMode: anApplicationState.editor.viewMode
  }
}

// endregion

// region component

/**
 * The top bar in the landing page.
 */
export const BannerModule = connect(mapStatsToProps)(
  ({viewMode, module, border, dropShadow}: BannerModuleProps) => {
    const atDesktop = viewMode == ViewMode.Desktop;
    return (
      <Stack
        direction="column"
        spacing={0}
        sx={{
          '&:hover': {
            opacity: 0.8,
            '& .banner-text': {
              textDecoration: 'underline'
            }
          }
        }}
      >
        {
          module.title &&
          <PlainText
            className="banner-text"
            fontSize={Config.titleSize}
            fontWeight={Config.titleWeight}
            textAlign="left"
          >
            {module.title}
          </PlainText>
        }
        <Box
          sx={{
            height: atDesktop ? '258px' : '87px',
            borderRadius: border.radius + 'px',
            boxShadow: StylingTools.getBoxShadow(dropShadow),
            overflow: 'hidden',
            '&:hover': {
              opacity: 0.8
            }
          }}
        >
          <LandingPageImage />
        </Box>
      </Stack>
    )
  }
);

// endregion

