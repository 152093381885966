// region imports

import * as React from 'react';
import {EditorStoreActions} from "../../store/editor/EditorStoreActions";
import {connect} from "react-redux";
import {TT} from "../TT";
import {Button} from "@mui/material";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import {Config} from "../../constants/Config";
import {UFMath} from "../../UF/tools/UFMath";
import { StylingTools } from '../../tools/StylingTools';

// endregion

// region local types and functions

interface AutoScaleButtonProps {
  setViewScale: typeof EditorStoreActions.setViewScale
}

// endregion

// region component

export const AutoScaleButton = connect(
  null,
  {
    setViewScale: EditorStoreActions.setViewScale
  }
)(
  ({setViewScale}: AutoScaleButtonProps) => {
    const updateViewScale = () => setViewScale(
      UFMath.minmax(
        Config.minScale, Config.maxScale, 100 * (StylingTools.getLandingPageViewWidth()) / Config.desktopWidth
      )
    );
    return (
      <Button
        startIcon={<AspectRatioIcon/>}
        variant="outlined"
        color="inherit"
        onClick={() => {
          updateViewScale();
          // repeat in case of a horizontal scrollbar (which can appear when scaling from no scrollbars to a vertical
          // scrollbar)
          setTimeout(updateViewScale, 10);
        }}
        size="small"
      >
        <TT ttid="autoscale-button.caption">Auto scale</TT>
      </Button>
    )
  }
);

// endregion