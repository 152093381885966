// region imports

import * as React from 'react';
import {UFFormTextField} from "../../UF-mui/components/form/UFFormTextField";
import {connect} from "react-redux";
import {UFThunkPropsType} from "../../UF-react/tools/UFRedux";
import {SelectImageStoreActions} from "../../store/dialogs/selectImage/SelectImageStoreActions";
import {TT} from "../TT";

// endregion

// region local types and functions

/**
 * Properties for the component
 */
interface ImageFieldProps {
  /**
   * Label to show above input field
   */
  readonly label?: React.ReactNode,

  /**
   * Value to 'edit' (full image url, but only filename will be shown)
   */
  readonly value: string;

  /**
   * Callback that is called whenever the value changes.
   */
  readonly onChange: (value: string) => void;

  /**
   * Store callback
   */
  readonly showPopup: UFThunkPropsType<typeof SelectImageStoreActions.popup>;
}

/**
 * Gets the filename part of an url.
 *
 * @param {string} anUrl
 *
 * @returns {string}
 */
function getFileName(anUrl?: string): string {
  if (anUrl == null) {
    return '';
  }
  const index = anUrl.lastIndexOf('/');
  return anUrl.substring(index + 1);
}

// endregion

// region component

/**
 * {@link ImageField} shows an input field to edit a html formatted text. The input field will show a plain version
 * of the text. Clicking it will show a dialog with rich text editor to change the html formatted text.
 */
export const ImageField = connect(null, {showPopup: SelectImageStoreActions.popup})(
  ({
     label = <TT>Image</TT>,
     value,
     onChange,
     showPopup
   }: ImageFieldProps
  ) => (
    <UFFormTextField
      label={label}
      value={getFileName(value)}
      readOnly={true}
      ellipsis={true}
      onClick={async () => {
        const result = await showPopup();
        if (result !== false) {
          onChange(result);
        }
      }}
    />
  )
);

// endregion