// region imports

import * as React from 'react';
import {LandingPageColors} from "../../../types/LandingPageColors";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {Box, Stack} from "@mui/material";
import {ModuleBaseProps} from "../../../types/ModuleBaseProps";
import {ReactComponent as ThreeStars} from '../../images/three-stars.svg';
import {LandingPageBorder} from "../../../types/LandingPageBorder";
import {PlainText} from "../parts/PlainText";
import {LandingPageButton} from "../parts/LandingPageButton";
import {ViewMode} from "../../../constants/ViewMode";
import {LandingPageDropShadow} from "../../../types/LandingPageDropShadow";
import {StylingTools} from "../../../tools/StylingTools";
import {VoucherCode} from "../parts/VoucherCode";

// endregion

// region local types and functions

/**
 * Props for the component
 */
interface VoucherModuleProps extends ModuleBaseProps {
  colors: LandingPageColors;
  border: LandingPageBorder;
  viewMode: ViewMode;
  dropShadow: LandingPageDropShadow;
}

/**
 * Maps store state to component properties.
 */
function mapStatsToProps(anApplicationState: ApplicationState) {
  return {
    colors: anApplicationState.landingPage.colors,
    border: anApplicationState.landingPage.border,
    dropShadow: anApplicationState.landingPage.dropShadow,
    viewMode: anApplicationState.editor.viewMode
  }
}

// endregion

// region component

/**
 * The voucher module.
 */
export const VoucherModule = connect(mapStatsToProps)(
  ({colors, border, viewMode, dropShadow, moduleBackground}: VoucherModuleProps) => {
    const atDesktop = viewMode == ViewMode.Desktop;
    return (
      <Stack
        direction="column"
        spacing={0}
        alignItems="center"
        sx={{
          boxShadow: StylingTools.getBoxShadow(dropShadow),
          border: '1px solid ' + colors.background,
          borderRadius: border.radius + 'px',
          padding: atDesktop ? '40px' : '10px',
          background: StylingTools.getReverseModuleBackground(moduleBackground, colors)
        }}
      >
        <Box marginBottom="-20px">
          <ThreeStars/>
        </Box>
        <PlainText fontSize={atDesktop ? '70px' : '30px'} fontWeight={900} textTransform="uppercase">Gutschein</PlainText>
        <PlainText fontSize={atDesktop ? '70px' : '20px'}>10 TAGE FunDorado GRATIS</PlainText>
        <VoucherCode
          viewMode={viewMode}
          digitBackground={StylingTools.getModuleBackground(moduleBackground, colors)}
        />
        <LandingPageButton
          margin={atDesktop ? '40px 0 0' : '20px 0 0'}
          caption="Hier mit Gutschein anmelden"
          border={border}
          colors={colors}
          viewMode={viewMode}
          width={atDesktop ? undefined : '100%'}
        />
      </Stack>
    )
  }
);

// endregion

