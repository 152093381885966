// region imports

import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {ListItemIcon, ListItemText} from "@mui/material";
import {EnglishIcon} from "../flags/EnglishIcon";
import {GermanIcon} from "../flags/GermanIcon";
import {LanguageType} from "../../constants/LanguageType";
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {SettingsStoreActions} from "../../store/settings/SettingsStoreActions";

// endregion

// region local types and functions

/**
 * Properties for the component.
 */
interface LanguageMenuProps {
  language: LanguageType;
  setLanguage: typeof SettingsStoreActions.setLanguage;
}

/**
 * Helper function to map application state to component properties.
 *
 * @param {ApplicationState} state
 * @returns {{language: LanguageType}}
 */
const mapStateToProps = (state: ApplicationState) => {
  return {
    language: state.settings.language
  };
};

// endregion

// region component

/**
 * {@link LanguageMenu} handles the selection of the UI language.
 */
export const LanguageMenu = connect(
  mapStateToProps,
  {
    setLanguage: SettingsStoreActions.setLanguage
  }
)(
  ({language, setLanguage}: LanguageMenuProps) => {
    // used to show and hide the popup menu
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    // shows the menu
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    // sets the language and closes the menu
    const handleClose = (aLanguage: LanguageType | null = null) => {
      if (aLanguage) {
        setLanguage(aLanguage);
      }
      setAnchorEl(null);
    };
    // determine values to show
    let currentLanguage;
    let currentIcon;
    switch (language) {
      case LanguageType.German:
        currentLanguage = 'Deutsch';
        currentIcon = <GermanIcon/>;
        break;
      default:
        currentLanguage = 'English';
        currentIcon = <EnglishIcon/>;
        break;
    }
    return (
      <div>
        <Button
          id="basic-button"
          onClick={handleClick}
          color="inherit"
          startIcon={currentIcon}
          sx={{width: '100px'}}
          endIcon={<ArrowDropDownIcon/>}
        >
          {currentLanguage}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose()}
        >
          <MenuItem
            onClick={() => handleClose(LanguageType.English)}
            disabled={language == LanguageType.English}
          >
            <ListItemIcon>
              <EnglishIcon/>
            </ListItemIcon>
            <ListItemText>
              English
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => handleClose(LanguageType.German)}
            disabled={language == LanguageType.German}
          >
            <ListItemIcon>
              <GermanIcon/>
            </ListItemIcon>
            <ListItemText>
              Deutsch
            </ListItemText>
          </MenuItem>
        </Menu>
      </div>
    );
  }
);