// region imports

import * as React from 'react';
import {LandingPageTemplate} from "../../../types/LandingPageTemplate";
import {Box, Stack, Typography} from "@mui/material";
import {LandingPageImage} from "../../landingPage/parts/LandingPageImage";

// endregion

// region local types

interface TemplateItemProps {
  template: LandingPageTemplate;
}

export const TemplateItem: React.FC<TemplateItemProps> = ({template}) => (
  <Stack direction="column">
    <Box
      sx={{
        width: '100%',
        height: '300px'
      }}
    >
      <LandingPageImage/>
    </Box>
    <Typography
      variant="h6"
      sx={{
        marginTop: '0.5rem'
      }}
    >
      {template.title}
    </Typography>
    <Typography>
      {template.description}
    </Typography>
  </Stack>
);

// endregion