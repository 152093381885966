// region imports

import * as React from 'react';
import {LandingPageColors} from "../../../types/LandingPageColors";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {Box, Stack} from "@mui/material";
import {ModuleBaseProps} from "../../../types/ModuleBaseProps";
import {StylingTools} from "../../../tools/StylingTools";
import {LandingPageBorder} from "../../../types/LandingPageBorder";
import {LandingPageDropShadow} from "../../../types/LandingPageDropShadow";
import {ViewMode} from "../../../constants/ViewMode";
import {TextImagePosition} from "../../../constants/TextImagePosition";
import {LandingPageImage} from "../parts/LandingPageImage";
import {Config} from "../../../constants/Config";
import {PlainText} from "../parts/PlainText";
import {TextBlock} from "../parts/TextBlock";

// endregion

// region local types and functions

/**
 * Props for the component
 */
interface SingleImageAndTextModuleProps extends ModuleBaseProps {
  colors: LandingPageColors;
  border: LandingPageBorder;
  dropShadow: LandingPageDropShadow;
  viewMode: ViewMode;
}

/**
 * Maps store state to component properties.
 */
function mapStatsToProps(anApplicationState: ApplicationState) {
  return {
    colors: anApplicationState.landingPage.colors,
    border: anApplicationState.landingPage.border,
    dropShadow: anApplicationState.landingPage.dropShadow,
    viewMode: anApplicationState.editor.viewMode
  }
}

// endregion

// region component

/**
 * The top bar in the landing page.
 */
export const SingleImageAndTextModule = connect(mapStatsToProps)(
  ({module, viewMode, moduleBackground, colors, border, dropShadow}: SingleImageAndTextModuleProps) => {
    const atLeft = module.textImagePosition == TextImagePosition.TextLeftImageRight;
    const atDesktop = viewMode == ViewMode.Desktop;
    return (
      <Stack
        alignItems="stretch"
        gap={Config.contentSpacing + "px"}
        spacing={0}
        sx={{
          '&:hover': {
            opacity: 0.8
          }
        }}
      >
        {
          module.title2 &&
          <PlainText
            textTransform="uppercase"
            fontWeight={Config.bigTitleWeight}
            fontSize={Config.bigTitleSize}
          >
            {module.title2}
          </PlainText>
        }
        <Stack
          direction={viewMode == ViewMode.Desktop ? "row" : "column"}
          gap={Config.contentSpacing + "px"}
          spacing={0}
        >
          <Box
            sx={{
              flex: '1 1',
              order: atLeft ? 0 : 1,
              flexBasis: atDesktop ? ((atLeft ? module.leftPercentage : 100 - module.leftPercentage) + '%') : 'auto'
            }}
          >
            <TextBlock
              title={module.title}
              text={module.text}
              colors={colors}
              border={border}
              dropShadow={dropShadow}
              moduleBackground={moduleBackground}
              />
          </Box>
          <Box
            sx={{
              flex: '1 1',
              height: atDesktop ? '428px' : '248px',
              borderRadius: border.radius + 'px',
              overflow: 'hidden',
              boxShadow: StylingTools.getBoxShadow(dropShadow),
              order: atLeft ? 1 : 0,
              flexBasis: atDesktop ? ((atLeft ? 100 - module.leftPercentage : module.leftPercentage) + '%') : 'auto'
            }}
          >
            <LandingPageImage />
          </Box>
        </Stack>
      </Stack>
    )
  }
);

// endregion

