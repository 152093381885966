// region imports

import * as React from 'react';
import {PropertyGroupsContainer} from "../PropertyGroupsContainer";
import {ModuleActions} from "../ModuleActions";
import {PropertyGroup} from "../PropertyGroup";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {LandingPageModule} from "../../../types/LandingPageModule";
import {LandingPageModuleTools} from "../../../tools/LandingPageModuleTools";
import {Button, MenuItem} from "@mui/material";
import {CategorySelect} from "../../form/CategorySelect";
import {UFSorted} from "../../../UF/tools/UFSorted";
import {Config} from "../../../constants/Config";
import {HeaderBannerItemProperties} from "./HeaderBannerItemProperties";
import AddIcon from '@mui/icons-material/Add';
import {UFFormSelect} from "../../../UF-mui/components/form/UFFormSelect";
import {TT} from "../../TT";
import {TextAlignType} from "../../../constants/TextAlignType";
import {LandingPageStoreActions} from "../../../store/landingPage/LandingPageStoreActions";
import {ApplicationButton} from "../../controls/ApplicationButton";
import {ClassificationSelect} from "../../form/ClassificationSelect";

// endregion

// region local types

/**
 * Properties for component.
 */
interface HeaderBannerModulePropertiesProps {
  readonly visible: boolean;
  readonly moduleKey: string;
  readonly module: LandingPageModule;
  readonly updateModule: typeof LandingPageStoreActions.updateModule;
  readonly addHeaderBanner: typeof LandingPageStoreActions.addHeaderBanner;
}

/**
 * Maps state to local properties.
 */
function mapStateToProps(anApplicationState: ApplicationState, aProps: Partial<HeaderBannerModulePropertiesProps>) {
  return {
    module: anApplicationState.landingPage.modules[aProps.moduleKey!],
  }
}

// region

/**
 * All properties for the module.
 */
export const HeaderBannerModuleProperties = connect(
  mapStateToProps,
  {
    updateModule: LandingPageStoreActions.updateModule,
    addHeaderBanner: LandingPageStoreActions.addHeaderBanner
  })(
  ({visible, module, updateModule, addHeaderBanner}: HeaderBannerModulePropertiesProps) => {
    const sortedHeaderBanners = UFSorted.sort(module.headerBannerItems);
    return (
      <PropertyGroupsContainer
        title={<TT ttid="header-banner-properties.title">Header banner module settings</TT>}
        visible={visible}
      >
        <ModuleActions module={module}>
          <ApplicationButton
            onClick={() => addHeaderBanner(LandingPageModuleTools.createHeaderBannerItem())}
            disabled={sortedHeaderBanners.length >= Config.maxHeaderBanners}
            startIcon={<AddIcon />}
          >
            <TT ttid="header-banner-properties.add-banner">Add header banner</TT>
          </ApplicationButton>
        </ModuleActions>
        <PropertyGroup title={<TT>Content</TT>}>
          <CategorySelect
            value={module.category}
            onChange={(value) => updateModule({...module, category: value})}
          />
          <ClassificationSelect
            value={module.classification}
            onChange={(value) => updateModule({...module, classification: value})}
          />
          <UFFormSelect
            label={<TT ttid="header-banner-properties.text-alignment">Text alignment</TT>}
            value={module.textAlign}
            onFieldChange={({value}) => updateModule({...module, textAlign: value})}
          >
            <MenuItem value={TextAlignType.Left}><TT>Left</TT></MenuItem>
            <MenuItem value={TextAlignType.Center}><TT>Center</TT></MenuItem>
            <MenuItem value={TextAlignType.Right}><TT>Right</TT></MenuItem>
          </UFFormSelect>
        </PropertyGroup>
        {
          sortedHeaderBanners.map(
            headerBanner => <HeaderBannerItemProperties key={headerBanner.key} headerBannerKey={headerBanner.key} module={module}/>
          )
        }
      </PropertyGroupsContainer>
    );
  }
);

// endregion