// region imports

import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {ListItemIcon, ListItemText} from "@mui/material";
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";
import LogoutIcon from '@mui/icons-material/Logout';
import {TT} from "../TT";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {UserStoreActions} from "../../store/user/UserStoreActions";
import {UFThunkPropsType} from "../../UF-react/tools/UFRedux";
import {ConfirmStoreActions} from "../../store/dialogs/confirm/ConfirmStoreActions";
import {MainController} from "../../controllers/MainController";

// endregion

// region local types and functions

/**
 * Properties for the component.
 */
interface UserMenuProps {
  fullName: string;
  dirty: boolean;
  confirm: UFThunkPropsType<typeof ConfirmStoreActions.popup>;
}

/**
 * Maps the application state to the component properties.
 */
function mapStateToProps(anApplicationState: ApplicationState) {
  return {
    fullName: anApplicationState.user.fullName,
    dirty: anApplicationState.landingPage.dirty
  };
}

// endregion

// region component

/**
 * {@link UserMenu} shows the users name and a menu with related user actions.
 */
export const UserMenu = connect(
  mapStateToProps,
  {
    confirm: ConfirmStoreActions.popup
  }
)(
  ({fullName, dirty, confirm}: UserMenuProps) => {
    const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorElement);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElement(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorElement(null);
    };
    const canLogout = async () =>
      !dirty ||
      await confirm(
        <TT ttid="logout.title">Confirm logout</TT>,
        <TT ttid="logout.text">There are unsaved changed, do you still want to logout?</TT>,
        <TT>Yes</TT>,
        <TT>No</TT>
      );
    return (
      <div>
        <Button
          id="basic-button"
          onClick={handleClick}
          color="inherit"
          endIcon={<ArrowDropDownIcon/>}
        >
          Hi, {fullName}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorElement}
          open={open}
          onClose={() => handleClose()}
        >
          <MenuItem
            onClick={async () => {
              handleClose();
              if (await canLogout()) {
                MainController.instance.logout();
              }
            }}
          >
            <ListItemIcon>
              <LogoutIcon/>
            </ListItemIcon>
            <ListItemText>
              <TT>Logout</TT>
            </ListItemText>
          </MenuItem>
        </Menu>
      </div>
    );
  }
);

// endregion