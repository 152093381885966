// region imports

import * as React from 'react';
import {TestStoreActions} from "../../store/dialogs/test/TestStoreActions";
import {connect} from "react-redux";
import {Dialog, DialogContent} from "@mui/material";

// endregion

// region local types

interface TestDialogProps {
  hide: typeof TestStoreActions.hide;
}

function render_html_to_canvas(
  html: string, context: CanvasRenderingContext2D, x: number, y: number, width: number, height: number
): HTMLImageElement {
  let xml = html_to_xml(html);
  xml = xml.replace(/\#/g, '%23');
  const styles = Array.from(document.getElementsByTagName('STYLE'));
  let styleText = '';
  for(const style of styles) {
    styleText += style.innerHTML.replace(/\#/g, '%23') + '\n\n';
  }
  const data = "data:image/svg+xml;charset=utf-8," + '<svg xmlns="http://www.w3.org/2000/svg" width="' + width + '" height="' + height + '">' +
    '<style>' +
    styleText +
      '</style>' +
    '<foreignObject width="100%" height="100%">' +
    xml +
    '</foreignObject>' +
    '</svg>';

  const img = new Image();
  img.onload = function () {
    context.drawImage(img, x, y);
  }
  img.src = data;
  return img;
}


function html_to_xml(html: string): string {
  const doc = document.implementation.createHTMLDocument('');
  doc.write(html);

  // You must manually set the xmlns if you intend to immediately serialize
  // the HTML document to a string as opposed to appending it to a
  // <foreignObject> in the DOM
  doc.documentElement.setAttribute('xmlns', doc.documentElement.namespaceURI || '');

  // Get well-formed markup
  html = (new XMLSerializer).serializeToString(doc.body);
  return html;
}

function test(): HTMLImageElement | null {
  const landingPage = document.getElementById('landing-page');
  if (landingPage) {
    const html = landingPage.innerHTML;
    const canvas = document.createElement('canvas');
    canvas.width = 1920;
    canvas.height = 1080;
    const img = render_html_to_canvas(html, canvas.getContext('2d')!, 0, 0, 1920, 1080);
    return img;
  }
  return null;
}

// endregion

// region component

export const TestDialog = connect(null, {hide: TestStoreActions.hide})(({hide}: TestDialogProps) => {
  const element = test();
  const myImage = element ? React.createElement("img", {src: element.src,}, null) : null;
  return (
    <Dialog
      open={true}
      onClose={hide}
    >
      <DialogContent
        sx={{
          width: '600px',
          height: '600px'
        }}
      >
        {myImage}
      </DialogContent>
    </Dialog>
  );
});

// endregion