// region imports

import {LanguageType} from "../../constants/LanguageType";
import {ReducerActionType} from "../ReducerActionType";
import {UFRedux} from "../../UF-react/tools/UFRedux";

// endregion

// region exports

export class SettingsStoreActions {

  /**
   * Sets the language.
   *
   * @param aLanguage
   *    New language to use
   */
  static setLanguage(aLanguage: LanguageType) {
    return UFRedux.reducerAction<ReducerActionType.SetLanguage>(ReducerActionType.SetLanguage)({
      language: aLanguage
    });
  }
}

// endregion
