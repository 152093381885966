// region imports

import * as React from 'react';
import {useState} from 'react';
import {Dialog, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import {TT} from "../TT";
import {UFStyledDialogActions} from "../../UF-mui/components/dialogs/UFStyledDialogActions";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import {DialogButton} from "../controls/DialogButton";
import {CampaignSelect} from "../form/CampaignSelect";
import {MainController} from "../../controllers/MainController";
import {Config} from "../../constants/Config";

// endregion

// region local types and functions

/**
 * Properties for component
 */
interface SelectTargetCampaignDialogProps {
  readonly onClose: () => any;
}

// endregion

// region component

/**
 * {@link SelectTargetCampaignDialog} shows the module types for the user to select one.
 */
export const SelectTargetCampaignDialog: React.FC<SelectTargetCampaignDialogProps> = ({onClose}) => {
  const [targetCampaign, setTargetCampaign] = useState('');
  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <TT ttid="select-target-campaign.title">Select target campaign</TT>
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" gap={Config.propertiesGapSize}>
          <Typography>
            <TT ttid="select-target-campaign.intro">
              Select a campaign to copy the landing page to and start editing it for that campaign.
            </TT>
          </Typography>
          <CampaignSelect value={targetCampaign} onChange={setTargetCampaign} withLandingPage={false} />
        </Stack>
      </DialogContent>
      <UFStyledDialogActions>
        <DialogButton
          onClick={() => {
            // noinspection JSIgnoredPromiseFromCall
            MainController.instance.copyToCampaign(targetCampaign);
            onClose();
          }}
          startIcon={<CheckIcon/>}
          disabled={targetCampaign.length <= 0}
        >
          <TT>Select</TT>
        </DialogButton>
        <DialogButton
          color="secondary"
          onClick={onClose}
          startIcon={<CloseIcon/>}
        >
          <TT>Cancel</TT>
        </DialogButton>
      </UFStyledDialogActions>
    </Dialog>
  );
};

// endregion
