// region imports

import {ModuleType} from "../constants/ModuleType";
import {CategoryType} from "../constants/CategoryType";
import {UFSystem} from "../UF/tools/UFSystem";
import {TextImagePosition} from "../constants/TextImagePosition";
import {LandingPageModule} from "../types/LandingPageModule";
import {LandingPageModuleDictionary} from "../types/LandingPageModuleDictionary";
import {HeaderBannerItem} from "../types/HeaderBannerItem";
import {TextAlignType} from "../constants/TextAlignType";
import {ClassificationType} from "../constants/ClassificationType";
import {ImageFitType} from "../constants/ImageFitType";

// endregion

// region local variables

/**
 * The dummy module, used by property editors when they are not visible
 *
 * @type {LandingPageModule}
 */
let s_dummyModule: LandingPageModule

// endregion

// region types

/**
 * Support methods for {@link LandingPageModule}
 */
export class LandingPageModuleTools {
  /**
   * Creates a landing page module.
   *
   * @param {ModuleType} aType
   * @param {CategoryType} aCategory
   * @param aClassification
   *
   * @returns {LandingPageModule}
   */
  static create(aType: ModuleType, aCategory: CategoryType, aClassification: ClassificationType): LandingPageModule {
    const result: LandingPageModule = {
      type: aType,
      category: aCategory,
      classification: aClassification,
      key: UFSystem.time().toString() + Math.random().toString(),
      sortOrder: -1,
      text: '',
      title: '',
      image: '',
      imageFit: ImageFitType.Contain,
      text2: '',
      title2: '',
      image2: '',
      imageFit2: ImageFitType.Contain,
      textImagePosition: TextImagePosition.TextLeftImageRight,
      leftPercentage: 50,
      rowCount: 2,
      headerBannerItems: {},
      selectedHeaderBanner: '',
      textAlign: TextAlignType.Left,
    };
    if (aType == ModuleType.HeaderBanner) {
      const banner = this.createHeaderBannerItem();
      result.headerBannerItems[banner.key] = banner;
      result.selectedHeaderBanner = banner.key;
    }
    return result;
  }

  /**
   * Creates a copy of a landing page module.
   *
   * @param {LandingPageModule} aModule
   *
   * @returns {LandingPageModule}
   */
  static copy(aModule: LandingPageModule): LandingPageModule {
    const copy = LandingPageModuleTools.create(aModule.type, aModule.category, aModule.classification);
    copy.text = aModule.text;
    copy.title = aModule.title;
    copy.image = aModule.image;
    copy.imageFit = aModule.imageFit;
    copy.text2 = aModule.text2;
    copy.title2 = aModule.title2;
    copy.image2 = aModule.image2;
    copy.imageFit2 = aModule.imageFit2;
    copy.textImagePosition = aModule.textImagePosition;
    copy.leftPercentage = aModule.leftPercentage;
    copy.rowCount = aModule.rowCount;
    copy.headerBannerItems = Object.assign({}, aModule.headerBannerItems);
    copy.selectedHeaderBanner = aModule.selectedHeaderBanner;
    copy.textAlign = aModule.textAlign;
    return copy;
  }

  /**
   * Gets the module dictionary as an array sorting the modules on their sortOrder.
   *
   * @param {LandingPageModuleDictionary} aModules
   *
   * @returns {LandingPageModule[]}
   */
  static getSortedList(aModules: LandingPageModuleDictionary): LandingPageModule[]
  {
    return Object.values(aModules).sort((first, second) => first.sortOrder - second.sortOrder);
  }

  /**
   * Creates a new header banner item.
   */
  static createHeaderBannerItem(): HeaderBannerItem {
    return {
      key: UFSystem.time().toString() + Math.random().toString(),
      text: '',
      sortOrder: 0,
      image: '',
      imageFit: ImageFitType.Contain
    }
  }

  /**
   * Makes a copy of a header banner.
   */
  static copyHeaderBanner(anHeaderBanner: HeaderBannerItem): HeaderBannerItem {
    const result = LandingPageModuleTools.createHeaderBannerItem();
    result.text = anHeaderBanner.text;
    return result;
  }
}