// region imports

import * as React from 'react';
import {Dialog, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import {TT} from "../TT";
import CloseIcon from '@mui/icons-material/Close';
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";
import {DialogButton} from "../controls/DialogButton";
import {UFStyledDialogActions} from '../../UF-mui/components/dialogs/UFStyledDialogActions';
import CheckIcon from "@mui/icons-material/Check";
import {UFBusy} from "../../UF-mui/components/wrappers/UFBusy";
import {MainController} from "../../controllers/MainController";

// endregion

// region local types and functions

interface DeleteTemplateDialogProps {
  readonly onClose: () => any;
  readonly networkBusy: boolean;
}

function mapStateToProps(anApplicationState: ApplicationState) {
  return {
    networkBusy: anApplicationState.network.networkBusy
  }
}

// endregion

// region component

/**
 * {@link ChangeCodeDialog} shows the module types for the user to select one.
 */
export const DeleteTemplateDialog = connect(mapStateToProps)(
  ({onClose, networkBusy}: DeleteTemplateDialogProps) => {
    return (
      <Dialog
        open={true}
        onClose={
          () => {
            if (!networkBusy) {
              onClose();
            }
          }
        }
      >
        <DialogTitle>
          <TT ttid="delete-template.title">Confirm deletion</TT>
        </DialogTitle>
        <DialogContent>
          <Typography>
            <TT ttid="delete-template.text">Continue with deleting this template?</TT>,
          </Typography>
        </DialogContent>
        <UFStyledDialogActions>
          <UFBusy busy={networkBusy}>
            <DialogButton
              color="error"
              onClick={async () => {
                await MainController.instance.deleteSelectedTemplate();
                onClose();
              }}
              startIcon={<CheckIcon/>}
              disabled={networkBusy}
            >
              <TT>Delete</TT>
            </DialogButton>
          </UFBusy>
          <DialogButton
            color="secondary"
            onClick={onClose}
            startIcon={<CloseIcon/>}
            disabled={networkBusy}
          >
            <TT>No</TT>
          </DialogButton>
        </UFStyledDialogActions>
      </Dialog>
    );
  }
);

// endregion
