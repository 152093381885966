// region imports

import * as React from 'react';
import {Box, Stack} from "@mui/material";
import {LandingPageColors} from "../../types/LandingPageColors";
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";
import {ModuleContent} from "./ModuleContent";
import {StylingTools} from "../../tools/StylingTools";
import {ViewMode} from "../../constants/ViewMode";
import {PlainText} from "./parts/PlainText";

// endregion

// region local types and functions

/**
 * Properties for top bar
 */
interface BottomBarProps {
  contentWidth: number;
  colors: LandingPageColors;
  selectedModule: string;
  viewMode: ViewMode;
}

/**
 * Maps store state to component properties.
 */
function mapStatsToProps(anApplicationState: ApplicationState) {
  return {
    colors: anApplicationState.landingPage.colors,
    selectedModule: anApplicationState.landingPage.selectedModule,
    viewMode: anApplicationState.editor.viewMode
  }
}

// endregion

// region local component

interface BottomLinkProps {
  colors: LandingPageColors;
}

const BottomLink: React.FC<BottomLinkProps> = ({colors, ...others}) => (
  <PlainText
    {...others}
    sx={{
      color: StylingTools.getTextColor(colors.background),
      '&:hover': {
        color: StylingTools.getHoverColor(StylingTools.getTextColor(colors.background)),
        textDecoration: 'underline'
      }
    }}
  />
)

// endregion

// region component

/**
 * The bottom bar in the landing page.
 */
export const BottomBar = connect(mapStatsToProps)(
  ({contentWidth, colors, viewMode, selectedModule}: BottomBarProps) => (
    <ModuleContent
      contentWidth={contentWidth}
      selected={!selectedModule.length}
      backgroundColor={StylingTools.getBottomBackgroundColor(colors)}
      moduleKey=""
    >
      <Box
        fontSize={viewMode == ViewMode.Desktop ? '14px' : '11px'}
        padding={0}
      >
        <Stack
          direction="row"
          justifyContent="center"
          spacing={0}
          flexWrap="wrap"
          columnGap="1em"
          marginBottom="0.25em"
        >
          <BottomLink colors={colors}>Impressum</BottomLink>
          <BottomLink colors={colors}>AGB</BottomLink>
          <BottomLink colors={colors}>Widerrufsrecht</BottomLink>
          <BottomLink colors={colors}>Service/Hilfe</BottomLink>
          <BottomLink colors={colors}>Datenschutz</BottomLink>
          <BottomLink colors={colors}>Webmaster</BottomLink>
        </Stack>
        <PlainText color={StylingTools.getTextColor(colors.background)}>
          &copy; Jestoro GmbH. All rights reserved.
        </PlainText>
      </Box>
    </ModuleContent>
  )
);

// endregion
