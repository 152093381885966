// region imports

import * as React from 'react';
import {FormControl, InputLabel, Typography} from "@mui/material";
import {TT} from "../TT";
import {Config} from "../../constants/Config";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

// endregion

// region local types and functions

interface UrlPreviewProps {
  readonly landingPageCode: string;
  readonly webmasterCode: string;
  readonly sx?: SxProps<Theme>;
}

// endregion

// region component

/**
 * Shows a preview of the landing page url.
 */
export const UrlPreview: React.FC<UrlPreviewProps> = (
  {
    landingPageCode,
    sx = {},
    webmasterCode
  }
) => (
  <FormControl variant="standard" margin="none" sx={sx}>
    <InputLabel shrink={true}>
      <TT ttid="url-preview.label">Landing page url preview</TT>
    </InputLabel>
    <Typography sx={{marginTop: '20px'}}>
      {Config.urlDomain}{webmasterCode}/<strong>{landingPageCode}</strong>{landingPageCode.length ? '/' : ''}
    </Typography>
  </FormControl>
);

// endregion