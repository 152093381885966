// region imports

import * as React from 'react';
import {LandingPageColors} from "../../../types/LandingPageColors";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {Box, Stack} from "@mui/material";
import {ModuleBaseProps} from "../../../types/ModuleBaseProps";
import {PlainText} from "../parts/PlainText";
import {LandingPageInput} from "../parts/LandingPageInput";
import {LandingPageBorder} from "../../../types/LandingPageBorder";
import {LandingPageDropShadow} from "../../../types/LandingPageDropShadow";
import {ReactComponent as ThreeStars} from '../../images/three-stars.svg';
import {VoucherCode} from "../parts/VoucherCode";
import {LandingPageButton} from "../parts/LandingPageButton";
import {ViewMode} from "../../../constants/ViewMode";
import {StylingTools} from "../../../tools/StylingTools";

// endregion

// region local types and functions

/**
 * Props for the component
 */
interface UserRegistrationModuleProps extends ModuleBaseProps {
  colors: LandingPageColors;
  border: LandingPageBorder;
  dropShadow: LandingPageDropShadow;
  viewMode: ViewMode;
}

/**
 * Maps store state to component properties.
 */
function mapStatsToProps(anApplicationState: ApplicationState) {
  return {
    colors: anApplicationState.landingPage.colors,
    border: anApplicationState.landingPage.border,
    dropShadow: anApplicationState.landingPage.dropShadow,
    viewMode: anApplicationState.editor.viewMode
  }
}

// endregion

// region component

/**
 * The top bar in the landing page.
 */
export const UserRegistrationModule = connect(mapStatsToProps)(
  ({colors, border, dropShadow, viewMode, moduleBackground}: UserRegistrationModuleProps) => {
    const inputBackground = StylingTools.getModuleBackground(moduleBackground, colors);
    const atDesktop = viewMode == ViewMode.Desktop;
    return (
      <Stack
        direction="column"
        spacing={0}
        padding="20px"
        sx={{
          borderRadius: border.radius + 'px',
          boxShadow: StylingTools.getBoxShadow(dropShadow),
          background: StylingTools.getReverseModuleBackground(moduleBackground, colors)
        }}
      >
        <PlainText fontSize={atDesktop ? '27px' : '16px'} fontWeight={600}>
          Das NEUE FunDorado! Unbegrenzter Zugang zu über 100.000 Videos, exklusiven Starshows und 24/7 Camgirls! Ab nur 4,95€
        </PlainText>
        <Box
          sx={{
            display: 'grid',
            padding: atDesktop ? '20px 60px' : '20px 0',
            gridTemplateColumns: atDesktop ? '1fr 1fr' : '1fr',
            gridTemplateRows: 'auto',
            rowGap: atDesktop ? '25px' : '20px',
            columnGap: '20px',
            gridTemplateAreas: atDesktop
              ? ` 
                "display-name email" 
                "first-name last-name"
                "country birth-date"
                "password confirm-password"
              `
              : ` 
                "display-name"
                "email" 
                "first-name"
                "last-name"
                "country"
                "birth-date"
                "password"
                "confirm-password"
              `
          }}
        >
          <LandingPageInput
            gridArea="display-name"
            hint="Benutzername"
            border={border}
            dropShadow={dropShadow}
            background={inputBackground}
          />
          <LandingPageInput
            gridArea="first-name"
            hint="Vorname"
            border={border}
            dropShadow={dropShadow}
            background={inputBackground}
          />
          <LandingPageInput
            gridArea="country"
            hint="Deutschland"
            border={border}
            dropShadow={dropShadow}
            background={inputBackground}
          />
          <LandingPageInput
            gridArea="password"
            hint="Passwort"
            border={border}
            dropShadow={dropShadow}
            background={inputBackground}
          />
          <LandingPageInput
            gridArea="email"
            hint="eMail Adresse"
            border={border}
            dropShadow={dropShadow}
            background={inputBackground}
          />
          <LandingPageInput
            gridArea="last-name"
            hint="Nachname"
            border={border}
            dropShadow={dropShadow}
            background={inputBackground}
          />
          <Stack
            gridArea="birth-date"
            direction="row"
            spacing={0}
            gap={atDesktop ? '25px' : '10px'}
          >
            <LandingPageInput
              hint="Tag"
              border={border}
              dropShadow={dropShadow}
              background={inputBackground}
            />
            <LandingPageInput
              hint="Monat"
              border={border}
              dropShadow={dropShadow}
              background={inputBackground}
            />
            <LandingPageInput
              hint="Jahr"
              border={border}
              dropShadow={dropShadow}
              background={inputBackground}
            />
          </Stack>
          <LandingPageInput
            gridArea="confirm-password"
            hint="Passwort wiederholen"
            border={border}
            dropShadow={dropShadow}
            background={inputBackground}
          />
        </Box>
        <Box>
          <ThreeStars/>
        </Box>
        <PlainText textTransform="uppercase" fontSize={atDesktop ? '40px' : '30px'} fontWeight={900}>
          Gutschein
        </PlainText>
        <VoucherCode
          viewMode={viewMode}
          digitBackground={StylingTools.getModuleBackground(moduleBackground, colors)}
        />
        <Box height={atDesktop ? '35px' : '20px'} />
        <LandingPageButton
          caption="Hier mit Gutschein anmelden"
          border={border}
          colors={colors}
          viewMode={viewMode}
          width="100%"
        />
      </Stack>
    )
  }
);

// endregion

