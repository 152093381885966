// region imports

import * as React from 'react';
import {AppBar, Stack, Toolbar} from "@mui/material";
import {ViewModeMenu} from "./ViewModeMenu";
import {ViewScale} from "./ViewScale";
import {LandingPageSettingsButton} from "./LandingPageSettingsButton";
import {SaveButton} from "./SaveButton";
import {NewModuleButton} from "./NewModuleButton";
import {AutoScaleButton} from "./AutoScaleButton";

// endregion


// region component

/**
 * {@link EditorTopBar} is the editor top bar showing controls related to the editor.
 */
export const EditorTopBar: React.FC = () => (
  <AppBar
    position="relative"
    elevation={0}
    sx={{
      borderTop: '1px solid rgba(0,0,0,0.2)'
    }}
  >
    <Toolbar variant="dense" sx={{gap: 2}}>
      <ViewModeMenu/>
      <ViewScale/>
      <AutoScaleButton/>
      <LandingPageSettingsButton/>
      <NewModuleButton/>
      <SaveButton/>
    </Toolbar>
  </AppBar>
);

// endregion
