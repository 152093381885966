// region imports

import {SettingsState} from "./SettingsState";
import {LanguageType} from "../../constants/LanguageType";
import {ReducerActionType} from "../ReducerActionType";
import {ViewMode} from "../../constants/ViewMode";
import {SettingsStoreActions} from "./SettingsStoreActions";

// endregion

// region local types

const initialState : SettingsState = {
  language: LanguageType.English,
}

/**
 * All system action models.
 */
type SettingsAction = ReturnType<typeof SettingsStoreActions.setLanguage> ;

// endregion

// region exports

export function settingsReducer(aState: SettingsState = initialState, anAction: SettingsAction): SettingsState {
  switch(anAction.type) {
    case ReducerActionType.SetLanguage:
      return {
        ...aState,
        language: anAction.language
      };
    default:
      return aState;
  }
}

// endregion