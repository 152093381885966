// region imports

import {Box} from '@mui/material';
import * as React from 'react';
import {LandingPageProperties} from "../../properties/landingPage/LandingPageProperties";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {ButtonModuleProperties} from "../../properties/buttonModule/ButtonModuleProperties";
import {ModuleType} from '../../../constants/ModuleType';
import {BannerModuleProperties} from "../../properties/bannerModule/BannerModuleProperties";
import {UserRegistrationModuleProperties} from "../../properties/userRegistrationModule/UserRegistrationModuleProperties";
import {CamsModuleProperties} from "../../properties/camsModule/CamsModuleProperties";
import {VideosModuleProperties} from "../../properties/videosModule/VideosModuleProperties";
import {StarShowsModuleProperties} from "../../properties/starShowsModule/StarShowsModuleProperties";
import {VoucherModuleProperties} from "../../properties/voucherModule/VoucherModuleProperties";
import {HeaderBannerModuleProperties} from "../../properties/headerBannerModule/HeaderBannerModuleProperties";
import {
  SingleImageAndTextModuleProperties
} from "../../properties/singleImageAndTextModule/SingleImageAndTextModuleProperties";
import {
  TwoImagesAndTextsModuleProperties
} from "../../properties/twoImagesAndTextsModule/TwoImagesAndTextsModuleProperties";
import {LandingPageModule} from "../../../types/LandingPageModule";
import {ModuleProperties} from "../../properties/ModuleProperties";
import {LandingPageModuleDictionary} from "../../../types/LandingPageModuleDictionary";
import {UFSorted} from "../../../UF/tools/UFSorted";

// endregion

// region local types and functions

interface PropertiesViewProps {
  readonly modules: LandingPageModuleDictionary;
  readonly selectedModule: string;
}

function mapStateToProps(anApplicationState: ApplicationState) {
  return {
    modules: anApplicationState.landingPage.modules,
    selectedModule: anApplicationState.landingPage.selectedModule
  }
}

// endregion

// region component

export const PropertiesView = connect(mapStateToProps)(({modules, selectedModule}: PropertiesViewProps) => {
  const sortedModules = UFSorted.sort(modules);
  return (
    <Box sx={{borderLeft: '1px solid grey', background: '#ccc', height: '100%'}}>
      <LandingPageProperties visible={selectedModule.length <= 0}/>
      {
        sortedModules.map(
          (module) => <ModuleProperties
            type={module.type} moduleKey={module.key} key={module.key} visible={selectedModule == module.key}
          />
        )
      }
    </Box>
  );
});

// endregion