// region imports

import * as React from 'react';
import {IconButton, Stack, Typography} from "@mui/material";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {LandingPageTemplate} from "../../../types/LandingPageTemplate";
import {TemplateItem} from "../../dialogs/parts/TemplateItem";
import {styled} from "@mui/styles";
import {TT} from "../../TT";
import {useEffect} from "react";
import {NetworkController} from "../../../controllers/NetworkController";
import {UFBusy} from "../../../UF-mui/components/wrappers/UFBusy";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {Config} from "../../../constants/Config";


// endregion

// region local types

interface SelectSourceTemplateProps {
  readonly value: string;
  readonly onChange: (value: string) => any;
  readonly networkBusy: boolean;
  readonly templates: LandingPageTemplate[];
}

const AliceCarouselContainer = styled('div')({
  width: '100%',
  '& .alice-carousel__prev-btn': {
    padding: 0,
    display: 'initial',
  },
  '& .alice-carousel__next-btn': {
    padding: 0,
    display: 'initial',
  },
});

function mapStateToProps(anApplicationState: ApplicationState) {
  return {
    networkBusy: anApplicationState.network.networkBusy,
    templates: anApplicationState.user.templates
  }
}

// endregion

// region component

export const SelectSourceTemplate = connect(mapStateToProps)((
  {value, onChange, templates, networkBusy}: SelectSourceTemplateProps
) => {
  const items = templates.map(item => <TemplateItem template={item}/>);
  useEffect(
    () => {
      // noinspection JSIgnoredPromiseFromCall
      NetworkController.instance.refreshTemplates();
    },
    []
  );
  return (
    <Stack width="100%" gap={Config.propertiesGapSize}>
      <Typography>
        <TT ttid="new-landing-page.template-text">
          Select a template create the landing page with.
        </TT>
      </Typography>
      <UFBusy busy={networkBusy} sx={{minHeight: '300px'}}>
        <AliceCarouselContainer>
          <AliceCarousel
            mouseTracking={true}
            items={items}
            disableDotsControls
            activeIndex={templates.findIndex(item => item.key == value)}
            onSlideChanged={(event) => onChange(templates[event.item].key)}
            renderPrevButton={({isDisabled}) =>
              <IconButton
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: '40%',
                }}
                disabled={isDisabled}
              >
                <ChevronLeftIcon
                  sx={{
                    color: 'white',
                    filter: 'drop-shadow(-1px -1px 0 black) drop-shadow(+1px +1px 0 black) drop-shadow(-1px +1px 0 black) drop-shadow(+1px -1px 0 black)'
                  }}
                />
              </IconButton>
            }
            renderNextButton={({isDisabled}) =>
              <IconButton
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: '40%',
                }}
                disabled={isDisabled}
              >
                <ChevronRightIcon
                  sx={{
                    color: 'white',
                    filter: 'drop-shadow(-1px -1px 0 black) drop-shadow(+1px +1px 0 black) drop-shadow(-1px +1px 0 black) drop-shadow(+1px -1px 0 black)'
                  }}
                />
              </IconButton>
            }
          />
        </AliceCarouselContainer>
      </UFBusy>
    </Stack>
  )
});

// endregion