// region imports

import {UserState} from "./UserState";
import {UserStoreActions} from "./UserStoreActions";
import {ReducerActionType} from "../ReducerActionType";
import {UserLocation} from "../../constants/UserLocation";

// endregion

// region local types

const initialState: UserState = {
  fullName: '',
  authenticated: false,
  administrator: false,
  urlCode: 'webmaster_id',
  location: UserLocation.Login,
  selectedCampaign: '',
  selectedTemplate: '',
  templates: [],
  campaigns: []
}

type UserAction = ReturnType<typeof UserStoreActions.setUser> | ReturnType<typeof UserStoreActions.setLocation>
  | ReturnType<typeof UserStoreActions.setSelectedCampaign> | ReturnType<typeof UserStoreActions.setSelectedTemplate>
  | ReturnType<typeof UserStoreActions.clear> | ReturnType<typeof UserStoreActions.setTemplates>
  | ReturnType<typeof UserStoreActions.setCampaigns>
  ;

/**
 * Checks if the location is correct for the other state values. Adjust if necessary.
 */
function validateLocation(aLocation: UserLocation, aState: UserState): UserLocation {
  // users that are not authenticated can only be at the login
  if (!aState.authenticated) {
    return UserLocation.Login;
  }
  // administrator users can only select a template
  if (aState.administrator && [UserLocation.SelectCampaign, UserLocation.CreateLandingPage].includes(aLocation)) {
    return UserLocation.SelectTemplate;
  }
  // non administrator users can only select a campaign
  if (!aState.administrator && (aLocation == UserLocation.SelectTemplate)) {
    return UserLocation.SelectCampaign;
  }
  // normal users have first to select a campaign
  if (
    !aState.administrator &&
    [UserLocation.EditLandingPage, UserLocation.CreateLandingPage] &&
    (aState.selectedCampaign.length <= 0)
  ) {
    return UserLocation.SelectCampaign;
  }
  return aLocation;
}

// endregion

// region exports

export function userReducer(aState: UserState = initialState, anAction: UserAction): UserState {
  switch (anAction.type) {
    case ReducerActionType.SetUser:
      return {
        ...aState,
        fullName: anAction.fullName,
        authenticated: true,
        administrator: anAction.administrator
      };
    case ReducerActionType.SetLocation:
      return {
        ...aState,
        location: validateLocation(anAction.location, aState)
      };
    case ReducerActionType.SetSelectedCampaign:
      return {
        ...aState,
        selectedCampaign: anAction.selectedCampaign
      };
    case ReducerActionType.SetSelectedTemplate:
      return {
        ...aState,
        selectedTemplate: anAction.selectedTemplate
      };
    case ReducerActionType.Clear:
      return {
        ...aState,
        location: UserLocation.Login,
        fullName: '',
        authenticated: false,
        administrator: false
      };
    case ReducerActionType.SetTemplates:
      return {
        ...aState,
        templates: anAction.templates
      };
    case ReducerActionType.SetCampaigns:
      return {
        ...aState,
        campaigns: anAction.campaigns
      };
    default:
      return aState;
  }
}
