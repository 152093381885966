// region Imports

import {TestState} from "./TestState";
import {ReducerActionType} from "../../ReducerActionType";
import {TestStoreActions} from "./TestStoreActions";

// endregion

// region Local variables

/**
 * Initial test state
 */
const initialState: TestState = {
  show: false,
};

// endregion

// region local types

/**
 * All input action models.
 */
type TestAction = ReturnType<typeof TestStoreActions.show> | ReturnType<typeof TestStoreActions.hide>;

// endregion

// region exports

/**
 * Reduces the Test store to a new state.
 *
 * @param aState
 *   Current state
 * @param anAction
 *   Action to apply to the store
 *
 * @return new state
 */
export function testReducer(aState: TestState = initialState, anAction: TestAction): TestState {
  switch (anAction.type) {
    case ReducerActionType.ShowTest:
      return {
        ...aState,
        show: true,
      };
    case ReducerActionType.HideTest:
      return {
        ...aState,
        show: false
      };
    default:
      return aState;
  }
}

// endregion