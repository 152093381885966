// region imports

import * as React from 'react';
import {ModuleBackgroundType} from "../../../constants/ModuleBackgroundType";
import {LandingPageImage} from "./LandingPageImage";
import {Box, Stack, Typography} from "@mui/material";
import {LandingPageBorder} from "../../../types/LandingPageBorder";
import {StylingTools} from "../../../tools/StylingTools";
import {LandingPageColors} from "../../../types/LandingPageColors";
import {LandingPageDropShadow} from "../../../types/LandingPageDropShadow";

// endregion

// region local types

/**
 * Properties for component
 */
interface ImageWithNameProps {
  name: string;
  moduleBackground: ModuleBackgroundType;
  border: LandingPageBorder;
  colors: LandingPageColors;
  dropShadow: LandingPageDropShadow;
  imageHeight: string;
  imageWidth: string;
}

// endregion

// region component

export const ImageWithName =
  ({name, moduleBackground, border, colors, imageHeight, imageWidth, dropShadow}: ImageWithNameProps) => (
    <Stack
      direction="column"
      sx={{
        overflow: 'hidden',
        background: StylingTools.getReverseModuleBackground(moduleBackground, colors),
        borderRadius: border.radius + 'px',
        flex: '1 1',
        boxShadow: StylingTools.getBoxShadow(dropShadow),
        '&:hover': {
          opacity: 0.8
        }
      }}
      width={imageWidth}
      minWidth={imageWidth}
    >
      <LandingPageImage height={imageHeight}/>
      <Box>
        <Typography variant="inherit" paddingLeft={Math.max(12, border.radius - 8) + 'px'}>{name}</Typography>
      </Box>
    </Stack>
  );

// endregion
