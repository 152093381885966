// region imports

import * as React from 'react';
import {Stack} from "@mui/material";
import {PlainText} from "./PlainText";
import {StylingTools} from "../../../tools/StylingTools";
import {Config} from "../../../constants/Config";
import {LandingPageColors} from "../../../types/LandingPageColors";
import {LandingPageBorder} from "../../../types/LandingPageBorder";
import {LandingPageDropShadow} from "../../../types/LandingPageDropShadow";
import {ModuleBackgroundType} from "../../../constants/ModuleBackgroundType";

// endregion

// region local types

/**
 * Properties for the component.
 */
interface TextBlockProps {
  title: string;
  text: string;
  colors: LandingPageColors;
  border: LandingPageBorder;
  dropShadow: LandingPageDropShadow;
  moduleBackground: ModuleBackgroundType;
}

// endregion

// region component

/**
 * {@link TextBlock} shows a title and html formatted text.
 */
export const TextBlock: React.FC<TextBlockProps> =
  ({
     title, text, colors, border, dropShadow,
     moduleBackground
   }) => (
    <Stack
      direction="column"
      spacing={0}
      sx={{
        textAlign: 'left',
        background: StylingTools.getReverseModuleBackground(moduleBackground, colors),
        padding: Config.textPadding + 'px',
        borderRadius: border.radius + 'px',
        boxShadow: StylingTools.getBoxShadow(dropShadow),
        width: '100%',
        height: '100%',
        overflow: 'hidden'
      }}
    >
      {
        title &&
        <PlainText
          fontSize={Config.contentTitleSize}
          fontWeight={Config.contentTitleWeight}
        >
          {title}
        </PlainText>
      }
      {StylingTools.parseHtml(text)}
    </Stack>
  );

// endregion