// region imports

import * as React from 'react';
import {PropertyGroupsContainer} from "../PropertyGroupsContainer";
import {ModuleActions} from "../ModuleActions";
import {PropertyGroup} from "../PropertyGroup";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {LandingPageModule} from "../../../types/LandingPageModule";
import {UFFormTextField} from "../../../UF-mui/components/form/UFFormTextField";
import {HtmlTextField} from "../../form/HtmlTextField";
import {LandingPageFont} from "../../../types/LandingPageFont";
import {TT} from "../../TT";
import {LandingPageStoreActions} from "../../../store/landingPage/LandingPageStoreActions";
import {StylingTools} from "../../../tools/StylingTools";
import {ImageFitSelect} from "../../form/ImageFitSelect";
import {ImageField} from "../../form/ImageField";

// endregion

// region local types

/**
 * Properties for component.
 */
interface TwoImagesAndTextsModulePropertiesProps {
  readonly visible: boolean;
  readonly moduleKey: string;
  readonly module: LandingPageModule;
  readonly updateModule: typeof LandingPageStoreActions.updateModule;
  readonly font: LandingPageFont;
}

/**
 * Maps state to local properties.
 */
function mapStateToProps(
  anApplicationState: ApplicationState, aProps: Partial<TwoImagesAndTextsModulePropertiesProps>
) {
  return {
    module: anApplicationState.landingPage.modules[aProps.moduleKey!],
    font: anApplicationState.landingPage.font
  }
}

// region

/**
 * All properties for the module.
 */
export const TwoImagesAndTextsModuleProperties = connect(
  mapStateToProps,
  {
    updateModule: LandingPageStoreActions.updateModule
  }
)(
  ({visible, module, font, updateModule}: TwoImagesAndTextsModulePropertiesProps) => {
    return (
      <PropertyGroupsContainer
        title={<TT ttid="two-images-and-texts-properties.title">Two images and texts module settings</TT>}
        visible={visible}
      >
        <ModuleActions module={module}/>
        <PropertyGroup
          title={<TT ttid="two-images-and-texts-properties.left-content">Left content</TT>}
          defaultExpanded={true}
        >
          <ImageFitSelect
            label={<TT ttid="two-images-and-texts-properties.left-image-fit">Left image fit</TT>}
            value={module.imageFit}
            onChange={(value) => updateModule({...module, imageFit: value})}
          />
          <ImageField
            label={<TT ttid="two-images-and-texts-properties.left-image">Left image</TT>}
            value={module.image}
            onChange={(value) => updateModule({...module, image: value})}
          />
          <UFFormTextField
            label={<TT ttid="two-images-and-texts-properties.left-title">Left title</TT>}
            value={module.title}
            onFieldChange={({value}) => updateModule({...module, title: value})}
            fontFamily={StylingTools.getFontFamily(font.family)}
            fontSize={font.size + 'px'}
          />
          <HtmlTextField
            label={<TT ttid="two-images-and-texts-properties.left-content">Left content</TT>}
            fontSize={font.size}
            fontFamily={font.family}
            value={module.text}
            onChange={(value) => updateModule({...module, text: value})}
          />
        </PropertyGroup>
        <PropertyGroup
          title={<TT ttid="two-images-and-texts-properties.right-content">Right content</TT>}
          defaultExpanded={true}
        >
          <ImageFitSelect
            label={<TT ttid="two-images-and-texts-properties.right-image-fit">Right image fit</TT>}
            value={module.imageFit2}
            onChange={(value) => updateModule({...module, imageFit2: value})}
          />
          <ImageField
            label={<TT ttid="two-images-and-texts-properties.right-image">Right image</TT>}
            value={module.image2}
            onChange={(value) => updateModule({...module, image2: value})}
          />
          <UFFormTextField
            label={<TT ttid="two-images-and-texts-properties.right-title">Right title</TT>}
            value={module.title2}
            onFieldChange={({value}) => updateModule({...module, title2: value})}
            fontFamily={StylingTools.getFontFamily(font.family)}
            fontSize={font.size + 'px'}
          />
          <HtmlTextField
            label={<TT ttid="two-images-and-texts-properties.right-content">Right content</TT>}
            fontSize={font.size}
            fontFamily={font.family}
            value={module.text2}
            onChange={(value) => updateModule({...module, text2: value})}
          />
        </PropertyGroup>
      </PropertyGroupsContainer>
    );
  }
);

// endregion