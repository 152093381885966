/**
 * Determines the possible background colors to show.
 */
export enum ModuleBackgroundType {
  /**
   * Shows the default background color.
   */
  Default,

  /**
   * Shows a very lightened version of the background color
   */
  BackgroundColor
}
