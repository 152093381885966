
export enum UserLocation {
  /**
   * Starting page.
   */
  Start,

  /**
   * User must login
   */
  Login,

  /**
   * User has to select a campaign
   */
  SelectCampaign,

  /**
   * User has to select a template.
   */
  SelectTemplate,

  /**
   * User has to create a new landing page.
   */
  CreateLandingPage,

  /**
   * System is busy loading the landing page.
   */
  LoadingPage,

  /**
   * User is busy editing the landing page
   */
  EditLandingPage
}