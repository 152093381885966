// region imports

import * as React from 'react';
import {ApplicationState} from "../../store/ApplicationState";
import {LandingPageModule} from "../../types/LandingPageModule";
import {connect} from "react-redux";
import {Stack} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {TT} from "../TT";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import {UFThunkPropsType} from "../../UF-react/tools/UFRedux";
import {PropertyGroup} from './PropertyGroup';
import {LandingPageModuleTools} from "../../tools/LandingPageModuleTools";
import {LandingPageModuleDictionary} from "../../types/LandingPageModuleDictionary";
import {Config} from "../../constants/Config";
import {LandingPageStoreActions} from "../../store/landingPage/LandingPageStoreActions";
import {ConfirmStoreActions} from "../../store/dialogs/confirm/ConfirmStoreActions";
import {ApplicationButton} from "../controls/ApplicationButton";

// endregion

// region local types and functions

interface ModuleActionsProps {
  module: LandingPageModule;
  modules: LandingPageModuleDictionary;
  swapModule: typeof LandingPageStoreActions.swapModule;
  deleteModule: typeof LandingPageStoreActions.deleteModule;
  addModule: typeof LandingPageStoreActions.addModule;
  confirmPopup: UFThunkPropsType<typeof ConfirmStoreActions.popup>;

  /**
   * Needed because using redux
   */
  children?: React.ReactNode;
}

function mapStateToProps(anApplicationState: ApplicationState) {
  return {
    modules: anApplicationState.landingPage.modules
  }
}

// endregion

// region component

/**
 * {@link ModuleActions} adds buttons to {@link PropertyGroup}. The component can contain children which will be
 * shown below the buttons.
 */
export const ModuleActions = connect(
  mapStateToProps,
  {
    swapModule: LandingPageStoreActions.swapModule,
    deleteModule: LandingPageStoreActions.deleteModule,
    confirmPopup: ConfirmStoreActions.popup,
    addModule: LandingPageStoreActions.addModule
  }
)(
  class extends React.Component<ModuleActionsProps> {
    private async handleDeleteClick() {
      const result = await this.props.confirmPopup(
        <TT ttid="delete-module.title">Confirm delete</TT>,
        <TT ttid="delete-module.text">Are you sure you want to delete this module?</TT>,
        <TT>Yes</TT>,
        <TT>No</TT>
      );
      if (result) {
        this.props.deleteModule(this.props.module.key);
      }
    }

    render() {
      const {modules, swapModule, addModule, children, module} = this.props;
      const sortedModules = LandingPageModuleTools.getSortedList(modules);
      const currentIndex = sortedModules.indexOf(module);
      return (
        <PropertyGroup title={<TT>Actions</TT>} defaultExpanded={true}>
          <Stack direction="row" spacing={0} gap={Config.propertiesGapSize}>
            <Stack direction="column" spacing={0} gap={Config.propertiesGapSize}>
              <ApplicationButton
                startIcon={<ArrowUpwardIcon/>}
                sx={{
                  justifyContent: 'flex-start',
                  whiteSpace: 'nowrap'
                }}
                disabled={currentIndex <= 0}
                onClick={() => swapModule(module.key, sortedModules[currentIndex - 1].key)}
              >
                <TT ttid="actions.move-up">Move up</TT>
              </ApplicationButton>
              <ApplicationButton
                startIcon={<ArrowDownwardIcon/>}
                sx={{
                  justifyContent: 'flex-start',
                  whiteSpace: 'nowrap'
                }}
                disabled={currentIndex >= sortedModules.length - 1}
                onClick={() => swapModule(module.key, sortedModules[currentIndex + 1].key)}
              >
                <TT ttid="actions.move-down">Move down</TT>
              </ApplicationButton>
            </Stack>
            <Stack
              direction="column"
              spacing={0}
              flexWrap="wrap"
              alignItems="flex-start"
              gap={Config.propertiesGapSize}
            >
              <ApplicationButton
                startIcon={<ContentCopyIcon/>}
                onClick={() => addModule(LandingPageModuleTools.copy(module))}
              >
                <TT ttid="actions.add-copy">Add a copy</TT>
              </ApplicationButton>
              <ApplicationButton
                color="error"
                startIcon={<DeleteOutlineRoundedIcon/>}
                onClick={() => this.handleDeleteClick()}
              >
                <TT>Delete</TT>
              </ApplicationButton>
            </Stack>
          </Stack>
          {children}
        </PropertyGroup>
      );
    }
  }
);

// endregion