// region imports

import * as React from 'react';
import {Box, Stack} from "@mui/material";
import {ViewMode} from "../../../constants/ViewMode";

// endregion

// region local types

interface VoucherCodeProps {
  viewMode: ViewMode;
  digitBackground: string;
}

// endregion

// region local components

const VoucherDigit: React.FC<VoucherCodeProps> = ({viewMode, digitBackground, ...others}) => {
  const atDesktop = viewMode == ViewMode.Desktop;
  return (
   <Box
      {...others}
      sx={{
        border: '4px solid #222',
        color: '#222',
        fontSize: atDesktop ? '50px' : '28px',
        fontWeight: '900',
        background: digitBackground,
        height: atDesktop ? '64px' : '38px',
        width: atDesktop ? '64px' : '38px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    />
  )
};

// endregion

// region component

export const VoucherCode: React.FC<VoucherCodeProps> = ({viewMode, digitBackground}) => (
  <Stack direction="row" gap={viewMode == ViewMode.Desktop ? '10px' : '5px'} spacing={0} justifyContent="center">
    <VoucherDigit viewMode={viewMode} digitBackground={digitBackground}>A</VoucherDigit>
    <VoucherDigit viewMode={viewMode} digitBackground={digitBackground}>5</VoucherDigit>
    <VoucherDigit viewMode={viewMode} digitBackground={digitBackground}>C</VoucherDigit>
    <VoucherDigit viewMode={viewMode} digitBackground={digitBackground}>X</VoucherDigit>
    <VoucherDigit viewMode={viewMode} digitBackground={digitBackground}>V</VoucherDigit>
    <VoucherDigit viewMode={viewMode} digitBackground={digitBackground}>3</VoucherDigit>
  </Stack>
);

// endregion