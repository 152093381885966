// region imports

import * as React from 'react';
import {ServerResult} from "../../constants/ServerResult";
import {TT} from "../TT";

// endregion

// region local types and functions

interface ServerResultTextProps {
  readonly result: ServerResult;
  readonly noneContent?: React.ReactNode;
}

// endregion

// region component

/**
 * Renders texts for certain server results.
 */
export const ServerResultText: React.FC<ServerResultTextProps> = (
  {result, noneContent= <span>&nbsp;</span>}
) => (
  <React.Fragment>
    {
      (result == ServerResult.AuthenticationFailed) &&
      <TT ttid="server-result.authentication-failed">Invalid or unknown email and/or password.</TT>
    }
    {
      (result == ServerResult.ServerError) &&
      <TT ttid="server-result.server-error">An error occurred while communicating with the server.</TT>
    }
    {
      (result == ServerResult.CodeNotAvailable) &&
      <TT ttid="server-result.code-error">The code is already in use and not available.</TT>
    }
    {
      (result == ServerResult.None) && noneContent
    }
  </React.Fragment>
)

// endregion