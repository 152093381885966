// region imports

import * as React from 'react';
import {connect} from 'react-redux';
import {UFConfirmDialog} from "../../UF-mui/components/dialogs/UFConfirmDialog";
import {UFInputDialog} from "../../UF-mui/components/dialogs/UFInputDialog";
import {ConfirmState} from "../../store/dialogs/confirm/ConfirmState";
import {ApplicationState} from "../../store/ApplicationState";
import {InputState} from "../../store/dialogs/input/InputState";
import {UFAlertDialog} from "../../UF-mui/components/dialogs/UFAlertDialog";
import {AlertState} from "../../store/dialogs/alert/AlertState";
import {EditState} from "../../store/dialogs/edit/EditState";
import {EditDialog} from "../dialogs/EditDialog";
import {NewModuleDialog} from "../dialogs/NewModuleDialog";
import {InputStoreActions} from "../../store/dialogs/input/InputStoreActions";
import {EditStoreActions} from "../../store/dialogs/edit/EditStoreActions";
import {AlertStoreActions} from "../../store/dialogs/alert/AlertStoreActions";
import {ConfirmStoreActions} from "../../store/dialogs/confirm/ConfirmStoreActions";
import {ChangeCodeDialog} from "../dialogs/ChangeCodeDialog";
import {TestState} from "../../store/dialogs/test/TestState";
import {TestDialog} from "../dialogs/TestDialog";
import {SelectCampaignDialog} from "../dialogs/SelectCampaignDialog";
import {DeleteTemplateDialog} from "../dialogs/DeleteTemplateDialog";
import {SelectTargetCampaignDialog} from "../dialogs/SelectTargetCampaignDialog";
import {ApplicationDialogState} from "../../store/dialogs/applicationDialog/ApplicationDialogState";
import {ApplicationDialogStoreActions} from "../../store/dialogs/applicationDialog/ApplicationDialogStoreActions";
import {ApplicationDialogType} from "../../constants/ApplicationDialogType";
import {SelectImageState} from "../../store/dialogs/selectImage/SelectImageState";
import {SelectImageStoreActions} from "../../store/dialogs/selectImage/SelectImageStoreActions";
import {SelectImageDialog} from "../dialogs/SelectImageDialog";

// endregion

// region local types and functions

/**
 * Properties for component
 */
interface RootDialogsProps {
  input: InputState;
  alert: AlertState;
  confirm: ConfirmState;
  edit: EditState;
  test: TestState;
  selectImage: SelectImageState;
  applicationDialog: ApplicationDialogState;
  hideInput: typeof InputStoreActions.hide;
  hideConfirm: typeof ConfirmStoreActions.hide;
  hideAlert: typeof AlertStoreActions.hide;
  hideEdit: typeof EditStoreActions.hide;
  hideSelectImage: typeof SelectImageStoreActions.hide;
  hideApplicationDialog: typeof ApplicationDialogStoreActions.hide;
}

/**
 * Maps state to local properties.
 */
function mapStateToProps(state: ApplicationState) {
  return {
    confirm: state.dialogs.confirm,
    input: state.dialogs.input,
    alert: state.dialogs.alert,
    edit: state.dialogs.edit,
    test: state.dialogs.test,
    selectImage: state.dialogs.selectImage,
    applicationDialog: state.dialogs.applicationDialog
  }
}

// endregion

// region component

/**
 * The {@link RootDialogs} component shows certain modal dialogs available
 * globally.
 */
export const RootDialogs = connect(
  mapStateToProps,
  {
    hideInput: InputStoreActions.hide,
    hideConfirm: ConfirmStoreActions.hide,
    hideAlert: AlertStoreActions.hide,
    hideEdit: EditStoreActions.hide,
    hideSelectImage: SelectImageStoreActions.hide,
    hideApplicationDialog: ApplicationDialogStoreActions.hide,
  }
)((
    {
      input, alert, confirm, edit, test, selectImage, applicationDialog,
      hideInput, hideConfirm, hideAlert, hideEdit, hideSelectImage, hideApplicationDialog
    }: RootDialogsProps
  ) => {
    if (input.show) {
      return <UFInputDialog
        title={input.title}
        content={input.content}
        value={input.value}
        allowEmpty={input.allowEmpty}
        ok={input.ok}
        cancel={input.cancel}
        variant="contained"
        onClose={(value: string | false) => {
          hideInput();
          input.callback(value);
        }}
      />;
    } else if (confirm.show) {
      return <UFConfirmDialog
        title={confirm.title}
        content={confirm.content}
        yes={confirm.yes}
        no={confirm.no}
        variant="contained"
        onClose={(choice: boolean) => {
          hideConfirm();
          confirm.callback(choice);
        }}
      />;
    } else if (alert.show) {
      return <UFAlertDialog
        title={alert.title}
        content={alert.content}
        close={alert.close}
        variant="contained"
        onClose={() => {
          hideAlert();
          alert.callback();
        }}
      />;
    } else if (edit.show) {
      return <EditDialog
        value={edit.value}
        allowFontSize={edit.allowFontSize}
        textColor={edit.textColor}
        backgroundColor={edit.backgroundColor}
        fontFamily={edit.fontFamily}
        fontSize={edit.fontSize}
        onClose={hideEdit}
        onChange={(value) => edit.callback(value)}
      />
    } else if (selectImage.show) {
      return <SelectImageDialog
        onClose={hideSelectImage}
        onSelect={(value) => selectImage.callback(value.url)}
      />
    } else if (test.show) {
      return <TestDialog/>
    } else {
      switch (applicationDialog.dialog) {
        case ApplicationDialogType.ChangeCode:
          return <ChangeCodeDialog onClose={hideApplicationDialog}/>;
        case ApplicationDialogType.DeleteTemplate:
          return <DeleteTemplateDialog onClose={hideApplicationDialog}/>;
        case ApplicationDialogType.NewModule:
          return <NewModuleDialog onClose={hideApplicationDialog}/>;
        case ApplicationDialogType.SelectCampaign:
          return <SelectCampaignDialog onClose={hideApplicationDialog}/>;
        case ApplicationDialogType.SelectTargetCampaign:
          return <SelectTargetCampaignDialog onClose={hideApplicationDialog}/>;
        default:
          return null;
      }
    }
  }
);

// endregion
