// region imports

import {PropertyGroup} from "../PropertyGroup";
import {MenuItem} from "@mui/material";
import * as React from "react";
import {UFFormTextField} from "../../../UF-mui/components/form/UFFormTextField";
import {UFFormSelect} from "../../../UF-mui/components/form/UFFormSelect";
import {TopBarLeftType} from "../../../constants/TopBarLeftType";
import {TT} from "../../TT";
import {TopBarRightType} from "../../../constants/TopBarRightType";
import {connect} from "react-redux";
import {LandingPageTopBar} from "../../../types/LandingPageTopBar";
import {ApplicationState} from "../../../store/ApplicationState";
import {LandingPageColors} from "../../../types/LandingPageColors";
import {LandingPageFont} from "../../../types/LandingPageFont";
import {HtmlTextField} from "../../form/HtmlTextField";
import {UFFormNumberSliderField} from "../../../UF-mui/components/form/UFFormNumberSliderField";
import {LandingPageStoreActions} from "../../../store/landingPage/LandingPageStoreActions";
import {EditStoreActions} from "../../../store/dialogs/edit/EditStoreActions";
import {ImageField} from "../../form/ImageField";

// endregion

// region local types and functions

/**
 * Properties for the component.
 */
interface LandingPageTopBarPropertiesProps {
  topBar: LandingPageTopBar;
  colors: LandingPageColors;
  font: LandingPageFont,
  setTopBar: typeof LandingPageStoreActions.setTopBar;
}

/**
 * Maps application state to component properties.
 */
function mapStateToProps(state: ApplicationState) {
  return {
    topBar: state.landingPage.topBar,
    colors: state.landingPage.colors,
    font: state.landingPage.font
  };
}

// endregion

// region component

/**
 * The landing page top bar properties.
 */
export const LandingPageTopBarProperties = connect(
  mapStateToProps,
  {
    setTopBar: LandingPageStoreActions.setTopBar,
  }
)(
  ({topBar, colors, font, setTopBar}: LandingPageTopBarPropertiesProps) => (
    <PropertyGroup title={<TT ttid="top-bar-properties.title">Top bar</TT>}>
      <UFFormSelect
        label={<TT ttid="top-bar-properties.left-type">Left content</TT>}
        value={topBar.leftType}
        onFieldChange={({value}) => setTopBar({...topBar, leftType: value})}
      >
        <MenuItem value={TopBarLeftType.None}><TT ttid="content-type.nothing">Nothing</TT></MenuItem>
        <MenuItem value={TopBarLeftType.Image}><TT ttid="content-type.image">Image</TT></MenuItem>
        <MenuItem value={TopBarLeftType.Text}><TT ttid="content-type.text">Text</TT></MenuItem>
      </UFFormSelect>
      {
        (topBar.leftType == TopBarLeftType.Text) &&
        <HtmlTextField
          label={<TT ttid="top-bar-properties.left-text">Left text</TT>}
          value={topBar.leftText}
          onChange={value => setTopBar({...topBar, leftText: value})}
          textColor={colors.text}
          backgroundColor={colors.background}
          fontFamily={font.family}
          fontSize={topBar.leftFontSize}
          showFontSize={true}
        />
      }
      {
        (topBar.leftType == TopBarLeftType.Text) &&
        <UFFormNumberSliderField
          label={<TT ttid="top-bar-properties.left-font-size">Left font size</TT>}
          leftLabel="$minValue$px"
          rightLabel="$maxValue$px"
          value={topBar.leftFontSize}
          showInput={true}
          minValue={6}
          maxValue={150}
          onFieldChange={({value}) => setTopBar({...topBar, leftFontSize: value})}
        />
      }
      {
        (topBar.leftType == TopBarLeftType.Image) &&
        <ImageField
          label={<TT ttid="top-bar-properties.left-image">Left image</TT>}
          value={topBar.leftImage}
          onChange={(value) => setTopBar({...topBar, leftImage: value})}
        />
      }
      <UFFormSelect
        label={<TT ttid="top-bar-properties.right-type">Right content</TT>}
        value={topBar.rightType}
        onFieldChange={({value}) => setTopBar({...topBar, rightType: value})}
      >
        <MenuItem value={TopBarRightType.None}><TT ttid="content-type.nothing">Nothing</TT></MenuItem>
        <MenuItem value={TopBarRightType.Image}><TT ttid="content-type.image">Image</TT></MenuItem>
        <MenuItem value={TopBarRightType.Text}><TT ttid="content-type.text">Text</TT></MenuItem>
        <MenuItem value={TopBarRightType.Banner}><TT ttid="content-type.banner">Banner</TT></MenuItem>
      </UFFormSelect>
      {
        (topBar.rightType == TopBarRightType.Text) &&
        <HtmlTextField
          label={<TT ttid="top-bar-properties.right-text">Right text</TT>}
          value={topBar.rightText}
          onChange={value => setTopBar({...topBar, rightText: value})}
          textColor={colors.text}
          backgroundColor={colors.background}
          fontFamily={font.family}
          fontSize={topBar.rightFontSize}
          showFontSize={true}
        />
      }
      {
        (topBar.rightType == TopBarRightType.Text) &&
        <UFFormNumberSliderField
          label={<TT ttid="top-bar-properties.right-font-size">Right font size</TT>}
          leftLabel="$minValue$px"
          rightLabel="$maxValue$px"
          value={topBar.rightFontSize}
          showInput={true}
          minValue={6}
          maxValue={150}
          onFieldChange={({value}) => setTopBar({...topBar, rightFontSize: value})}
        />
      }
      {
        (topBar.rightType == TopBarRightType.Image) &&
        <ImageField
          label={<TT ttid="top-bar-properties.right-image">Right image</TT>}
          value={topBar.rightImage}
          onChange={(value) => setTopBar({...topBar, rightImage: value})}
        />
      }
      {
        (topBar.rightType == TopBarRightType.Banner) &&
        <UFFormTextField
          label={<TT ttid="top-bar-properties.right-banner">Right Banner</TT>}
          value={topBar.rightBanner}
          onFieldChange={({value}) => setTopBar({...topBar, rightBanner: value})}
        />
      }
    </PropertyGroup>
  )
);
