// region imports

import * as React from 'react';
import {Box} from "@mui/material";
import {Config} from "../../constants/Config";
import {connect} from "react-redux";
import {LandingPageStoreActions} from "../../store/landingPage/LandingPageStoreActions";

// endregion

// region local types

interface ModuleContentProps {
  /**
   * Width of content
   */
  contentWidth: number;

  /**
   * True if content is currently selected
   */
  selected: boolean;

  /**
   * Background color to use
   */
  backgroundColor: string;

  /**
   * Key of module
   */
  moduleKey: string;

  /**
   * Needed because using redux
   */
  children?: React.ReactNode;

  /**
   * Update store
   */
  setSelectedModule: typeof LandingPageStoreActions.setSelectedModule;
}

// endregion

// region component

/**
 * {@link ModuleContent} renders the content with a certain width. It also takes care drawing the selected state if
 * needed. The component handles clicks and will set the module as selected.
 */
export const ModuleContent = connect(null, {setSelectedModule: LandingPageStoreActions.setSelectedModule})(
  ({contentWidth, selected, backgroundColor, moduleKey, setSelectedModule, children}: ModuleContentProps) => (
    <Box
      sx={{
        background: backgroundColor,
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        cursor: selected ? 'default' : 'pointer'
      }}
      onClick={() => {
        if (!selected) {
          setSelectedModule(moduleKey);
        }
      }}
    >
      <Box
        sx={{
          width: contentWidth + 'px',
          padding: Config.verticalPadding + 'px ' + Config.horizontalPadding + 'px',
          height: 'auto',
          position: 'relative',
          color: '#222222',
          '& *': {
            margin: 0
          }
        }}
      >
        {children}
      </Box>
      {selected &&
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: contentWidth + 'px',
            border: '2px solid #c00000',
            pointerEvents: 'none',
            borderRadius: '8px'
          }}
        />
      }
    </Box>
  )
);