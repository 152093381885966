// region imports

import * as React from 'react';
import {LandingPageBorder} from "../../../types/LandingPageBorder";
import {LandingPageColors} from "../../../types/LandingPageColors";
import {LandingPageDropShadow} from "../../../types/LandingPageDropShadow";
import {Box} from "@mui/material";
import {ViewMode} from "../../../constants/ViewMode";
import {StylingTools} from "../../../tools/StylingTools";
import {Config} from '../../../constants/Config';

// endregion

// region local types

interface LandingPageButtonProps {
  readonly caption: string;
  readonly border: LandingPageBorder;
  readonly colors: LandingPageColors;
  readonly dropShadow?: LandingPageDropShadow;
  readonly viewMode: ViewMode;
  readonly width?: string;
  readonly margin?: string;
}

// endregion

// region component

/**
 * Renders a landing page button.
 */
export const LandingPageButton: React.FC<LandingPageButtonProps> =
  ({
     caption, border, colors, dropShadow,
     viewMode, width, margin = '0'
   }) => (
    <Box
      sx={{
        background: colors.buttonBackground,
        color: colors.buttonText,
        borderRadius: border.radius + 'px',
        minWidth: width == undefined ? (viewMode == ViewMode.Desktop ? '50%' : '100%') : 'auto',
        maxWidth: '100%',
        width: width == undefined ? 'auto' : width,
        //whiteSpace: 'wrap',
        overflow: 'hidden',
        padding: '12px',
        margin: margin,
        boxShadow: dropShadow != undefined ? StylingTools.getBoxShadow(dropShadow) : 'none',
        '&:hover': {
          background: StylingTools.getHoverColor(colors.buttonBackground)
        },
        textTransform: 'uppercase',
        fontWeight: Config.contentTitleWeight
      }}
    >
      {caption}
    </Box>
  );

// endregion