// region imports

import * as React from 'react';
import {Box} from "@mui/material";
import {Config} from "../../../constants/Config";

// endregion

interface LandingPageImageProps {
  height?: string;
  width?: string;
}

// region component

/**
 * An image within the landing page.
 */
export const LandingPageImage: React.FC<LandingPageImageProps> =
  ({height = '100%', width= '100%'}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width,
      height,
      objectFit: 'cover',
      background: Config.imageBackground
    }}
  >
    (image)
  </Box>
);

// endregion