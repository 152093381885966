// region imports

import * as React from 'react';
import {Warning} from "@mui/icons-material";
import {Button, Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {UFStyledDialogActions} from "./UFStyledDialogActions";
import {UFDialogTitleIcon} from "./UFDialogTitleIcon";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

// endregion

// region component

/**
 * Properties for {@link UFConfirmDialog}
 */
export interface UFConfirmDialogProps {
  /**
   * Title for dialog
   */
  title?: React.ReactNode;

  /**
   * Content text
   */
  content: React.ReactNode;

  /**
   * Caption for yes button
   */
  yes: React.ReactNode;

  /**
   * Caption for no button
   */
  no: React.ReactNode;

  /**
   * Button variant
   */
  variant?: 'text' | 'outlined' | 'contained';

  /**
   * Callback that is called when user makes choice
   *
   * @param choice
   *   Choice by user
   */
  onClose: (choice: boolean) => void;
}

/**
 * Defines a confirm dialog. The dialog shows a question to which the user can answer yes (callback is
 * called with true) or no (callback is called with false). Clicking outside the dialog will result
 * in a callback with false.
 */
export const UFConfirmDialog: React.FC<UFConfirmDialogProps> = (
  {
    title,
    content,
    yes,
    no,
    variant = 'contained',
    onClose
  }
) => {
  if (typeof content === 'string') {
    content = <DialogContentText>{content}</DialogContentText>;
  }
  return (
    <Dialog open={true} onClose={() => onClose(false)}>
      {
        title &&
        <DialogTitle>
          <UFDialogTitleIcon icon={<Warning/>}/>{title}
        </DialogTitle>
      }
      <DialogContent>
        {content}
      </DialogContent>
      <UFStyledDialogActions>
        <Button
          color="primary"
          variant={variant}
          startIcon={<CheckIcon />}
          onClick={() => onClose(true)}
        >
          {yes}
        </Button>
        <Button
          color="secondary"
          variant={variant}
          startIcon={<CloseIcon />}
          onClick={() => onClose(false)}
        >
          {no}
        </Button>
      </UFStyledDialogActions>
    </Dialog>
  );
};

// endregion
