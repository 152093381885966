// region imports

import * as React from 'react';
import {LandingPageConfiguration} from "../../../types/LandingPageConfiguration";
import {UFFormTextField} from "../../../UF-mui/components/form/UFFormTextField";
import {TT} from "../../TT";
import {Box, Typography} from "@mui/material";
import {CodeField} from "../../form/CodeField";
import {CategorySelect} from "../../form/CategorySelect";
import {DescriptionField} from "../../form/DescriptionField";
import {Config} from "../../../constants/Config";
import {ClassificationSelect} from "../../form/ClassificationSelect";
import {UrlPreview} from "../../form/UrlPreview";

// endregion

// region local types and functions

interface InitialLandingPageConfigurationProps {
  readonly busy: boolean;
  readonly configuration: LandingPageConfiguration;
  readonly onChange: (value: LandingPageConfiguration) => any;
  readonly error: boolean;
  readonly webmasterCode: string;
}

// endregion

// region component

export const InitialLandingPageConfiguration: React.FC<InitialLandingPageConfigurationProps> = (
  {configuration, onChange, busy, error, webmasterCode}
) => {
  return (
    <Box
      sx={{
        display: 'grid',
        rowGap: 1.5 * Config.propertiesGapSize,
        columnGap: '2rem',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto auto auto auto auto',
        gridTemplateAreas: `
          "introduction introduction" 
          "title title"
          "code url"
          "description description" 
          "category classification"
        `
      }}
    >
      <Typography
        sx={{
          gridArea: 'introduction'
        }}
      >
        <TT ttid="new-landing-page.properties-introduction" html={true}>
          Set the title (shown in the browser tab), the code (used with the landing page url), the description (shown in
          search engines) and the initial category and classification used when creating category depending content.
          <br/>
          These values can always be changed at a later time while editing the landing page.
        </TT>
      </Typography>
      <UFFormTextField
        label={<TT ttid="new-landing-page.title-label">Title</TT>}
        onFieldChange={
          ({value}) => onChange({...configuration, title: value})
        }
        value={configuration.title}
        disabled={busy}
        autoFocus={true}
        sx={{
          gridArea: 'title'
        }}
      />
      <CodeField
        value={configuration.urlCode}
        onChange={(value) => onChange({...configuration, urlCode: value})}
        disabled={busy}
        error={error}
        required={true}
        label={<TT ttid="new-landing-page.code-label">New code for url</TT>}
        sx={{
          gridArea: 'code'
        }}
      />
      <UrlPreview
        landingPageCode={configuration.urlCode}
        webmasterCode={webmasterCode}
        sx={{
          gridArea: 'url'
        }}
      />
      <DescriptionField
        value={configuration.description}
        onChange={(value) => onChange({...configuration, description: value})}
        disabled={busy}
        sx={{
          gridArea: 'description'
        }}
      />
      <CategorySelect
        label={<TT ttid="new-landing-page.initial-category-label">Initial category</TT>}
        value={configuration.initialCategory}
        onChange={(value) => onChange({...configuration, initialCategory: value})}
        disabled={busy}
        sx={{
          gridArea: 'category'
        }}
      />
      <ClassificationSelect
        label={<TT ttid="new-landing-page.initial-classification-label">Initial classification</TT>}
        value={configuration.initialClassification}
        onChange={(value) => onChange({...configuration, initialClassification: value})}
        disabled={busy}
        sx={{
          gridArea: 'classification'
        }}
      />
    </Box>
  );
};

// endregion