// region exports

/**
 * Configuration
 */
export const Config = {
  /**
   * Version of store. Change to clear the current stored data.
   */
  storeVersion: 7,

  /**
   * Local storage key used to save part of redux store.
   */
  storageKey: 'lpbuilder-redux-state',

  /**
   * Padding of each element
   */
  horizontalPadding: 12,

  /**
   * Padding of each element
   */
  verticalPadding: 12,

  /**
   * Padding of content within a module
   */
  contentPadding: 12,

  /**
   * Spacing between content parts
   */
  contentSpacing: 20,

  /**
   * Spacing of text within a container
   */
  textPadding: 40,

  /**
   * Width at desktop view
   */
  desktopWidth: 1284,

  /**
   * Width at mobile view
   */
  mobileWidth: 400,

  /**
   * Size for title texts in module
   */
  titleSize: '2em',

  /**
   * Font weight for title texts
   */
  titleWeight: 600,

  /**
   * Size for big title texts
   */
  bigTitleSize: '2.5em',

  /**
   * Font weight for big title texts
   */
  bigTitleWeight: 900,

  /**
   * Size for title texts used with textual content
   */
  contentTitleSize: '1.75em',

  /**
   * Font weight for content title texts
   */
  contentTitleWeight: 500,

  /**
   * Color to use for image background
   */
  imageBackground: '#808080',

  /**
   * Maximum allowed number of header banners
   */
  maxHeaderBanners: 10,

  /**
   * Gap size to use with properties pane.
   */
  propertiesGapSize: 2,

  /**
   * Number of seconds to auto save after first change.
   */
  autoSaveDelay: 30,

  /**
   * Rest API root
   */
  apiRoot: '/api/v1',

  /**
   * The name used in the header to pass the token
   */
  TokenHeaderKey: 'LPBuilder-token',

  /**
   * Domain for landing pages
   */
  urlDomain: 'https://www.somedomain.de/',

  /**
   * Minimum scale value
   */
  minScale: 25,

  /**
   * Maximum scale value
   */
  maxScale: 200,
};

// endregion
