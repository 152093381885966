// region imports

import * as React from "react";
import {UFRedux} from "../../../UF-react/tools/UFRedux";
import {ReducerActionType} from "../../ReducerActionType";
import {ThunkAction} from "redux-thunk";
import {ApplicationState} from "../../ApplicationState";
import {Action} from "redux";

// endregion

// region actions

export class ConfirmStoreActions {
  /**
   * Shows the confirmation popup.
   *
   * @param aTitle
   *   Title to use
   * @param aContent
   *   Content to use
   * @param aCallback
   *   Callback to call
   * @param aYes
   *   Yes label
   * @param aNo
   *   No label
   */
  static show(
    aTitle: React.ReactNode,
    aContent: React.ReactNode,
    aCallback: (choice: boolean) => void,
    aYes: React.ReactNode = 'Yes',
    aNo: React.ReactNode = 'No'
  ) {
    return UFRedux.reducerAction<ReducerActionType.ShowConfirm>(ReducerActionType.ShowConfirm)({
      title: aTitle,
      content: aContent,
      callback: aCallback,
      yes: aYes,
      no: aNo
    });
  }

  /**
   * Hides the confirmation popup.
   */
  static hide() {
    return UFRedux.reducerAction<ReducerActionType.HideConfirm>(ReducerActionType.HideConfirm)({});
  }

  /**
   * Thunk action to perform a confirmation popup.
   *
   * @param aTitle
   *   Title to use
   * @param aContent
   *   Content to use
   * @param aYes
   *   Yes label (default is 'Yes')
   * @param aNo
   *   No label (default is 'No')
   *
   * @returns A function that expects a dispatch parameter and returns a Promise.
   */
  static popup = (
    aTitle: React.ReactNode, aContent: React.ReactNode, aYes: React.ReactNode = 'Yes', aNo: React.ReactNode = 'No'
  ): ThunkAction<Promise<boolean>, ApplicationState, null, Action<ReducerActionType>> =>
    dispatch => new Promise<boolean>(resolve => {
      dispatch(ConfirmStoreActions.show(
        aTitle,
        aContent,
        value => {
          dispatch(ConfirmStoreActions.hide());
          resolve(value);
        },
        aYes,
        aNo
      ));
    });
}

// endregion