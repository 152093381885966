// region imports

import * as React from 'react';
import {Box, BoxProps} from "@mui/material";
import {LandingPageDropShadow} from "../../../types/LandingPageDropShadow";
import {LandingPageBorder} from "../../../types/LandingPageBorder";

// endregion

// region local types

interface LandingPageInputProps extends Omit<BoxProps, 'border'> {
  hint: string;
  border: LandingPageBorder;
  dropShadow: LandingPageDropShadow;
  background: string;
}

// endregion

// region component

export const LandingPageInput: React.FC<LandingPageInputProps> =
  ({hint, border, dropShadow, background, ...others}) => {
    return (
      <Box
        {...others}
        sx={{
          textAlign: 'left',
          color: '#666666',
          padding: '20px',
          background: background,
          boxShadow: dropShadow.enabled ? '0 3px 6px 0 rgba(0,0,0,0.16)' : 'none',
          borderRadius: border.radius + 'px',
          border: '1px solid #D1D5DB',
          width: '100%',
          fontSize: '16px'
        }}
      >
        {hint}
      </Box>
    );
  }

// endregion