// region imports

import * as React from 'react';
import {Box} from "@mui/material";
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";
import {Module} from "./Module";
import {ModuleBackgroundType} from "../../constants/ModuleBackgroundType";
import {ModuleType} from "../../constants/ModuleType";
import {LandingPageModuleDictionary} from "../../types/LandingPageModuleDictionary";
import {LandingPageModuleTools} from "../../tools/LandingPageModuleTools";

// endregion

// region local types and functions

/**
 * Properties for the module.
 */
interface ModulesContainerProps {
  /**
   * Width of content
   */
  readonly contentWidth: number;

  /**
   * Modules (from application state)
   */
  readonly modules: LandingPageModuleDictionary;
}

/**
 * Maps state to local properties.
 */
function mapsStateToProps(anApplicationState: ApplicationState) {
  return {
    modules: anApplicationState.landingPage.modules
  }
}

// endregion

// region component

/**
 * A container with all modules
 */
export const ModulesContainer = connect(mapsStateToProps)(
  ({contentWidth, modules}: ModulesContainerProps) => {
    let moduleBackground: ModuleBackgroundType = ModuleBackgroundType.Default;
    const sortedModules = LandingPageModuleTools.getSortedList(modules);
    return (
      <Box sx={{flex: '1 1'}}>
        {sortedModules.map((module, index) => {
          if ((index > 0) && (module.type != ModuleType.Button)) {
            moduleBackground = (moduleBackground == ModuleBackgroundType.Default)
              ? ModuleBackgroundType.BackgroundColor
              : ModuleBackgroundType.Default;
          }
          return <Module
            key={module.key}
            moduleKey={module.key}
            moduleBackground={moduleBackground}
            contentWidth={contentWidth}
          />;
        })}
      </Box>
    );
  }
);

// endregion
