// region imports

import {ReducerActionType} from "../ReducerActionType";
import {ViewMode} from "../../constants/ViewMode";
import {UFRedux} from "../../UF-react/tools/UFRedux";
import {EditorMode} from "../../constants/EditorMode";

// endregion

// region exports

export class EditorStoreActions {
  /**
   * Sets the view mode.
   *
   * @param aViewMode
   *   New view mode to use
   */
  static setViewMode(aViewMode: ViewMode) {
    return UFRedux.reducerAction<ReducerActionType.SetViewMode>(ReducerActionType.SetViewMode)({
      viewMode: aViewMode
    });
  }

  /**
   * Sets the view scale.
   *
   * @param aViewScale
   *   New viewing scale to use
   */
  static setViewScale(aViewScale: number) {
    return UFRedux.reducerAction<ReducerActionType.SetViewScale>(ReducerActionType.SetViewScale)({
      viewScale: aViewScale
    });
  }

  /**
   * Sets the view scale.
   *
   * @param aMode
   *   New mode to set
   */
  static setEditorMode(aMode: EditorMode) {
    return UFRedux.reducerAction<ReducerActionType.SetEditorMode>(ReducerActionType.SetEditorMode)({
      mode: aMode
    });
  }
}

// endregion
