// region imports

import * as React from 'react';
import {Typography, TypographyProps} from "@mui/material";

// endregion

// region component

/**
 * {@link PlainText} is a {@link Typography} that uses inherit for variant.
 */
export const PlainText: React.FC<TypographyProps> = (aProps) =>
  <Typography {...aProps} variant="inherit" />

// endregion