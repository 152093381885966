/**
 * The font families. Use {@link StylingTools.getFontFamily} to get the css font family name.
 */
export enum FontFamily {
  Roboto = 1,
  RobotoSlab = 2,
  Verdana = 3,
  Arial = 4,
  OpenSans = 5,
  Montserrat = 6,
}
