// region imports

import * as React from 'react';
import {MenuItem} from "@mui/material";
import {CategoryType} from "../../constants/CategoryType";
import {TT} from "../TT";
import {UFFormSelect} from "../../UF-mui/components/form/UFFormSelect";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

// endregion

// region local types and functions

/**
 * Properties for the component.
 */
interface CategorySelectProps {
  readonly value: CategoryType;
  readonly onChange: (value: CategoryType) => any;
  readonly label?: React.ReactNode;
  readonly disabled?: boolean;
  readonly sx?: SxProps<Theme>;
}

// endregion

// region component

/**
 * {@link CategorySelect} renders a selection control to select a {@link CategoryType}
 */
export const CategorySelect: React.FC<CategorySelectProps> = (
  {
    value,
    label = <TT ttid="category.title">Category</TT>,
    disabled = false,
    onChange,
    sx = {}
  }
) => (
  <UFFormSelect
    label={label}
    value={value}
    onFieldChange={({value}) => onChange(value)}
    disabled={disabled}
    sx={sx}
  >
    <MenuItem value={CategoryType.General}><TT ttid="category-type.general">General</TT></MenuItem>
    <MenuItem value={CategoryType.Teens}><TT ttid="category-type.teens">Teens</TT></MenuItem>
    <MenuItem value={CategoryType.Fetish}><TT ttid="category-type.fetish">Fetish</TT></MenuItem>
    <MenuItem value={CategoryType.MILFs}><TT ttid="category-type.milfs">MILFs</TT></MenuItem>
    <MenuItem value={CategoryType.Lesbians}><TT ttid="category-type.lesbians">Lesbians</TT></MenuItem>
    <MenuItem value={CategoryType.Reality}><TT ttid="category-type.reality">Reality</TT></MenuItem>
  </UFFormSelect>
);

// endregion