// region imports

import * as React from "react";
import {FormControl} from "@mui/material";
import {UFTextField, UFTextFieldProps} from "./UFTextField";

// endregion

// region component

/**
 * {@link UFFormTextField} wraps {@link UFTextField} in a FormControl.
 */
export const UFFormTextField: React.FC<UFTextFieldProps> = (
  ({
     fullWidth = true,
     variant = 'standard',
     sx = {},
     ...other
   }) =>
    <FormControl fullWidth={fullWidth} variant={variant} sx={sx}>
      <UFTextField
        {...other}
      />
    </FormControl>
);

// endregion
