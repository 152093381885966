// region imports

import * as React from 'react';
import {LandingPageColors} from "../../../types/LandingPageColors";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {Box, Stack} from "@mui/material";
import {ModuleBaseProps} from "../../../types/ModuleBaseProps";
import {LandingPageBorder} from "../../../types/LandingPageBorder";
import {LandingPageDropShadow} from "../../../types/LandingPageDropShadow";
import {StylingTools} from "../../../tools/StylingTools";
import {PlainText} from "../parts/PlainText";
import {LandingPageButton} from "../parts/LandingPageButton";
import {ViewMode} from "../../../constants/ViewMode";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Config} from "../../../constants/Config";

// endregion

// region local types and functions

/**
 * Props for the component
 */
interface StarShowsModuleProps extends ModuleBaseProps {
  colors: LandingPageColors;
  border: LandingPageBorder;
  dropShadow: LandingPageDropShadow;
  viewMode: ViewMode;
}

/**
 * Maps store state to component properties.
 */
function mapStatsToProps(anApplicationState: ApplicationState) {
  return {
    colors: anApplicationState.landingPage.colors,
    border: anApplicationState.landingPage.border,
    dropShadow: anApplicationState.landingPage.dropShadow,
    viewMode: anApplicationState.editor.viewMode
  }
}

// endregion

// region local component

interface TimeProps {
  value: string;
  label: string;
  colors: LandingPageColors;
  valueSize: string;
  labelSize: string;
}

const Time: React.FC<TimeProps> =
  ({value, label, colors, valueSize, labelSize}) => (
    <Stack direction="column" spacing={0} color={colors.link} textAlign="center" lineHeight={1}>
      <PlainText fontSize={valueSize} fontWeight={900}>{value}</PlainText>
      <PlainText fontSize={labelSize} marginTop="-6px">{label}</PlainText>
    </Stack>
  );

// endregion

// region component

/**
 * The top bar in the landing page.
 */
export const StarShowsModule = connect(mapStatsToProps)(
  ({colors, border, dropShadow, viewMode, moduleBackground}: StarShowsModuleProps) => {
    const atDesktop = viewMode == ViewMode.Desktop;
    return (
      <Stack
        direction="column"
        position="relative"
        sx={{
          background: StylingTools.getReverseModuleBackground(moduleBackground, colors),
          boxShadow: StylingTools.getBoxShadow(dropShadow),
          borderRadius: border.radius + 'px',
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            background: colors.background,
            color: StylingTools.getTextColor(colors.background),
            height: '80px',
            textAlign: 'center',
            lineHeight: '80px',
            fontSize: '30px',
            fontWeight: 900
          }}
        >
          FunDo StarShows
        </Box>
        <Stack direction="row" spacing={0}>
          <Box
            padding={atDesktop ? '35px 0 0' : '300px 0 0'}
            sx={{
              color: colors.link,
              '&:hover': {
                color: StylingTools.getHoverColor(colors.link)
              }
            }}
          >
            <ChevronLeftIcon fontSize="large"/>
          </Box>
          <Stack
            direction={atDesktop ? 'row' : 'column'}
            flex="1"
            margin={atDesktop ? '20px 0 35px 210px' : '240px 0 0'}
          >
            <Box textAlign={atDesktop ? 'left' : 'center'} lineHeight={1}>
              <PlainText color={colors.link} fontSize="27px" fontWeight={600}>Micaela Schäfer</PlainText>
              <PlainText fontSize="20px"> Mittwoch, 19.01.2022<br/>18:30 bis 19:30</PlainText>
            </Box>
            {
              atDesktop &&
              <Stack direction="row" spacing={0} padding="20px 0 0" flex="1" justifyContent="center" gap="20px">
                <Time
                  colors={colors}
                  value="01"
                  label="Tag"
                  valueSize="74px"
                  labelSize="15px"
                />
                <Time
                  colors={colors}
                  value="03"
                  label="Stunden"
                  valueSize="74px"
                  labelSize="15px"
                />
                <Time
                  colors={colors}
                  value="32"
                  label="Minuten"
                  valueSize="74px"
                  labelSize="15px"
                />
                <Time
                  colors={colors}
                  value="46"
                  label="Sekunden"
                  valueSize="74px"
                  labelSize="15px"
                />
              </Stack>
            }
            {
              !atDesktop &&
              <Stack direction="row" spacing={0} flex="1" justifyContent="center" gap="20px">
                <Time
                  colors={colors}
                  value="01"
                  label="Tag"
                  valueSize="74px"
                  labelSize="15px"
                />
                <Time
                  colors={colors}
                  value="03"
                  label="Stunden"
                  valueSize="74px"
                  labelSize="15px"
                />
              </Stack>
            }
            {
              !atDesktop &&
              <Stack direction="row" spacing={0} justifyContent="center" gap="20px">
                <Time
                  colors={colors}
                  value="32"
                  label="Minuten"
                  valueSize="74px"
                  labelSize="15px"
                />
                <Time
                  colors={colors}
                  value="46"
                  label="Sekunden"
                  valueSize="74px"
                  labelSize="15px"
                />
              </Stack>
            }
            <Stack
              direction="column"
              justifyContent="space-between"
              margin={atDesktop ? '0 0 0 12px' : '20px 0'}
              width={atDesktop ? '200px' : '100%'}
              gap={atDesktop ? '0' : '10px'}
              lineHeight={1}
            >
              <PlainText fontSize="20px" fontWeight={900} textAlign={atDesktop ? 'left' : 'center'}>
                Bis zur nächsten Starshow
              </PlainText>
              <LandingPageButton
                caption="Zum profil"
                border={border}
                colors={colors}
                viewMode={viewMode}
                width="100%"
              />
            </Stack>
          </Stack>
          <Box
            padding={atDesktop ? '35px 0 0' : '300px 0 0'}
            sx={{
              color: colors.link,
              '&:hover': {
                color: StylingTools.getHoverColor(colors.link)
              }
            }}
          >
            <ChevronRightIcon fontSize="large"/>
          </Box>
          <Box
            sx={
              atDesktop
                ? {
                  position: 'absolute',
                  left: '30px',
                  top: '30px',
                  bottom: '15px',
                }
                : {
                  position: 'absolute',
                  width: '230px',
                  height: '230px',
                  left: 'calc(50% - 115px)',
                  top: '70px'
                }
            }
          >
            <img
              style={{
                visibility: 'visible',
                border: '4px solid ' + StylingTools.getReverseModuleBackground(moduleBackground, colors),
                borderRadius: '50%',
                height: '100%',
                width: 'auto',
                display: 'block',
                overflow: 'hidden',
                background: Config.imageBackground
              }}
              src="pixel.png"
            />
          </Box>
        </Stack>


      </Stack>
    )
  }
);

// endregion

