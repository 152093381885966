/**
 * Resuls from server calls.
 */
export enum ServerResult {
  /**
   * No server result.
   */
  None,

  /**
   * Server call was successful.
   */
  Success,

  /**
   * Login credentials are invalid.
   */
  AuthenticationFailed,

  /**
   * The code (used with urls) is not available.
   */
  CodeNotAvailable,

  /**
   * The authentication token has expired (403).
   */
  TokenExpired,

  /**
   * The server return an error (400).
   */
  ServerError
}