// region imports

import * as React from 'react';
import {MenuItem, Stack, Typography} from "@mui/material";
import {TT} from "../TT";
import {UFFormSelect} from "../../UF-mui/components/form/UFFormSelect";
import {useEffect} from "react";
import {LandingPageTemplate} from "../../types/LandingPageTemplate";
import {NetworkController} from "../../controllers/NetworkController";
import {UFBusy} from "../../UF-mui/components/wrappers/UFBusy";
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";

// endregion

// region local types and functions

interface TemplateSelectProps {
  readonly value: string;
  readonly onChange: (value: string) => any;
  readonly label?: React.ReactNode;
  readonly disabled?: boolean;
  readonly networkBusy: boolean;
  readonly templates: LandingPageTemplate[];
  readonly selectedTemplate: string;
}

function mapStateToProps(anApplicationState: ApplicationState) {
  return {
    networkBusy: anApplicationState.network.networkBusy,
    templates: anApplicationState.user.templates,
    selectedTemplate: anApplicationState.user.selectedTemplate
  };
}

// endregion

// region component

/**
 * {@link TemplateSelect} renders a selection control to select a template.
 */
export const TemplateSelect = connect(mapStateToProps)(
  ({
    value,
    label = <TT ttid="select-template.template-label">Template</TT>,
    disabled = false,
    onChange,
    networkBusy,
    templates,
    selectedTemplate
  }: TemplateSelectProps
) => {
  useEffect(
    () => {
      // noinspection JSIgnoredPromiseFromCall
      NetworkController.instance.refreshTemplates();
    },
    []
  );
  const filteredTemplates = templates.filter(template => template.key != selectedTemplate);
  return (
    <UFBusy busy={networkBusy} sx={{width: '100%'}}>
      <UFFormSelect
        value={value}
        onFieldChange={({value}) => onChange(value)}
        label={label}
        sx={{
          height: '55px'
        }}
        disabled={disabled || networkBusy}
      >
        {filteredTemplates.map(template =>
          <MenuItem value={template.key} key={template.key}>
            <Stack direction="column">
              <Typography fontStyle={template.available ? 'normal' : 'italic'}>
                {template.title}&nbsp;
                {template.available ? '' : <TT ttid="select-template.draft">(draft)</TT>}
              </Typography>
              <Typography variant="caption">
                <TT ttid="select-template.created-by" map={{'$name$': template.createdBy}}>Created by $name$</TT>
              </Typography>
            </Stack>
          </MenuItem>
        )}
      </UFFormSelect>
    </UFBusy>
  );
});

// endregion