// region imports

import * as React from 'react';
import {AppBar, Box, Toolbar, Typography} from "@mui/material";
import {LanguageMenu} from "./LanguageMenu";
import {UserMenu} from "./UserMenu";
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";
import {UserLocation} from "../../constants/UserLocation";
import {TestStoreActions} from "../../store/dialogs/test/TestStoreActions";
import {TT} from "../TT";
import {Campaign} from "../../types/Campaign";
import {LandingPageConfiguration} from "../../types/LandingPageConfiguration";
import {LandingPageTemplate} from "../../types/LandingPageTemplate";
import {LabeledText} from "./LabeledText";

// endregion

// region local types and functions

/**
 * Properties for component
 */
interface ApplicationTopBarProps {
  readonly authenticated: boolean;
  readonly administrator: boolean;
  readonly location: UserLocation;
  readonly showTest: typeof TestStoreActions.show;
  readonly selectedCampaign?: Campaign;
  readonly configuration: LandingPageConfiguration;
  readonly template: LandingPageTemplate;
}

/**
 * Maps state to local props
 */
function mapStateToProps(anApplicationState: ApplicationState) {
  return {
    authenticated: anApplicationState.user.authenticated,
    administrator: anApplicationState.user.administrator,
    location: anApplicationState.user.location,
    selectedCampaign: anApplicationState.user.campaigns.find(campaign => campaign.key == anApplicationState.user.selectedCampaign),
    configuration: anApplicationState.landingPage.configuration,
    template: anApplicationState.landingPage.template
  }
}

// endregion

// region component

/**
 * {@link ApplicationTopBar} is the applications top bar showing various menu and other controls.
 */
export const ApplicationTopBar = connect(mapStateToProps, {showTest: TestStoreActions.show})(
  (
    {
      authenticated, administrator, location, showTest, selectedCampaign, configuration, template
    }: ApplicationTopBarProps
  ) => {
    const showCampaign = authenticated && !administrator
      && [UserLocation.CreateLandingPage, UserLocation.LoadingPage, UserLocation.EditLandingPage].includes(location);
    const showLandingPage = authenticated && !administrator
      && [UserLocation.LoadingPage, UserLocation.EditLandingPage].includes(location);
    const showTemplate = authenticated && administrator
      && [UserLocation.LoadingPage, UserLocation.EditLandingPage].includes(location);
    return (
      <AppBar position="relative" elevation={0}>
        <Toolbar variant="dense" sx={{gap: 2}}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              textShadow: '1px 1px 0px rgba(0,0,0,0.5)',
              fontWeight: 400
            }}
            whiteSpace="nowrap"
          >
            <strong>L</strong><small>anding</small> <strong>P</strong><small>age</small> <strong>Builder</strong>
          </Typography>
          {
            showCampaign &&
            <LabeledText
              label={<TT ttid="top-bar.campaign">campaign:</TT>}
              text={selectedCampaign!.name}
            />
          }
          {
            showLandingPage &&
            <LabeledText
              label={<TT ttid="top-bar.landing-page">landing page:</TT>}
              text={configuration.title || <TT>(empty title)</TT>}
            />
          }
          {
            showTemplate &&
            <LabeledText
              label={<TT ttid="top-bar.template">template:</TT>}
              text={template.title}
            />
          }
          {
            //<Button size="small" variant="outlined" onClick={showTest} color="inherit">Test</Button>
          }
          <Box flex={1}>&nbsp;</Box>
          {authenticated && <UserMenu/>}
          <LanguageMenu/>
        </Toolbar>
      </AppBar>
    )
  }
);

// endregion