// region imports

import * as React from 'react';
import {HeaderBannerItem} from "../../../types/HeaderBannerItem";
import {ViewMode} from "../../../constants/ViewMode";
import {Box, Stack} from "@mui/material";
import {LandingPageImage} from "./LandingPageImage";
import {StylingTools} from "../../../tools/StylingTools";
import {ApplicationState} from "../../../store/ApplicationState";
import {LandingPageModule} from "../../../types/LandingPageModule";
import {connect} from 'react-redux';
import {UFText} from "../../../UF/tools/UFText";

// endregion

// region local types and functions

interface HeaderBannerProps {
  /**
   * Key of header banner item
   */
  itemKey: string;

  /**
   * Key of module the header banners located in
   */
  moduleKey: string;

  /**
   * True to show, false to set visibility state to hidden
   */
  visible: boolean;

  /**
   * Current viewing mode
   */
  viewMode: ViewMode;

  /**
   * Set by function
   */
  item: HeaderBannerItem;

  /**
   * Set by function
   */
  module: LandingPageModule;
}

function mapStateToProps(anApplicationState: ApplicationState, aProps: Partial<HeaderBannerProps>) {
  const module = anApplicationState.landingPage.modules[aProps.moduleKey!];
  return {
    module: module,
    item: module.headerBannerItems[aProps.itemKey!],
    viewMode: anApplicationState.editor.viewMode
  }
}

// endregion

// region component

export const HeaderBanner = connect(mapStateToProps)(
  ({item, visible, viewMode, module}: HeaderBannerProps) => (
    <Stack direction="column" visibility={visible ? 'visible' : 'hidden'} gridArea="banner">
      <Box
        sx={{
          height: viewMode == ViewMode.Desktop ? '628px' : '248px'
        }}
      >
        <LandingPageImage/>
      </Box>
      {
        (UFText.convertToPlain(item.text).length > 0) &&
        <Box
          sx={{
            textAlign: StylingTools.getTextAlign(module.textAlign)
          }}
        >
          {StylingTools.parseHtml(item.text)}
        </Box>
      }
    </Stack>
  )
);

// endregion