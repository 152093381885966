// region imports

import {Box, Stack} from '@mui/material';
import * as React from 'react';
import {LandingPageTopBar} from "../../types/LandingPageTopBar";
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";
import {LandingPageColors} from "../../types/LandingPageColors";
import {ViewMode} from "../../constants/ViewMode";
import {ModuleContent} from "./ModuleContent";
import {StylingTools} from "../../tools/StylingTools";

// endregion

// region local types and functions

/**
 * Properties for top bar
 */
interface TopBarProps {
  readonly contentWidth: number,
  readonly viewMode: ViewMode,
  readonly colors: LandingPageColors,
  readonly topBar: LandingPageTopBar,
  readonly selectedModule: string
}

/**
 * Maps store state to component properties.
 */
function mapStatsToProps(anApplicationState: ApplicationState) {
  return {
    colors: anApplicationState.landingPage.colors,
    topBar: anApplicationState.landingPage.topBar,
    viewMode: anApplicationState.editor.viewMode,
    selectedModule: anApplicationState.landingPage.selectedModule
  }
}

// endregion

// region component

/**
 * The top bar in the landing page.
 */
export const TopBar = connect(mapStatsToProps)(({contentWidth, viewMode, topBar, colors, selectedModule}: TopBarProps) => {
  return (
    <ModuleContent
      contentWidth={contentWidth}
      backgroundColor={colors.background}
      selected={!selectedModule.length}
      moduleKey=""
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          color: colors.text,
          maxHeight: '200px',
          overflow: 'hidden',
          '& *': {
            margin: 0,
            padding: 0
          }
        }}>
        <Box sx={{fontSize: topBar.leftFontSize + 'px'}}>
          {StylingTools.parseHtml(topBar.leftText)}
        </Box>
        {
          (viewMode != ViewMode.Mobile) &&
          <Box sx={{fontSize: topBar.rightFontSize + 'px', flex: '1 1', textAlign: 'right'}}>
            {StylingTools.parseHtml(topBar.rightText)}
          </Box>
        }
      </Stack>
    </ModuleContent>
  )
});

// endregion
