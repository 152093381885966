// region imports

import * as React from 'react';
import {ModuleType} from "../../constants/ModuleType";
import {Dialog, DialogContent, DialogTitle, Stack} from "@mui/material";
import {TT} from "../TT";
import {UFStyledDialogActions} from "../../UF-mui/components/dialogs/UFStyledDialogActions";
import CloseIcon from '@mui/icons-material/Close';
import {DialogButton} from "../controls/DialogButton";
import {ModuleButton} from "./parts/ModuleButton";
import {MainController} from "../../controllers/MainController";

// endregion

// region local types and functions

/**
 * Properties for component
 */
interface NewModuleDialogProps {
  readonly onClose: () => void;
}

// endregion

// region component

/**
 * {@link NewModuleDialog} shows the module types for the user to select one.
 */
export const NewModuleDialog: React.FC<NewModuleDialogProps> = ({onClose}) => {
  const select = (type: ModuleType) => {
    MainController.instance.addModule(type);
    onClose();
  };
  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <TT ttid="new-module.title">New module</TT>
      </DialogTitle>
      <DialogContent>
        <Stack
          gap={2}
          flexWrap="wrap"
          width="100%"
          direction="row"
          justifyContent="center"
        >
          <ModuleButton
            onClick={() => select(ModuleType.HeaderBanner)}
            caption={<TT ttid="module.header-banner">Header banner</TT>}
          />
          <ModuleButton
            onClick={() => select(ModuleType.Banner)}
            caption={<TT ttid="module.banner">Banner</TT>}
          />
          <ModuleButton
            onClick={() => select(ModuleType.SingleImageAndText)}
            caption={<TT ttid="module.single-image-text">Single image and text</TT>}
          />
          <ModuleButton
            onClick={() => select(ModuleType.TwoImagesAndTexts)}
            caption={<TT ttid="module.two-images-texts">Two images and texts</TT>}
          />
          <ModuleButton
            onClick={() => select(ModuleType.Button)}
            caption={<TT ttid="module.button">Call to action button</TT>}
          />
          <ModuleButton
            onClick={() => select(ModuleType.Cams)} caption={<TT ttid="module.cams">Cams</TT>}
          />
          <ModuleButton
            onClick={() => select(ModuleType.Videos)}
            caption={<TT ttid="module.videos">Videos</TT>}
          />
          <ModuleButton
            onClick={() => select(ModuleType.StarShows)}
            caption={<TT ttid="module.star-shows">Star shows</TT>}
          />
          <ModuleButton
            onClick={() => select(ModuleType.UserRegistration)}
            caption={<TT ttid="module.user-registration">User registration</TT>}
          />
          <ModuleButton
            onClick={() => select(ModuleType.Voucher)}
            caption={<TT ttid="module.voucher">Voucher</TT>}
          />
        </Stack>
      </DialogContent>
      <UFStyledDialogActions>
        <DialogButton
          color="secondary"
          onClick={onClose}
          startIcon={<CloseIcon/>}
        >
          <TT>Cancel</TT>
        </DialogButton>
      </UFStyledDialogActions>
    </Dialog>
  );
};

// endregion
