// region imports

import * as React from 'react';
import {LandingPageColors} from "../../../types/LandingPageColors";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {Stack} from "@mui/material";
import {ModuleBaseProps} from "../../../types/ModuleBaseProps";
import {Config} from "../../../constants/Config";
import {ImageWithName} from "../parts/ImageWithName";
import {ViewMode} from "../../../constants/ViewMode";
import {TitleWithMoreLink} from "../parts/TitleWithMoreLink";
import {LandingPageBorder} from "../../../types/LandingPageBorder";
import {LandingPageDropShadow} from "../../../types/LandingPageDropShadow";

// endregion

// region local types and functions

/**
 * Props for the component
 */
interface VideosModuleProps extends ModuleBaseProps {
  colors: LandingPageColors;
  viewMode: ViewMode;
  border: LandingPageBorder;
  dropShadow: LandingPageDropShadow;
}

/**
 * Maps store state to component properties.
 */
function mapStatsToProps(anApplicationState: ApplicationState) {
  return {
    colors: anApplicationState.landingPage.colors,
    viewMode: anApplicationState.editor.viewMode,
    border: anApplicationState.landingPage.border,
    dropShadow: anApplicationState.landingPage.dropShadow
  }
}

// endregion

// region component

/**
 * The top bar in the landing page.
 */
export const VideosModule = connect(mapStatsToProps)(
  ({module, moduleBackground, colors, viewMode, border, dropShadow}: VideosModuleProps) => {
    const atDesktop = viewMode == ViewMode.Desktop;
    return (
      <Stack
        textAlign="left"
        gap={Config.contentPadding + 'px'}
      >
        <TitleWithMoreLink title={module.title} viewMode={viewMode} colors={colors} />
        <Stack
          gap={Config.contentSpacing + 'px'}
          direction="row"
          flexWrap="wrap"
        >
          {
            Array(4 * (module.rowCount || 1)).fill(0).map(
              (value, index) =>
                <ImageWithName
                  key={index.toString()}
                  imageHeight={atDesktop ? '450px' : '310px'}
                  imageWidth={atDesktop ? '22%' : '45%'}
                  name='Firstname Lastname'
                  moduleBackground={moduleBackground}
                  border={border}
                  colors={colors}
                  dropShadow={dropShadow}
                />
            )
          }
        </Stack>
      </Stack>
    )
  }
);

// endregion

