// region imports

import * as React from 'react';
import {MenuItem} from "@mui/material";
import {ClassificationType} from "../../constants/ClassificationType";
import {TT} from "../TT";
import {UFFormSelect} from "../../UF-mui/components/form/UFFormSelect";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

// endregion

// region local types and functions

/**
 * Properties for the component.
 */
interface ClassificationSelectProps {
  readonly value: ClassificationType;
  readonly onChange: (value: ClassificationType) => any;
  readonly label?: React.ReactNode;
  readonly disabled?: boolean;
  readonly sx?: SxProps<Theme>;
}

// endregion

// region component

/**
 * {@link ClassificationSelect} renders a selection control to select a {@link ClassificationType}
 */
export const ClassificationSelect: React.FC<ClassificationSelectProps> = (
  {
    value,
    label = <TT ttid="classification.title">Classification</TT>,
    disabled = false,
    onChange,
    sx={}
  }
) => (
  <UFFormSelect
    label={label}
    value={value}
    onFieldChange={({value}) => onChange(value)}
    disabled={disabled}
    sx={sx}
  >
    <MenuItem value={ClassificationType.VerySoft}><TT ttid="classification-type.general">Very soft</TT></MenuItem>
    <MenuItem value={ClassificationType.Soft}><TT ttid="classification-type.teens">Soft</TT></MenuItem>
    <MenuItem value={ClassificationType.HardCore}><TT ttid="classification-type.fetish">Hardcore</TT></MenuItem>
  </UFFormSelect>
);

// endregion