// region imports

import * as React from 'react';
import {
  Alert,
  Box,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton, Stack,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import {TT} from "../TT";
import {UFStyledDialogActions} from "../../UF-mui/components/dialogs/UFStyledDialogActions";
import {DialogButton} from "../controls/DialogButton";
import CloseIcon from '@mui/icons-material/Close';
import {LandingPageImage} from "../../types/LandingPageImage";
import Dropzone from 'react-dropzone';
import {UFCollapsableAlert} from "../../UF-mui/components/controls/UFCollapsableAlert";

// endregion

// region local types and functions

interface SelectImageDialogProps {
  readonly onClose: () => any;
  readonly onSelect: (image: LandingPageImage) => any;
}

interface SelectImageDialogState {
  tab: number;
  files: any[];
  image: string;
  fileError: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;
  return (
    <Box
      sx={{
        visibility: value == index ? 'visible' : 'hidden'
      }}
    >
      <React.Fragment>
        {children}
      </React.Fragment>
    </Box>
  );
}

// endregion

// region component

export class SelectImageDialog extends React.Component<SelectImageDialogProps, SelectImageDialogState> {
  constructor(aProps: SelectImageDialogProps) {
    super(aProps);
    this.state = {
      tab: 0,
      files: [],
      image: '',
      fileError: false
    }
  }

  // region event handlers

  private handleChange = (aTab: number) => {
    this.setState({
      tab: aTab
    });
  }

  private handleFiles = (files: any[]) => {
    if (files.length > 0) {
      this.setState({
        files: files,
        image: URL.createObjectURL(files[0]),
        fileError: false
      });
    }
  }

  private handleRejected = () => {
    this.setState({
      fileError: true
    });
  }

  // endregion

  // region render methods

  private renderImages() {
    return null;
  }

  private renderAlert() {
    return (
      <Box sx={{width: '100%'}}>
        <Collapse in={this.state.fileError}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => this.setState({fileError: false})}
              >
                <CloseIcon fontSize="inherit"/>
              </IconButton>
            }
            sx={{mb: 2}}
          >
            <TT ttid="image-dialog.file-error">The file is not supported or is larger then 3MB.</TT>
          </Alert>
        </Collapse>
      </Box>
    );
  }

  private renderUpload() {
    return (
      <Stack
        direction="column"
        gap={1}
        height="350px"
        marginTop={2}
      >
        <UFCollapsableAlert
          message={<TT ttid="image-dialog.file-error">The file is not supported or is larger then 3MB.</TT>}
          severity="error"
          onClose={() => this.setState({fileError: false})}
          visible={this.state.fileError}
          time={4}
        />
        <Dropzone
          onDrop={this.handleFiles}
          onDropRejected={this.handleRejected}
          maxFiles={1}
          maxSize={3 * 1024 * 1024}
          minSize={10}
          accept=".jpg,.jpeg,image/png,image/gif,image/svg+xml"

        >
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps({style: {flex: 1, overflow: 'hidden'}})}>
              <input {...getInputProps()} />
              <Box
                sx={{
                  border: '2px dashed #ccc',
                  borderRadius: '12px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  cursor: 'pointer',
                  height: '100%'
                }}
              >
                {
                  (this.state.image.length <= 0) &&
                  <Typography>
                    <TT ttid="image-dialog.drop-text" html={true}>
                      Drag 'n' drop an image file here, or click to select an image.<br/>Maximum allowed file size is 3MB.
                    </TT>
                  </Typography>
                }
                {
                  (this.state.image.length > 0) &&
                  <img
                    style={{
                      width: 'auto',
                      height: 'auto',
                      objectFit: 'contain',
                      maxWidth: '100%',
                      maxHeight: '100%',
                      padding: '1.5rem'
                    }}
                    src={this.state.image}
                    alt=""
                  />
                }
              </Box>
            </div>
          )}
        </Dropzone>
      </Stack>
    )
  }

  private renderBanners() {
    return null;
  }

  // endregion

  // region react callbacks

  render() {
    const {onClose} = this.props;
    return (
      <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle>
          <TT ttid="image-dialog.title">Select image</TT>
        </DialogTitle>
        <DialogContent>
          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs
              value={this.state.tab}
              onChange={(event, value) => this.handleChange(value)}
              variant="fullWidth"
            >
              <Tab label={<TT>Images</TT>}/>
              <Tab label={<TT>Upload</TT>}/>
              <Tab label={<TT>Banners</TT>} disabled={true}/>
            </Tabs>
          </Box>
          <TabPanel value={this.state.tab} index={0}>
            {this.renderImages()}
          </TabPanel>
          <TabPanel value={this.state.tab} index={1}>
            {this.renderUpload()}
          </TabPanel>
          <TabPanel value={this.state.tab} index={2}>
            {this.renderBanners()}
          </TabPanel>
        </DialogContent>
        <UFStyledDialogActions>
          <DialogButton
            color="secondary"
            onClick={onClose}
            startIcon={<CloseIcon/>}
          >
            <TT>Cancel</TT>
          </DialogButton>
        </UFStyledDialogActions>
      </Dialog>
    );
  }

  // endregion
}

// endregion