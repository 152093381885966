// region imports

import {CredentialsState} from "./CredentialsState";
import {CredentialsStoreActions} from "./CredentialsStoreActions";
import {ReducerActionType} from "../ReducerActionType";

// endregion

// region local types

const initialState : CredentialsState = {
  token: '',
}

type CredentialsAction = ReturnType<typeof CredentialsStoreActions.setToken>;

// endregion

// region exports

export function credentialsReducer(aState: CredentialsState = initialState, anAction: CredentialsAction): CredentialsState {
  switch(anAction.type) {
    case ReducerActionType.SetToken:
      return {
        ...aState,
        token: anAction.token
      };
    default:
      return aState;
  }
}

// endreigon