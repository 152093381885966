// region imports

import {ReducerActionType} from "../ReducerActionType";
import {UFRedux} from "../../UF-react/tools/UFRedux";

// endregion

// region exports

export class MainStoreActions {
  /**
   * Sets the state to initialized.
   */
  static setInitialized() {
    return UFRedux.reducerAction<ReducerActionType.SetInitialized>(ReducerActionType.SetInitialized)({});
  }
}


// endregion
