// region imports

import {ConfirmState} from "./ConfirmState";
import {ReducerActionType} from "../../ReducerActionType";
import {TT} from "../../../components/TT";
import {ConfirmStoreActions} from "./ConfirmStoreActions";

// endregion

// region local variables

/**
 * Initial system store state
 */
const initialState: ConfirmState = {
  show: false,
  title: '',
  content: '',
  yes: <TT>Yes</TT>,
  no: <TT>No</TT>,
  callback: () => {}
};

// endregion

// region local types

/**
 * All confirm action models.
 */
type ConfirmAction = ReturnType<typeof ConfirmStoreActions.show> | ReturnType<typeof ConfirmStoreActions.hide>;

// endregion

// region exports

/**
 * Reduces the confirmation store to a new state.
 *
 * @param aState
 *   Current state
 * @param anAction
 *   Action to apply to the store
 *
 * @return new state
 */
export function confirmReducer(aState: ConfirmState = initialState, anAction: ConfirmAction): ConfirmState {
  switch (anAction.type) {
    case ReducerActionType.ShowConfirm:
      return {
        show: true,
        title: anAction.title,
        content: anAction.content,
        yes: anAction.yes,
        no: anAction.no,
        callback: anAction.callback
      };
    case ReducerActionType.HideConfirm:
      return {
        ...aState,
        show: false
      };
    default:
      return aState;
  }
}

// endregion