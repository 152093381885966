// region imports

import * as React from 'react';
import {Box, Stack} from "@mui/material";
import {PlainText} from "./PlainText";
import {Config} from "../../../constants/Config";
import {ViewMode} from "../../../constants/ViewMode";
import {LandingPageColors} from "../../../types/LandingPageColors";
import {StylingTools} from "../../../tools/StylingTools";

// endregion

// region local types

interface TitleWithMoreLinkProps {
  title: string;
  viewMode: ViewMode;
  colors: LandingPageColors;
}

// endregion

// region component

/**
 * Shows a title with more link (depending on the view mode).
 */
export const TitleWithMoreLink: React.FC<TitleWithMoreLinkProps> =
  ({title, viewMode, colors}) => {
    const atDesktop = viewMode == ViewMode.Desktop;
    if (!title && !atDesktop) {
      return null;
    }
    return (
      <Stack direction="row" alignItems="flex-end">
        {title && <PlainText fontSize={Config.titleSize} flex="1 1" fontWeight={900}>{title}</PlainText>}
        {!title && <Box flex="1 1" />}
        {
          atDesktop &&
          <PlainText
            color={colors.link}
            sx={{
              '&:hover': {
                color: StylingTools.getHoverColor(colors.link),
                textDecoration: 'underline'
              }
            }}
          >
            mehr
          </PlainText>}
      </Stack>
    );
  };