// region imports

import * as React from 'react';
import {Box} from "@mui/material";
import {LandingPage} from "./LandingPage";
import {ViewMode} from "../../constants/ViewMode";
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";
import {Config} from "../../constants/Config";
import {useEffect, useState} from "react";
import {StylingTools} from "../../tools/StylingTools";

// endregion

// region local types and functions

/**
 * Properties for component.
 */
interface LandingPageContainerProps {
  viewScale: number;
  viewMode: ViewMode;
}

/**
 * Maps the application state to the component properties.
 *
 * @param {ApplicationState} state
 * @returns {{viewMode: ViewMode}}
 */
const mapStateToProps = (state: ApplicationState) => {
  return {
    viewScale: state.editor.viewScale,
    viewMode: state.editor.viewMode
  };
};

// endregion


// region component

/**
 * {@link LandingPageContainer} shows the landing page. It handles the scaling the landing page adjusting its own
 * width and height.
 */
export const LandingPageContainer = connect(mapStateToProps)(
  ({viewScale, viewMode}: LandingPageContainerProps) => {
    const [resizeCount, setResizeCount] = useState(0);
    const increaseResize = () => setResizeCount(resizeCount + 1);
    useEffect(() => {
      window.addEventListener('resize', increaseResize);
      return () => window.removeEventListener('resize', increaseResize);
    });
    const width = (viewMode == ViewMode.Desktop ? Config.desktopWidth : Config.mobileWidth);
    return (
      <Box sx={{
        transform: 'scale(' + viewScale / 100 + ')',
        transformOrigin: 'top left',
        display: 'flex',
        width: 'max(' + (100 * 100 / viewScale) + '%' + ', ' + width + 'px)',
        minHeight: (100 * 100 / viewScale) + '%',
        // needed to hide scrollbar in parent when scaled area fits within parent but 100% scale is larger
        // see also https://stackoverflow.com/a/49019400/968451
        marginBottom: -StylingTools.getLandingPageViewHeight() * 100 / viewScale + '%',
      }}>
        <LandingPage contentWidth={width}/>
      </Box>
    )
  }
);

// endregion