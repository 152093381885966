// region imports

import * as React from 'react';
import {useState} from 'react';
import {Button} from "@mui/material";
import {TT} from "../TT";
import {UFFormTextField} from "../../UF-mui/components/form/UFFormTextField";
import LoginIcon from '@mui/icons-material/Login';
import {DialogPage} from "./DialogPage";
import {UFFormSwitch} from "../../UF-mui/components/form/UFFormSwitch";
import {MainController} from "../../controllers/MainController";
import {UFBusy} from "../../UF-mui/components/wrappers/UFBusy";
import {UFCollapsableAlert} from "../../UF-mui/components/controls/UFCollapsableAlert";

// endregion

// region component

/**
 * {@link LoginPage} is the main page.
 */
export const LoginPage: React.FC = () => {
  const [administrator, setAdministrator] = useState(false);
  const [busy, setBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [email, setEmail] = useState('(prefilled for testing)');
  const [password, setPassword] = useState('(prefilled for testing)');
  return (
    <DialogPage title={<TT ttid="login.title">Login</TT>} width="600px">
      <UFCollapsableAlert
        message={<TT ttid="login.invalid">Invalid or unknown email address and/or password.</TT>}
        severity="error"
        onClose={() => setErrorMessage(false)}
        visible={errorMessage}
        time={4}
      />
      <UFFormTextField
        floatingLabel={true}
        label={<TT ttid="login.email-label">Email</TT>}
        value={email}
        onFieldChange={({value}) => setEmail(value)}
        autoFocus={true}
      />
      <UFFormTextField
        floatingLabel={true}
        label={<TT ttid="login.password-label">Password</TT>}
        type="password"
        value={password}
        onFieldChange={({value}) => setPassword(value)}
      />
      <UFFormSwitch
        label="User is administrator (testing)"
        checked={administrator}
        onFieldChange={({value}) => setAdministrator(value)}
      />
      <UFBusy busy={busy} sx={{marginTop: 4}}>
        <Button
          disabled={!email.length || !password.length || busy}
          variant="contained"
          startIcon={<LoginIcon/>}
          onClick={async () => {
            setBusy(true);
            const result = await MainController.instance.login('', '', administrator);
            setErrorMessage(!result);
            setBusy(false);
            if (result) {
              MainController.instance.finishLocation();
            } else {
              setPassword('(prefilled for testing)');
            }
          }}
        >
          <TT ttid="login.login-caption">Login</TT>
        </Button>
      </UFBusy>
    </DialogPage>
  );
};

// endregion
