/**
 * Dialogs used within the application. Each dialog will invoke an action on {@link MainController}
 */
export enum ApplicationDialogType {
  None,
  ChangeCode,
  DeleteTemplate,
  NewModule,
  SelectCampaign,
  SelectTargetCampaign,
  SelectTemplate
}
