// region imports

import {LandingPageState} from "./LandingPageState";
import {CategoryType} from "../../constants/CategoryType";
import {FontFamily} from "../../constants/FontFamily";
import {TopBarLeftType} from "../../constants/TopBarLeftType";
import {TopBarRightType} from "../../constants/TopBarRightType";
import {ReducerActionType} from "../ReducerActionType";
import {UFSorted} from "../../UF/tools/UFSorted";
import {LandingPageModule} from "../../types/LandingPageModule";
import {LandingPageStoreActions} from "./LandingPageStoreActions";
import {ClassificationType} from "../../constants/ClassificationType";

// endregion

// region local types

/**
 * Initial state values.
 */
const initialState : LandingPageState = {
  dirty: false,
  configuration: {
    title: '',
    urlCode: '',
    initialCategory: CategoryType.General,
    initialClassification: ClassificationType.HardCore,
    description: ''
  },
  border: {
    radius: 0
  },
  colors: {
    background: '#640163',
    text: '#ffffff',
    link: '#640163',
    buttonBackground: '#640163',
    buttonText: '#ffffff'
  },
  dropShadow: {
    enabled: true,
    left: 0,
    top: 0,
    size: 2,
    blurSize: 6,
    color: 'rgba(0,0,0,0.25)'
  },
  font: {
    family: FontFamily.Roboto,
    size: 20
  },
  topBar: {
    leftType: TopBarLeftType.None,
    leftImage: '',
    leftText: '',
    leftFontSize: 18,
    rightType: TopBarRightType.None,
    rightImage: '',
    rightText: '',
    rightFontSize: 18,
    rightBanner: ''
  },
  template: {
    key: '',
    title: '',
    description: '',
    image: '',
    available: true,
    createdBy: ''
  },
  modules: {},
  selectedModule: ''
}

/**
 * Combination of all landing page actions.
 */
type LandingPageAction = ReturnType<typeof LandingPageStoreActions.reset> | ReturnType<typeof LandingPageStoreActions.clearDirty>
  | ReturnType<typeof LandingPageStoreActions.addModule> | ReturnType<typeof LandingPageStoreActions.deleteModule>
  | ReturnType<typeof LandingPageStoreActions.setCode> | ReturnType<typeof LandingPageStoreActions.addHeaderBanner>
  | ReturnType<typeof LandingPageStoreActions.deleteHeaderBanner> | ReturnType<typeof LandingPageStoreActions.swapHeaderBanner>
  | ReturnType<typeof LandingPageStoreActions.setBorder> | ReturnType<typeof LandingPageStoreActions.setCode>
  | ReturnType<typeof LandingPageStoreActions.setFont> | ReturnType<typeof LandingPageStoreActions.setColors>
  | ReturnType<typeof LandingPageStoreActions.setConfiguration> | ReturnType<typeof LandingPageStoreActions.setDropShadow>
  | ReturnType<typeof LandingPageStoreActions.setSelectedHeaderBanner> | ReturnType<typeof LandingPageStoreActions.setSelectedModule>
  | ReturnType<typeof LandingPageStoreActions.setTopBar> | ReturnType<typeof LandingPageStoreActions.swapModule>
  | ReturnType<typeof LandingPageStoreActions.updateModule> | ReturnType<typeof LandingPageStoreActions.updateHeaderBanner>
  | ReturnType<typeof LandingPageStoreActions.setTemplate>
  ;

// endregion

// region exports

/**
 * Updates to new state.
 */
export function landingPageReducer(aState: LandingPageState = initialState, anActionData: LandingPageAction): LandingPageState {
  let module: LandingPageModule;
  switch(anActionData.type) {
    case ReducerActionType.SetBorder:
      return {
        ...aState,
        dirty: true,
        border: anActionData.border
      };
    case ReducerActionType.SetColors:
      return {
        ...aState,
        dirty: true,
        colors: anActionData.colors
      };
    case ReducerActionType.SetConfiguration:
      return {
        ...aState,
        dirty: true,
        configuration: anActionData.configuration
      };
    case ReducerActionType.SetDropShadow:
      return {
        ...aState,
        dirty: true,
        dropShadow: anActionData.dropShadow
      };
    case ReducerActionType.SetFont:
      return {
        ...aState,
        dirty: true,
        font: anActionData.font
      };
    case ReducerActionType.SetTopBar:
      return {
        ...aState,
        dirty: true,
        topBar: anActionData.topBar
      };
    case ReducerActionType.SetCode:
      return {
        ...aState,
        dirty: true,
        configuration: {
          ...aState.configuration,
          urlCode: anActionData.code
        }
      };
    case ReducerActionType.Reset:
      return initialState;
    case ReducerActionType.ClearDirty:
      return {
        ...aState,
        dirty: false
      };
    case ReducerActionType.AddModule:
      anActionData.module.sortOrder = UFSorted.getMax(aState.modules) + 1;
      return {
        ...aState,
        dirty: true,
        selectedModule: anActionData.module.key,
        modules: {
          ...aState.modules,
         [anActionData.module.key]: anActionData.module
        }
      };
    case ReducerActionType.DeleteModule:
      delete aState.modules[anActionData.key];
      return {
        ...aState,
        dirty: true,
        selectedModule: aState.selectedModule == anActionData.key ? '' : aState.selectedModule,
        modules: {...aState.modules}
      };
    case ReducerActionType.UpdateModule:
      aState.modules[anActionData.module.key] = anActionData.module;
      return {
        ...aState,
        dirty: true
      };
    case ReducerActionType.SwapModule:
      const firstModule = aState.modules[anActionData.firstKey];
      const secondModule = aState.modules[anActionData.secondKey];
      const tempModuleSortOrder = firstModule.sortOrder;
      firstModule.sortOrder = secondModule.sortOrder;
      secondModule.sortOrder = tempModuleSortOrder;
      return {
        ...aState,
        dirty: true,
        modules: {
          ...aState.modules,
          [firstModule.key]: firstModule,
          [secondModule.key]: secondModule
        }
      };
    case ReducerActionType.SetSelectedModule:
      return {
        ...aState,
        selectedModule: anActionData.selectedModule
      };
    case ReducerActionType.AddHeaderBanner:
      module = aState.modules[aState.selectedModule];
      anActionData.headerBanner.sortOrder = UFSorted.getMax(module.headerBannerItems) + 1;
      return {
        ...aState,
        modules: {
          ...aState.modules,
          [module.key]: {
            ...module,
            selectedHeaderBanner: anActionData.headerBanner.key,
            headerBannerItems: {
              ...module.headerBannerItems,
              [anActionData.headerBanner.key]: anActionData.headerBanner
            }
          }
        },
        dirty: true
      };
    case ReducerActionType.DeleteHeaderBanner:
      module = aState.modules[aState.selectedModule];
      delete module.headerBannerItems[anActionData.key];
      return {
        ...aState,
        modules: {
          ...aState.modules,
          [module.key]: {
            ...module,
            selectedHeaderBanner: module.selectedHeaderBanner == anActionData.key
              ? Object.values(module.headerBannerItems)[0].key
              : module.selectedHeaderBanner,
            headerBannerItems: {
              ...module.headerBannerItems,
            }
          }
        },
        dirty: true
      };
    case ReducerActionType.SwapHeaderBanner:
      module = aState.modules[aState.selectedModule];
      const firstBanner = module.headerBannerItems[anActionData.firstKey];
      const secondBanner = module.headerBannerItems[anActionData.secondKey];
      const tempBannerSortOrder = firstBanner.sortOrder;
      firstBanner.sortOrder = secondBanner.sortOrder;
      secondBanner.sortOrder = tempBannerSortOrder;
      return {
        ...aState,
        modules: {
          ...aState.modules,
          [module.key]: {
            ...module,
            headerBannerItems: {
              ...module.headerBannerItems,
              [firstBanner.key]: firstBanner,
              [secondBanner.key]: secondBanner
            }
          }
        },
        dirty: true
      };
    case ReducerActionType.UpdateHeaderBanner:
      module = aState.modules[aState.selectedModule];
      module.headerBannerItems[anActionData.headerBanner.key] = anActionData.headerBanner;
      return {
        ...aState,
        dirty: true
      };
    case ReducerActionType.SetSelectedHeaderBanner:
      module = aState.modules[aState.selectedModule];
      module.selectedHeaderBanner = anActionData.key;
      return {
        ...aState
      };
    case ReducerActionType.SetTemplate:
      return {
        ...aState,
        template: anActionData.template,
        dirty: true
      };
    default:
      return aState;
  }
}

// endregion