// region imports

import * as React from 'react';
import {Box} from "@mui/material";

// endregion

// region component

/**
 * {@link ApplicationContainer} is the container for the whole application.
 */
export const ApplicationContainer: React.FC = ({children}) => (
  <Box sx={{height: '100%'}}>
    {children}
  </Box>
);

// endregion
