// region imports

import * as React from 'react';
import {LandingPageCopySource} from "../../../constants/LandingPageCopySource";
import {Stack, Typography} from "@mui/material";
import {TT} from "../../TT";
import {DialogButton} from "../../controls/DialogButton";
import {Config} from "../../../constants/Config";
import {MainController} from "../../../controllers/MainController";

// endregion

// region local types and functions

interface SelectCopySourceProps {
  readonly hasCampaigns: boolean;
  readonly onSelect: (choice: LandingPageCopySource) => any;
}

// endregion

// region component

export const SelectCopySource: React.FC<SelectCopySourceProps> = ({onSelect, hasCampaigns}) => (
  <Stack direction="column" gap={Config.propertiesGapSize / 2} alignItems="flex-start">
    <Typography>
      <TT ttid="new-landing-page.template-explanation-text">
        Select a template to create the landing page with. The landing page is fully editable and
        every added module can be adjusted or deleted.
      </TT>
    </Typography>
    <DialogButton
      size="large"
      onClick={() => onSelect(LandingPageCopySource.Template)}
    >
      <TT ttid="new-landing-page.template-caption">Choose template</TT>
    </DialogButton>
    {
      hasCampaigns &&
      <Typography marginTop={4}>
        <TT ttid="new-landing-page.campaign-explanation-text">
          Select a campaign to copy the landing page modules and settings from. The landing page is fully editable and
          every added module can be adjusted or deleted.
        </TT>
      </Typography>
    }
    {
      hasCampaigns &&
      <DialogButton
        size="large"
        onClick={() => onSelect(LandingPageCopySource.Campaign)}
      >
        <TT ttid="new-landing-page.campaign-caption">Choose a landing page to copy</TT>
      </DialogButton>
    }
    <Typography marginTop={4}>
      <TT ttid="new-landing-page.empty-explanation-text">
        Start with an empty landing page without any initial modules.
      </TT>
    </Typography>
    <DialogButton
      size="large"
      onClick={() => onSelect(LandingPageCopySource.None)}
    >
      <TT ttid="new-landing-page.empty-caption">Empty landing page</TT>
    </DialogButton>
    <Typography marginTop={4}>
      <TT ttid="new-landing-page.select-campaign-explanation-text">
        Go back and select a different campaign.
      </TT>
    </Typography>
    <DialogButton
      size="large"
      onClick={() => MainController.instance.changeCampaign()}
      color="secondary"
    >
      <TT ttid="new-landing-page.select-campaign-caption">Go back and select campaign</TT>
    </DialogButton>
  </Stack>
);

// endregion