// region imports

import * as React from 'react';
import {Stack, Typography} from "@mui/material";
import {TT} from "../../TT";
import {CampaignSelect} from "../../form/CampaignSelect";
import {Config} from "../../../constants/Config";

// endregion

// region local types and functions

interface SelectSourceCampaignProps {
  readonly value?: string;
  readonly onChange: (value: string) => any;
}

// endregion

// region component

export const SelectSourceCampaign: React.FC<SelectSourceCampaignProps> = ({value = '', onChange}) => {
  return (
    <Stack direction="column" gap={Config.propertiesGapSize} width="100%">
      <Typography>
        <TT ttid="new-landing-page.campaign-text">
          Select a landing page from a campaign to copy the modules and settings from.
        </TT>
      </Typography>
      <CampaignSelect value={value} onChange={onChange} withLandingPage={true} />
    </Stack>
  );
};

// endregion