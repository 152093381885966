// region imports

import {ReducerActionType} from "../ReducerActionType";
import {UFRedux} from "../../UF-react/tools/UFRedux";
import {UserLocation} from "../../constants/UserLocation";
import {LandingPageTemplate} from "../../types/LandingPageTemplate";
import {Campaign} from "../../types/Campaign";

// endregion

// region type

export class UserStoreActions {
  static setUser(aName: string, anAdministrator: boolean) {
    return UFRedux.reducerAction<ReducerActionType.SetUser>(ReducerActionType.SetUser)({
      fullName: aName,
      administrator: anAdministrator
    });
  }
  
  static setLocation(aLocation: UserLocation) {
    return UFRedux.reducerAction<ReducerActionType.SetLocation>(ReducerActionType.SetLocation)({
      location: aLocation
    });
  }

  static setSelectedCampaign(aSelectedCampaign: string) {
    return UFRedux.reducerAction<ReducerActionType.SetSelectedCampaign>(ReducerActionType.SetSelectedCampaign)({
      selectedCampaign: aSelectedCampaign
    });
  }

  static setSelectedTemplate(aSelectedTemplate: string) {
    return UFRedux.reducerAction<ReducerActionType.SetSelectedTemplate>(ReducerActionType.SetSelectedTemplate)({
      selectedTemplate: aSelectedTemplate
    });
  }

  static clear() {
    return UFRedux.reducerAction<ReducerActionType.Clear>(ReducerActionType.Clear)({});
  }
  
  static setTemplates(aTemplates: LandingPageTemplate[]) {
    return UFRedux.reducerAction<ReducerActionType.SetTemplates>(ReducerActionType.SetTemplates)({
      templates: aTemplates
    });
  }

  static setCampaigns(aCampaigns: Campaign[]) {
    return UFRedux.reducerAction<ReducerActionType.SetCampaigns>(ReducerActionType.SetCampaigns)({
      campaigns: aCampaigns
    });
  }
}


// endregion