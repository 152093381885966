/**
 * Defines the {@link Application} component
 */

// region imports

import * as React from 'react';
import {ApplicationContainer} from "./ApplicationContainer";
import {EditorPage} from "../pages/EditorPage";
import {RootDialogs} from "./RootDialogs";
import {UFTranslationProvider} from "../../UF-react/components/translation/UFTranslationProvider";
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";
import {StylingTools} from "../../tools/StylingTools";
import translations from '../../translations/translations.json';
import { LoginPage } from '../pages/LoginPage';
import {Box, Stack} from "@mui/material";
import {ApplicationTopBar} from "../applicationTopBar/ApplicationTopBar";
import {EditorMode} from "../../constants/EditorMode";
import {SelectCampaignPage} from "../pages/SelectCampaignPage";
import {SelectTemplatePage} from "../pages/SelectTemplatePage";
import {UserLocation} from "../../constants/UserLocation";
import {NewLandingPagePage} from "../pages/NewLandingPagePage";
import {StartPage} from "../pages/StartPage";
import {LoadingPage} from "../pages/LoadingPage";

// endregion

// region local types and functions

/**
 * Properties for component.
 */
interface ApplicationProps {
  language: string;
  location: UserLocation;
}

/**
 * Maps the state to local properties.
 */
function mapStateToProps(anApplicationState: ApplicationState) {
  return {
    language: anApplicationState.settings.language,
    location: anApplicationState.user.location
  }
}

// endregion

// region component

/**
 * {@link Application} is the root component of the application.
 */
export const Application = connect(mapStateToProps)(
  ({language, location}: ApplicationProps) => {
  return (
    <UFTranslationProvider
      texts={translations}
      language={language}
      htmlParser={value => StylingTools.parseHtml(value)}
    >
      <ApplicationContainer>
        <RootDialogs/>
        <Stack
          direction="column"
          spacing={0}
          height="100%"
          alignItems="stretch"
        >
          <ApplicationTopBar />
          <Box
            flex={1}
            height="100%"
            overflow="hidden"
          >
            {(location == UserLocation.Start) && <StartPage />}
            {(location == UserLocation.Login) && <LoginPage />}
            {(location == UserLocation.SelectCampaign) && <SelectCampaignPage />}
            {(location == UserLocation.SelectTemplate) && <SelectTemplatePage />}
            {(location == UserLocation.CreateLandingPage) && <NewLandingPagePage />}
            {(location == UserLocation.LoadingPage) && <LoadingPage />}
            {(location == UserLocation.EditLandingPage) && <EditorPage />}
          </Box>
        </Stack>
      </ApplicationContainer>
    </UFTranslationProvider>
  );
});

// endregion
