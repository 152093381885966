/**
 * All actions possible on the store. These include also actions for sub-stores.
 *
 * All actions are stored in one enum, to make sure they are unique.
 */
export enum ReducerActionType {
  // dialogs/alert
  ShowAlert = 'SHOW_ALERT',
  HideAlert = 'HIDE_ALERT',
  // dialogs/colorPicker
  ShowColorPicker = 'SHOW_COLOR_PICKER',
  HideColorPicker = 'HIDE_COLOR_PICKER',
  // dialogs/confirm
  ShowConfirm = 'SHOW_CONFIRM',
  HideConfirm = 'HIDE_CONFIRM',
  // dialogs/edit
  ShowEdit = 'SHOW_EDIT',
  HideEdit = 'HIDE_EDIT',
  // dialogs/input
  ShowInput = 'SHOW_INPUT',
  HideInput = 'HIDE_INPUT',
  // dialogs/newModule
  ShowNewModule = 'SHOW_NEW_MODULE',
  HideNewModule = 'HIDE_NEW_MODULE',
  // dialogs/changeCode
  ShowChangeCode = 'SHOW_CHANGE_CODE',
  HideChangeCode = 'HIDE_CHANGE_CODE',
  // dialogs/newLandingPAge
  ShowNewLandingPage = 'SHOW_NEW_LANDING_PAGE',
  HideNewLandingPage = 'HIDE_NEW_LANDING_PAGE',
  // dialogs/selectImage
  ShowSelectImage = 'SHOW_SELECT_IMAGE',
  HideSelectImage = 'HIDE_SELECT_IMAGE',
  // dialogs/deleteTemplate
  ShowDeleteTemplate = 'SHOW_DELETE_TEMPLATE',
  HideDeleteTemplate = 'HIDE_DELETE_TEMPLATE',
  // dialogs/selectTemplate
  ShowSelectTemplate = 'SHOW_SELECT_TEMPLATE',
  HideSelectTemplate = 'HIDE_SELECT_TEMPLATE',
  // dialogs/selectCampaign
  ShowSelectCampaign = 'SHOW_SELECT_CAMPAIGN',
  HideSelectCampaign = 'HIDE_SELECT_CAMPAIGN',
  // dialogs/selectTargetCampaign
  ShowSelectTargetCampaign = 'SHOW_SELECT_TARGET_CAMPAIGN',
  HideSelectTargetCampaign = 'HIDE_SELECT_TARGET_CAMPAIGN',
  // dialogs/applicationDialog
  ShowApplicationDialog = 'SHOW_APPLICATION_DIALOG',
  HideApplicationDialog = 'HIDE_APPLICATION_DIALOG',
  // landingPage
  SetConfiguration = 'SET_CONFIGURATION',
  SetBorder = 'SET_BORDER',
  SetColors = 'SET_COLORS',
  SetFont = 'SET_FONT',
  SetDropShadow = 'SET_DROP_SHADOW',
  SetTopBar = 'SET_TOP_BAR',
  ClearDirty = 'CLEAR_DIRTY',
  SetCode = 'SET_CODE',
  Reset = 'RESET',
  AddModule = 'ADD_MODULE',
  DeleteModule = 'DELETE_MODULE',
  UpdateModule = 'UPDATE_MODULE',
  SwapModule = 'SWAP_MODULE',
  SetSelectedHeaderBanner = 'SET_SELECTED_HEADER_BANNER',
  AddHeaderBanner = 'ADD_HEADER_BANNER',
  DeleteHeaderBanner = 'DELETE_HEADER_BANNER',
  UpdateHeaderBanner = 'UPDATE_HEADER_BANNER',
  SwapHeaderBanner = 'SWAP_HEADER_BANNER',
  SetTemplate = 'SET_TEMPLATE',
  SetSelectedModule = 'SELECT_SELECTED_MODULE',
  // main
  SetLandingPageViewHeight = 'SET_LANDING_PAGE_VIEW_HEIGHT',
  SetInitialized = 'SET_INITIALIZED',
  // network
  SetBusySaving = 'SET_BUSY_SAVING',
  SetSaveDelayTime = 'SET_SAVE_DELAY_TIME',
  SetNetworkBusy = 'SET_NETWORK_BUSY',
  // settings
  SetLanguage = 'SET_LANGUAGE',
  // editor
  SetViewMode = 'SET_VIEW_MODE',
  SetViewScale = 'SET_VIEW_SCALE',
  SetEditorMode = 'SET_EDITOR_MODE',
  // user
  SetUser = 'SET_USER',
  SetLocation = 'SET_LOCATION',
  SetSelectedCampaign = 'SET_SELECTED_CAMPAIGN',
  SetSelectedTemplate = 'SET_SELECTED_TEMPLATE',
  Clear = 'CLEAR',
  SetTemplates = 'SET_TEMPLATES',
  SetCampaigns = 'SET_CAMPAIGNS',
  // test
  ShowTest = 'SHOW_TEST',
  HideTest = 'HIDE_TEST',
  // credentials
  SetToken = 'SET_TOKEN',

}
