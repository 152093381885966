// region imports

import * as React from 'react';
import {Button, ButtonGroup} from "@mui/material";
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";
import {ViewMode} from "../../constants/ViewMode";
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import {StylingTools} from "../../tools/StylingTools";
import {EditorStoreActions} from "../../store/editor/EditorStoreActions";

// endregion

// region local types and functions

/**
 * Properties for component.
 */
interface ViewModeMenuProps {
  viewMode: ViewMode;
  setViewMode: typeof EditorStoreActions.setViewMode
}

/**
 * Maps the application state to the component properties.
 *
 * @param {ApplicationState} state
 * @returns {{viewMode: ViewMode}}
 */
const mapStateToProps = (state: ApplicationState) => {
  return {
    viewMode: state.editor.viewMode
  };
};

// endregion

// region component

/**
 * {@link ViewModeMenu} shows buttons to select a certain view mode.
 */
export const ViewModeMenu = connect(
  mapStateToProps, {setViewMode: EditorStoreActions.setViewMode}
)(
  ({viewMode, setViewMode}: ViewModeMenuProps) => {
    // there is no selected styling, so define one
    return (
      <ButtonGroup
        disableElevation
        color="inherit"
        variant="outlined"
        size="small"
      >
        <Button
          sx={StylingTools.getSelectedTopButton(viewMode == ViewMode.Desktop)}
          onClick={() => setViewMode(ViewMode.Desktop)}
        >
          <DesktopWindowsIcon/>
        </Button>
        <Button
          sx={StylingTools.getSelectedTopButton(viewMode == ViewMode.Mobile)}
          onClick={() => setViewMode(ViewMode.Mobile)}
        >
          <PhoneIphoneIcon/>
        </Button>
      </ButtonGroup>
    );
  }
);

// endregion