// region imports

import * as React from 'react';
import {useState} from 'react';
import {Dialog, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import {TT} from "../TT";
import CloseIcon from '@mui/icons-material/Close';
import {LandingPageConfiguration} from "../../types/LandingPageConfiguration";
import {LandingPageStoreActions} from "../../store/landingPage/LandingPageStoreActions";
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";
import {DialogButton} from "../controls/DialogButton";
import {UFStyledDialogActions} from '../../UF-mui/components/dialogs/UFStyledDialogActions';
import CheckIcon from "@mui/icons-material/Check";
import {UFBusy} from "../../UF-mui/components/wrappers/UFBusy";
import {CodeField} from "../form/CodeField";
import {ServerResultText} from "../texts/ServerResultText";
import {ServerResult} from "../../constants/ServerResult";
import {NetworkController} from "../../controllers/NetworkController";
import {UrlPreview} from "../form/UrlPreview";
import {Config} from "../../constants/Config";
import {UFCollapsableAlert} from "../../UF-mui/components/controls/UFCollapsableAlert";

// endregion

// region local types and functions

/**
 * Properties for component
 */
interface ChangeCodeDialogProps {
  configuration: LandingPageConfiguration;
  setConfiguration: typeof LandingPageStoreActions.setConfiguration;
  onClose: () => any;
  networkBusy: boolean;
  webmasterCode: string;
}

function mapStateToProps(anApplicationState: ApplicationState) {
  return {
    configuration: anApplicationState.landingPage.configuration,
    networkBusy: anApplicationState.network.networkBusy,
    webmasterCode: anApplicationState.user.urlCode
  }
}

// endregion

// region component

/**
 * {@link ChangeCodeDialog} shows the module types for the user to select one.
 */
export const ChangeCodeDialog = connect(mapStateToProps, {setConfiguration: LandingPageStoreActions.setConfiguration})(
  ({configuration, setConfiguration, onClose, webmasterCode, networkBusy}: ChangeCodeDialogProps) => {
    const [code, setCode] = useState(configuration.urlCode);
    const [serverResult, setServerResult] = useState(ServerResult.None);
    const [errorMessage, setErrorMessage] = useState(false);
    return (
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth="sm"
        onClose={
          () => {
            if (!networkBusy) {
              onClose();
            }
          }
        }
      >
        <DialogTitle>
          <TT ttid="change-code.title">Change code</TT>
        </DialogTitle>
        <DialogContent>
          <UFCollapsableAlert
            message={<ServerResultText result={serverResult}/>}
            severity="error"
            onClose={() => setErrorMessage(false)}
            visible={errorMessage}
          />
          <Stack direction="column" gap={1.5 * Config.propertiesGapSize}>
            <Typography sx={{marginBottom: 1}}>
              <TT ttid="change-code.introduction">
                Enter a new code and press update to save it at the server.
              </TT>
            </Typography>
            <CodeField
              value={code}
              onChange={value => {
                setCode(value);
                setServerResult(ServerResult.None);
                setErrorMessage(false);
              }}
              disabled={networkBusy}
              autoFocus={true}
              error={![ServerResult.None, ServerResult.Success].includes(serverResult)}
              label={<TT ttid="change-code.label">Code for url</TT>}
            />
            <UrlPreview
              landingPageCode={code}
              webmasterCode={webmasterCode}
            />
          </Stack>
        </DialogContent>
        <UFStyledDialogActions>
          <UFBusy busy={networkBusy}>
            <DialogButton
              color="primary"
              onClick={async () => {
                const result = await NetworkController.instance.validateCode(code);
                if (result == ServerResult.Success) {
                  setConfiguration({...configuration, urlCode: code});
                  onClose();
                } else {
                  setServerResult(result);
                  setErrorMessage(true);
                }
              }}
              startIcon={<CheckIcon/>}
              disabled={networkBusy || (code.length <= 0)}
            >
              <TT>Update</TT>
            </DialogButton>
          </UFBusy>
          <DialogButton
            color="secondary"
            onClick={onClose}
            startIcon={<CloseIcon/>}
            disabled={networkBusy}
          >
            <TT>Cancel</TT>
          </DialogButton>
        </UFStyledDialogActions>
      </Dialog>
    );
  }
);

// endregion
