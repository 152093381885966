import * as React from "react";
import {UFRedux} from "../../../UF-react/tools/UFRedux";
import {ReducerActionType} from "../../ReducerActionType";
import {ThunkAction} from "redux-thunk";
import {ApplicationState} from "../../ApplicationState";
import {Action} from "redux";

export class AlertStoreActions {
  /**
   * Shows the alert popup.
   *
   * @param aTitle
   *   Title to use
   * @param aContent
   *   Content to use
   * @param aCallback
   *   Callback to call
   * @param aClose
   *   Close label
   */
  static show(
    aTitle: React.ReactNode, aContent: React.ReactNode, aCallback: () => void, aClose: React.ReactNode = 'Close'
  ) {
    return UFRedux.reducerAction<ReducerActionType.ShowAlert>(ReducerActionType.ShowAlert)({
      title: aTitle,
      content: aContent,
      close: aClose,
      callback: aCallback,
    });
  }


  /**
   * Hides the alert popup.
   */
  static hide() {
    return UFRedux.reducerAction<ReducerActionType.HideAlert>(ReducerActionType.HideAlert)({});
  }

  /**
   * Thunk action to perform an alert popup.
   *
   * @param aTitle
   *   Title to use
   * @param aContent
   *   Content to use
   * @param aClose
   *   Close label (default is 'Close')
   *
   * @returns A function that expects a dispatch parameter and returns a Promise.
   */
  static popup = (
    aTitle: React.ReactNode, aContent: React.ReactNode, aClose: React.ReactNode = 'Close'
  ): ThunkAction<Promise<void>, ApplicationState, null, Action<ReducerActionType>> =>
    dispatch => new Promise(resolve => {
      dispatch(AlertStoreActions.show(
        aTitle,
        aContent,
        () => {
          dispatch(AlertStoreActions.hide());
          resolve();
        },
        aClose
      ));
    });
}