// region imports

import * as React from 'react';
import {Provider} from "react-redux";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {appTheme} from "../../themes/appTheme";
import GoogleFontLoader from "react-google-font-loader";
import {Application} from "./Application";

// endregion

// region local types

interface RootProps {
  /**
   * Redux store to use
   */
  store: any;
}

// endregion

// region component

/**
 * The root of the application.
 */
export const Root: React.FC<RootProps> = ({store}) => (
  <Provider store={store}>
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <GoogleFontLoader
        fonts={[
          {
            font: 'Roboto',
            weights: [400, 600, 700, 900]
          },
          {
            font: 'Roboto+Slab',
            weights: [400, 600, 700, 900]
          },
          {
            font: 'Open+Sans',
            weights: [400, 600, 700, 900]
          },
          {
            font: 'Montserrat',
            weights: [400, 600, 700, 900]
          }
        ]}
      />
      <Application/>
    </ThemeProvider>
  </Provider>
);

// endregion