// region imports

import * as React from 'react';
import {LandingPageColors} from "../../../types/LandingPageColors";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {Box} from "@mui/material";
import {ModuleBaseProps} from "../../../types/ModuleBaseProps";
import {HeaderBannerDictionary} from "../../../types/HeaderBannerDictionary";
import {LandingPageModuleDictionary} from "../../../types/LandingPageModuleDictionary";
import {UFSorted} from "../../../UF/tools/UFSorted";
import {HeaderBannerItem} from "../../../types/HeaderBannerItem";
import {ViewMode} from "../../../constants/ViewMode";
import {HeaderBanner} from "../parts/HeaderBanner";
import {Config} from "../../../constants/Config";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// endregion

// region local types and functions

/**
 * Props for the component
 */
interface HeaderBannerModuleProps extends ModuleBaseProps {
  colors: LandingPageColors;
  modules: LandingPageModuleDictionary;
  viewMode: ViewMode;
  headerBannerItems: HeaderBannerDictionary;
  selectedHeaderBanner: string;
  headerBannerItem: HeaderBannerItem;
}

/**
 * Maps store state to component properties.
 */
function mapStatsToProps(anApplicationState: ApplicationState, aProps: Partial<HeaderBannerModuleProps>) {
  const module = anApplicationState.landingPage.modules[aProps.module!.key];
  return {
    colors: anApplicationState.landingPage.colors,
    modules: anApplicationState.landingPage.modules,
    viewMode: anApplicationState.editor.viewMode,
    headerBannerItems: module.headerBannerItems,
    selectedHeaderBanner: module.selectedHeaderBanner,
    headerBannerItem: module.headerBannerItems[module.selectedHeaderBanner]
  }
}

// endregion

// region component

/**
 * The top bar in the landing page.
 */
export const HeaderBannerModule = connect(mapStatsToProps)(
  ({module, modules, viewMode, headerBannerItems, selectedHeaderBanner, headerBannerItem}: HeaderBannerModuleProps) => {
    const isFirst = module.sortOrder == UFSorted.getMin(modules);
    const atDesktop = viewMode == ViewMode.Desktop;
    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: '1fr',
          gridTemplateAreas: '"banner"',
          marginTop: isFirst ? (-Config.verticalPadding) + 'px' : 0,
          position: 'relative',
          '&:hover': {
            opacity: 0.8
          }
        }}
      >
        {
          UFSorted.sort(headerBannerItems).map(
            item => <HeaderBanner
              key={item.key}
              itemKey={item.key}
              moduleKey={module.key}
              visible={item.key == selectedHeaderBanner}
            />
          )
        }
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: atDesktop ? '300px' : '100px'
          }}
        >
          <ChevronLeftIcon
            sx={{
              fontSize: '40px',
              color: 'white',
              filter: 'drop-shadow(-1px -1px 0 black) drop-shadow(+1px +1px 0 black) drop-shadow(-1px +1px 0 black) drop-shadow(+1px -1px 0 black)'
            }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: atDesktop ? '300px' : '100px'
          }}
        >
          <ChevronRightIcon
            sx={{
              fontSize: '40px',
              color: 'white',
              filter: 'drop-shadow(-1px -1px 0 black) drop-shadow(+1px +1px 0 black) drop-shadow(-1px +1px 0 black) drop-shadow(+1px -1px 0 black)'
            }}
          />
        </Box>
      </Box>
    )
  }
);

// endregion
