// region imports

import * as React from 'react';
import {Button} from "@mui/material";
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";
import {StylingTools} from "../../tools/StylingTools";
import {TT} from "../TT";
import EditIcon from '@mui/icons-material/Edit';
import {LandingPageStoreActions} from "../../store/landingPage/LandingPageStoreActions";

// endregion

// region local types and functions

/**
 * Properties for component.
 */
interface LandingPageSettingsProps {
  selectedModule: string;
  setSelectedModule: typeof LandingPageStoreActions.setSelectedModule;
}

/**
 * Maps the application state to the component properties.
 */
function mapStateToProps(anApplicationState: ApplicationState) {
  return {
    selectedModule: anApplicationState.landingPage.selectedModule
  };
}

// endregion

// region component

/**
 * {@link ViewModeMenu} shows buttons to select a certain view mode.
 */
export const LandingPageSettingsButton = connect(
  mapStateToProps,
  {
    setSelectedModule: LandingPageStoreActions.setSelectedModule
  }
)(
  ({selectedModule, setSelectedModule}: LandingPageSettingsProps) => {
    // there is no selected styling, so define one
    return (
      <Button
        size="small"
        color="inherit"
        variant="outlined"
        sx={StylingTools.getSelectedTopButton(!selectedModule.length)}
        onClick={() => setSelectedModule('')}
        startIcon={<EditIcon/>}
      >
        <TT ttid="landing-page-settings.caption">Landing Page Settings</TT>
      </Button>
    );
  }
);

// endregion