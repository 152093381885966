// region imports

import * as React from 'react';
import {connect} from "react-redux";
import {DialogPage} from "./DialogPage";
import {TT} from "../TT";
import {Stack, Typography} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {useState} from "react";
import {DialogButton} from "../controls/DialogButton";
import {MainController} from "../../controllers/MainController";
import {TemplateSelect} from "../form/TemplateSelect";
import {ApplicationState} from "../../store/ApplicationState";
import {Config} from "../../constants/Config";

// endregion

// region local types and functions

interface SelectTemplatePageProps {
  readonly networkBusy: boolean;
}

function mapStateToProps(anApplicationState: ApplicationState) {
  return {
    networkBusy: anApplicationState.network.networkBusy
  }
}

// endregion

// region component

export const SelectTemplatePage = connect(mapStateToProps)(({networkBusy}: SelectTemplatePageProps) => {
  const [template, setTemplate] = useState('');
  return (
    <DialogPage width="600px" title={<TT ttid="select-template.title">Select template</TT>}>
      <Typography>
        <TT ttid="select-template.intro">Select a template to edit the landing page for.</TT>
      </Typography>
      <TemplateSelect value={template} onChange={setTemplate}/>
      <Stack
        direction="row"
        marginTop={4}
        gap={Config.propertiesGapSize}
      >
        <DialogButton
          onClick={() => {
            // noinspection JSIgnoredPromiseFromCall
            MainController.instance.newTemplate();
          }}
          variant="contained"
          startIcon={<AddIcon/>}
          disabled={networkBusy}
        >
          <TT ttid="select-template.new-caption">Create new</TT>
        </DialogButton>
        <DialogButton
          onClick={() => {
            // noinspection JSIgnoredPromiseFromCall
            MainController.instance.selectTemplate(template);
          }}
          variant="contained"
          startIcon={<CheckIcon/>}
          disabled={(template.length <= 0) || networkBusy}
        >
          <TT ttid="select-template.select-caption">Edit</TT>
        </DialogButton>
        <DialogButton
          color="error"
          startIcon={<DeleteOutlineIcon/>}
          disabled={(template.length <= 0) || networkBusy}
          onClick={async () => {
            await MainController.instance.deleteTemplate(template);
            setTemplate('');
          }}
        >
          <TT ttid="select-template.delete-caption">Delete</TT>
        </DialogButton>
      </Stack>
    </DialogPage>
  );
});

// endregion
