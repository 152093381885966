// region imports

import * as React from 'react';
import {Stack} from "@mui/material";
import {BottomBar} from "./BottomBar";
import {TopBar} from "./TopBar";
import {ModulesContainer} from "./ModulesContainer";
import {ApplicationState} from "../../store/ApplicationState";
import {LandingPageFont} from "../../types/LandingPageFont";
import {connect} from "react-redux";
import {ViewMode} from "../../constants/ViewMode";
import {StylingTools} from "../../tools/StylingTools";

// endregion

// region local types and functions

/**
 * Props for the component
 */
interface LandingPageProps {
  /**
   * Width of content in landing page
   */
  contentWidth: number;

  /**
   * Font to render page with
   */
  font: LandingPageFont;

  /**
   * Viewing mode
   */
  viewMode: ViewMode;
}

/**
 * Maps the state to local props.
 */
function mapStatsToProps(anApplicationState: ApplicationState) {
  return {
    font: anApplicationState.landingPage.font,
    viewMode: anApplicationState.editor.viewMode
  }
}

// endregion

// region component

/**
 * The landing page.
 */
export const LandingPage = connect(mapStatsToProps)(
  ({font, contentWidth, viewMode}: LandingPageProps) => (
    <Stack
      sx={{
        background: 'white',
        flex: '1 1',
        fontFamily: StylingTools.getFontFamily(font.family),
        fontSize: viewMode == ViewMode.Desktop ? font.size + 'px' : (font.size * 0.9) + 'px'
      }}
      id="landing-page"
    >
      <TopBar contentWidth={contentWidth}/>
      <ModulesContainer contentWidth={contentWidth}/>
      <BottomBar contentWidth={contentWidth}/>
    </Stack>
  )
);

// endregion