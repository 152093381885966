// region imports

import * as React from 'react';
import {UFFormTextField} from "../../UF-mui/components/form/UFFormTextField";
import {TT} from "../TT";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

// endregion

// region local types and functions

interface DescriptionFieldProps {
  value: string;
  onChange: (value: string) => any;
  disabled?: boolean;
  label?: React.ReactNode;
  autoFocus?: boolean;
  shortHelp?: boolean;
  readonly sx?: SxProps<Theme>;
}

// endregion

// region component

export const DescriptionField: React.FC<DescriptionFieldProps> = (
  {
    value,
    onChange,
    label = <TT ttid="description-input.label">Description</TT>,
    disabled = false,
    autoFocus = false,
    shortHelp = false,
    sx = {}
  }
) => (
  <UFFormTextField
    label={label}
    onFieldChange={({value}) => onChange(value.replace(/[\r\n]/g, ''))}
    value={value}
    disabled={disabled}
    autoFocus={autoFocus}
    multiline={true}
    color={value.length < 120 ? 'primary' : value.length < 158 ? 'warning' : 'error'}
    sx={sx}
    helperText={
      shortHelp
        ? <TT ttid="description-input.description-short-help" map={{'$length$': value.length}}>
          Length: $length$ (max 120/158)
        </TT>
        : <TT ttid="description-input.description-long-help" map={{'$length$': value.length}}>
          Max length for mobile: 120, for desktop: 158, current length: $length$
        </TT>
    }
  />
);

// endregion