/**
 * Possible modes for the editor
 */
export enum EditorMode {
  /**
   * Nothing to edit.
   */
  None,

  /**
   * Edit a landing page for a template
   */
  Template,

  /**
   * Edit a landing page for a campaign
   */
  Campaign
}
