// region imports

import * as React from 'react';
import {Button, ButtonProps} from "@mui/material";

// endregion

// region component

/**
 * Button with some default styling.
 */
export const DialogButton: React.FC<ButtonProps> = (
  {variant = 'contained', size = 'medium', ...other}
) =>
  <Button variant={variant} size={size} {...other} />
