import {FontFamily} from "../../../constants/FontFamily";
import {UFRedux} from "../../../UF-react/tools/UFRedux";
import {ReducerActionType} from "../../ReducerActionType";

export class EditStoreActions {
  /**
   * Shows the edit popup
   *
   * @param aCallback
   *   Callback to call with every change
   * @param aValue
   *   Initial value to show
   * @param aTextColor
   * @param aBackgroundColor
   * @param aFontFamily
   * @param aFontSize
   * @param anAllowFontSize
   *   Show font size option
   */
  static show(
    aCallback: (value: string) => void, aValue: string = '',
    aTextColor: string = '#222222', aBackgroundColor: string = '#ffffff', aFontFamily: FontFamily = FontFamily.Roboto,
    aFontSize: number = 14, anAllowFontSize: boolean = false
  ) {
    return UFRedux.reducerAction<ReducerActionType.ShowEdit>(ReducerActionType.ShowEdit)({
      callback: aCallback,
      value: aValue,
      backgroundColor: aBackgroundColor,
      textColor: aTextColor,
      fontFamily: aFontFamily,
      fontSize: aFontSize,
      allowFontSize: anAllowFontSize
    });
  }

  /**
   * Hides the edit popup.
   */
  static hide() {
    return UFRedux.reducerAction<ReducerActionType.HideEdit>(ReducerActionType.HideEdit)({});
  }
}