// region imports

import {ReducerActionType} from "../ReducerActionType";
import {LandingPageBorder} from "../../types/LandingPageBorder";
import {LandingPageColors} from "../../types/LandingPageColors";
import {LandingPageFont} from "../../types/LandingPageFont";
import {LandingPageConfiguration} from "../../types/LandingPageConfiguration";
import {LandingPageDropShadow} from "../../types/LandingPageDropShadow";
import {LandingPageTopBar} from "../../types/LandingPageTopBar";
import {LandingPageModule} from "../../types/LandingPageModule";
import {HeaderBannerItem} from "../../types/HeaderBannerItem";
import {UFRedux} from "../../UF-react/tools/UFRedux";
import {LandingPageTemplate} from "../../types/LandingPageTemplate";

// endregion

// region exports

export class LandingPageStoreActions {

  static setBorder(aBorder: LandingPageBorder) {
    return UFRedux.reducerAction<ReducerActionType.SetBorder>(ReducerActionType.SetBorder)({
      border: aBorder
    });
  }

  static setColors(aColors: LandingPageColors) {
    return UFRedux.reducerAction<ReducerActionType.SetColors>(ReducerActionType.SetColors)({
      colors: aColors
    });
  }

  static setConfiguration(aConfiguration: LandingPageConfiguration) {
    return UFRedux.reducerAction<ReducerActionType.SetConfiguration>(ReducerActionType.SetConfiguration)({
      configuration: aConfiguration
    });
  }

  static setDropShadow(aDropShadow: LandingPageDropShadow) {
    return UFRedux.reducerAction<ReducerActionType.SetDropShadow>(ReducerActionType.SetDropShadow)({
      dropShadow: aDropShadow
    });
  }

  static setFont(aFont: LandingPageFont) {
    return UFRedux.reducerAction<ReducerActionType.SetFont>(ReducerActionType.SetFont)({
      font: aFont
    });
  }

  static setTopBar(aTopBar: LandingPageTopBar) {
    return UFRedux.reducerAction<ReducerActionType.SetTopBar>(ReducerActionType.SetTopBar)({
      topBar: aTopBar
    });
  }

  static setCode(aCode: string) {
    return UFRedux.reducerAction<ReducerActionType.SetCode>(ReducerActionType.SetCode)({
      code: aCode
    });
  }

  static reset() {
    return UFRedux.reducerAction<ReducerActionType.Reset>(ReducerActionType.Reset)({})
  }

  static clearDirty() {
    return UFRedux.reducerAction<ReducerActionType.ClearDirty>(ReducerActionType.ClearDirty)({})
  }

  static addModule(aModule: LandingPageModule) {
    return UFRedux.reducerAction<ReducerActionType.AddModule>(ReducerActionType.AddModule)({
      module: aModule
    })
  }

  static deleteModule(aKey: string) {
    return UFRedux.reducerAction<ReducerActionType.DeleteModule>(ReducerActionType.DeleteModule)({
      key: aKey
    })
  }

  static updateModule(aModule: LandingPageModule) {
    return UFRedux.reducerAction<ReducerActionType.UpdateModule>(ReducerActionType.UpdateModule)({
      module: aModule
    })
  }

  static swapModule(aFirstKey: string, aSecondKey: string) {
    return UFRedux.reducerAction<ReducerActionType.SwapModule>(ReducerActionType.SwapModule)({
      firstKey: aFirstKey,
      secondKey: aSecondKey
    })
  }

  static addHeaderBanner(aHeaderBanner: HeaderBannerItem) {
    return UFRedux.reducerAction<ReducerActionType.AddHeaderBanner>(ReducerActionType.AddHeaderBanner)({
      headerBanner: aHeaderBanner
    })
  }

  static deleteHeaderBanner(aKey: string) {
    return UFRedux.reducerAction<ReducerActionType.DeleteHeaderBanner>(ReducerActionType.DeleteHeaderBanner)({
      key: aKey
    })
  }

  static updateHeaderBanner(aHeaderBanner: HeaderBannerItem) {
    return UFRedux.reducerAction<ReducerActionType.UpdateHeaderBanner>(ReducerActionType.UpdateHeaderBanner)({
      headerBanner: aHeaderBanner
    })
  }

  static swapHeaderBanner(aFirstKey: string, aSecondKey: string) {
    return UFRedux.reducerAction<ReducerActionType.SwapHeaderBanner>(ReducerActionType.SwapHeaderBanner)({
      firstKey: aFirstKey,
      secondKey: aSecondKey
    })
  }

  static setSelectedHeaderBanner(aKey: string) {
    return UFRedux.reducerAction<ReducerActionType.SetSelectedHeaderBanner>(ReducerActionType.SetSelectedHeaderBanner)({
      key: aKey
    })
  }

  static setSelectedModule(aKey: string) {
    return UFRedux.reducerAction<ReducerActionType.SetSelectedModule>(ReducerActionType.SetSelectedModule)({
      selectedModule: aKey
    })
  }

  static setTemplate(aTemplate: LandingPageTemplate) {
    return UFRedux.reducerAction<ReducerActionType.SetTemplate>(ReducerActionType.SetTemplate)({
      template: aTemplate
    });
  }


}

// endregion
