/**
 * This module initializes the redux store, history method and renders the
 * root component.
 */

// region imports

import * as React from 'react';
import ReactDOM from 'react-dom';
import {buildStore} from './store/buildStore';
import {MainController} from './controllers/MainController';
import {Root} from "./components/application/Root";
import {NetworkController} from "./controllers/NetworkController";

// endregion

// region startup code

// initialize redux store
const store = buildStore();
// initialize controllers
MainController.init(store);
NetworkController.init(store);
// render the root
ReactDOM.render(<Root store={store}/>, document.getElementById('root'));
// start the application
// noinspection JSIgnoredPromiseFromCall
MainController.instance.start();

// endregion
