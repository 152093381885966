// region imports

import * as React from 'react';
import {Typography} from "@mui/material";
import {TT} from "../TT";

// endregion

// region local types and functions

interface LabeledTextProps {
  readonly label: React.ReactNode;
  readonly text: React.ReactNode;
}

// endregion

// region component

export const LabeledText: React.FC<LabeledTextProps> = (
  {
    label,
    text
  }
) => (
  <Typography>
    <Typography variant="caption" component="span">
      {label}
    </Typography>
    &nbsp;
    {text}
  </Typography>
);

// endregion