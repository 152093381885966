// region imports

import * as React from "react";
import {Slider, Stack, Typography} from "@mui/material";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {ApplicationState} from "../../store/ApplicationState";
import {connect} from "react-redux";
import {EditorStoreActions} from "../../store/editor/EditorStoreActions";

// endregion

// region local types and functions

/**
 * Properties for the component.
 */
interface ViewScaleProps {
  viewScale: number;
  setViewScale: typeof EditorStoreActions.setViewScale
}

/**
 * Maps application state to component properties.
 *
 * @param {ApplicationState} state
 * @returns {{viewScale: number}}
 */
function mapStateToProps(state: ApplicationState) {
  return {
    viewScale: state.editor.viewScale
  };
}

// endregion

// region component

/**
 * {@link ViewScale} shows a slider to change the viewing scale.
 */
export const ViewScale = connect(mapStateToProps, {setViewScale: EditorStoreActions.setViewScale})
(
  ({viewScale, setViewScale}: ViewScaleProps) => {
    const updateViewScale = (value: number) => {
      setViewScale((Math.abs(value - 100) < 10) ? 100 : value);
    }
    return (
      <Stack spacing={0} direction="row" sx={{width: '200px'}} alignItems="center">
        <ZoomOutIcon sx={{marginRight: '0.7rem'}}/>
        <Slider
          sx={{color: 'background.default'}}
          track={false}
          marks={[{value: 100}]}
          min={25}
          max={200}
          value={viewScale}
          onChange={(event, value) => updateViewScale(value as number)}
        />
        <ZoomInIcon sx={{marginLeft: '0.7rem'}}/>
        <Typography sx={{width: 80}} variant="caption" align="right">{Math.floor(viewScale)}%</Typography>
      </Stack>
    )
  }
);

// endregion