// region imports

import * as React from 'react';
import {connect} from "react-redux";
import {Button, Stack} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import {HeaderBannerItem} from "../../../types/HeaderBannerItem";
import {HeaderBannerDictionary} from "../../../types/HeaderBannerDictionary";
import {ApplicationState} from "../../../store/ApplicationState";
import {UFThunkPropsType} from "../../../UF-react/tools/UFRedux";
import {UFSorted} from "../../../UF/tools/UFSorted";
import {PropertyGroup} from "../PropertyGroup";
import {TT} from "../../TT";
import {LandingPageModuleTools} from "../../../tools/LandingPageModuleTools";
import {Config} from "../../../constants/Config";
import {LandingPageFont} from "../../../types/LandingPageFont";
import MonitorIcon from '@mui/icons-material/Monitor';
import {HtmlTextField} from '../../form/HtmlTextField';
import {LandingPageStoreActions} from "../../../store/landingPage/LandingPageStoreActions";
import {ConfirmStoreActions} from "../../../store/dialogs/confirm/ConfirmStoreActions";
import {LandingPageModule} from "../../../types/LandingPageModule";
import {ApplicationButton} from "../../controls/ApplicationButton";
import {ImageFitSelect} from "../../form/ImageFitSelect";
import {ImageField} from "../../form/ImageField";

// endregion

// region local types and functions

interface HeaderBannerItemProps {
  readonly headerBannerKey: string;
  readonly headerBannerItems: HeaderBannerDictionary;
  readonly swapHeaderBanner: typeof LandingPageStoreActions.swapHeaderBanner;
  readonly deleteHeaderBanner: typeof LandingPageStoreActions.deleteHeaderBanner;
  readonly addHeaderBanner: typeof LandingPageStoreActions.addHeaderBanner;
  readonly updateHeaderBanner: typeof LandingPageStoreActions.updateHeaderBanner;
  readonly setSelectedHeaderBanner: typeof LandingPageStoreActions.setSelectedHeaderBanner;
  readonly confirmPopup: UFThunkPropsType<typeof ConfirmStoreActions.popup>;
  readonly font: LandingPageFont;
  readonly selectedHeaderBanner: string;
  readonly headerBanner: HeaderBannerItem;
  readonly module: LandingPageModule;
}

function mapStateToProps(anApplicationState: ApplicationState, aProps: Partial<HeaderBannerItemProps>) {
  const module = aProps.module!;
  return {
    headerBannerItems: module.headerBannerItems,
    font: anApplicationState.landingPage.font,
    selectedHeaderBanner: module.selectedHeaderBanner,
    headerBanner: module.headerBannerItems[aProps.headerBannerKey!]
  };
}

// endregion

// region component

/**
 */
export const HeaderBannerItemProperties = connect(
  mapStateToProps,
  {
    swapHeaderBanner: LandingPageStoreActions.swapHeaderBanner,
    deleteHeaderBanner: LandingPageStoreActions.deleteHeaderBanner,
    addHeaderBanner: LandingPageStoreActions.addHeaderBanner,
    updateHeaderBanner: LandingPageStoreActions.updateHeaderBanner,
    confirmPopup: ConfirmStoreActions.popup,
    setSelectedHeaderBanner: LandingPageStoreActions.setSelectedHeaderBanner
  }
)(
  class extends React.Component<HeaderBannerItemProps> {
    private async handleDeleteClick() {
      const result = await this.props.confirmPopup(
        <TT ttid="delete-headerBanner.title">Confirm delete</TT>,
        <TT ttid="delete-headerBanner.text">Are you sure you want to delete this banner?</TT>,
        <TT>Yes</TT>,
        <TT>No</TT>
      );
      if (result) {
        this.props.deleteHeaderBanner(this.props.headerBanner.key);
      }
    }

    render() {
      const {
        headerBannerItems, swapHeaderBanner, addHeaderBanner, updateHeaderBanner, headerBanner, font,
        selectedHeaderBanner, setSelectedHeaderBanner
      } = this.props;
      const sortedHeaderBanners = UFSorted.sort(headerBannerItems);
      const currentIndex = sortedHeaderBanners.indexOf(headerBanner);
      const isSelected = headerBanner.key == selectedHeaderBanner;
      return (
        <PropertyGroup
          title={
            <span>
              <TT
                ttid="header-banner.item-title"
                map={{
                  '$index$': (currentIndex + 1).toString(),
                }}
              >
                Header banner $index$
              </TT>
              {
                (currentIndex == 0) && <span> (<TT ttid="header-banne.item-fixed">fixed</TT>)</span>
              }
            </span>
          }
          rightTitleContent={isSelected ? <MonitorIcon/> : null}
        >
          <Stack direction="row" spacing={0} gap={Config.propertiesGapSize}>
            <Stack direction="column" spacing={0} gap={Config.propertiesGapSize}>
              <ApplicationButton
                startIcon={<ArrowUpwardIcon/>}
                sx={{
                  justifyContent: 'flex-start',
                  whiteSpace: 'nowrap'
                }}
                disabled={currentIndex <= 1}
                onClick={() => swapHeaderBanner(headerBanner.key, sortedHeaderBanners[currentIndex - 1].key)}
              >
                <TT ttid="actions.move-up">Move up</TT>
              </ApplicationButton>
              <ApplicationButton
                startIcon={<ArrowDownwardIcon/>}
                sx={{
                  justifyContent: 'flex-start',
                  whiteSpace: 'nowrap'
                }}
                disabled={(currentIndex >= sortedHeaderBanners.length - 1) || (currentIndex < 1)}
                onClick={() => swapHeaderBanner(headerBanner.key, sortedHeaderBanners[currentIndex + 1].key)}
              >
                <TT ttid="actions.move-down">Move down</TT>
              </ApplicationButton>
            </Stack>
            <Stack
              direction="row"
              spacing={0}
              flexWrap="wrap"
              alignItems="flex-start"
              gap={Config.propertiesGapSize}
            >
              <ApplicationButton
                startIcon={<ContentCopyIcon/>}
                disabled={(currentIndex == 0) || (sortedHeaderBanners.length >= Config.maxHeaderBanners)}
                onClick={() => addHeaderBanner(LandingPageModuleTools.copyHeaderBanner(headerBanner))}
              >
                <TT ttid="actions.add-copy">Add a copy</TT>
              </ApplicationButton>
              <ApplicationButton
                startIcon={<MonitorIcon/>}
                disabled={isSelected}
                onClick={() => setSelectedHeaderBanner(headerBanner.key)}
              >
                <TT ttid="actions.select">Select</TT>
              </ApplicationButton>
              <ApplicationButton
                color="error"
                startIcon={<DeleteOutlineRoundedIcon/>}
                disabled={currentIndex == 0}
                onClick={() => this.handleDeleteClick()}
              >
                <TT>Delete</TT>
              </ApplicationButton>
            </Stack>
          </Stack>
          <HtmlTextField
            label={<TT>Description</TT>}
            value={headerBanner.text}
            fontFamily={font.family}
            fontSize={font.size}
            onChange={(value) => updateHeaderBanner({...headerBanner, text: value})}
          />
          <ImageFitSelect
            value={headerBanner.imageFit}
            onChange={(value) => updateHeaderBanner({...headerBanner, imageFit: value})}
          />
          <ImageField
            value={headerBanner.image}
            onChange={(value) => updateHeaderBanner({...headerBanner, image: value})}
          />
        </PropertyGroup>
      );
    }
  }
);

// endregion