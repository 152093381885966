import {UFRedux} from "../../../UF-react/tools/UFRedux";
import {ReducerActionType} from "../../ReducerActionType";
import * as React from "react";
import {ThunkAction} from "redux-thunk";
import {ApplicationState} from "../../ApplicationState";
import {Action} from "redux";

export class SelectImageStoreActions {
  /**
   * Shows the select image popup
   *
   * @param aCallback
   *   Callback to call with choice
   */
  static show(aCallback: (value: string) => void) {
    return UFRedux.reducerAction<ReducerActionType.ShowSelectImage>(ReducerActionType.ShowSelectImage)({
      callback: aCallback,
    });
  }

  /**
   * Hides the select image popup.
   */
  static hide() {
    return UFRedux.reducerAction<ReducerActionType.HideSelectImage>(ReducerActionType.HideSelectImage)({});
  }

  /**
   * Thunk action to perform an image select.
   *
   * @returns A function that expects a dispatch parameter and returns a Promise.
   */
  static popup = (): ThunkAction<Promise<string | false>, ApplicationState, null, Action<ReducerActionType>> =>
      dispatch => new Promise<string | false>(resolve => {
    dispatch(SelectImageStoreActions.show(
      value => {
        dispatch(SelectImageStoreActions.hide());
        resolve(value);
      },
    ));
  });

}