// region imports

import * as React from 'react';
import {TT} from "../TT";
import {BusyPage} from "./BusyPage";

// endregion

// region component

/**
 * {@link StartPage} is the starting page, shown while the app is initializing.
 */
export const StartPage: React.FC = () => <BusyPage
  title={<TT ttid="start-page.title">Starting application</TT>}
  text={<TT ttid="start-page.content">One moment please, busy initializing</TT>}
/>;

// endregion
