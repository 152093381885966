// region imports

import * as React from 'react';
import {LandingPageColors} from "../../../types/LandingPageColors";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {Box} from "@mui/material";
import {ModuleBaseProps} from "../../../types/ModuleBaseProps";
import {LandingPageBorder} from "../../../types/LandingPageBorder";
import {LandingPageFont} from "../../../types/LandingPageFont";
import {ViewMode} from "../../../constants/ViewMode";
import {LandingPageDropShadow} from "../../../types/LandingPageDropShadow";
import {LandingPageButton} from "../parts/LandingPageButton";

// endregion

// region local types and functions

/**
 * Props for the component
 */
interface ButtonModuleProps extends ModuleBaseProps {
  colors: LandingPageColors;
  border: LandingPageBorder;
  font: LandingPageFont;
  viewMode: ViewMode;
  dropShadow: LandingPageDropShadow;
}

/**
 * Maps store state to component properties.
 */
function mapStatsToProps(anApplicationState: ApplicationState) {
  return {
    colors: anApplicationState.landingPage.colors,
    border: anApplicationState.landingPage.border,
    font: anApplicationState.landingPage.font,
    viewMode: anApplicationState.editor.viewMode,
    dropShadow: anApplicationState.landingPage.dropShadow
  }
}

// endregion

// region component

/**
 * The top bar in the landing page.
 */
export const ButtonModule = connect(mapStatsToProps)(
  ({module, colors, font, border, viewMode, dropShadow}: ButtonModuleProps) => {
    return (
      <Box
        display="flex"
        justifyContent="center"
      >
        <LandingPageButton
          caption={module.text}
          border={border}
          colors={colors}
          viewMode={viewMode}
          dropShadow={dropShadow}
        />
      </Box>
    )
  }
);

// endregion

