// region imports

import {combineReducers} from "redux";
import {ApplicationState} from "./ApplicationState";
import {dialogsReducer} from "./dialogs/dialogReducer";
import {userReducer} from "./user/userReducer";
import {landingPageReducer} from "./landingPage/landingPageReducer";
import {mainReducer} from "./main/mainReducer";
import {settingsReducer} from "./settings/settingsReducer";
import {networkReducer} from "./network/networkReducer";
import {editorReducer} from "./editor/editorReducer";
import {credentialsReducer} from "./credentials/credentialsReducer";

// endregion

// region types

/**
 * The combination of all reducers.
 */
export const appReducer = combineReducers<ApplicationState>({
  dialogs: dialogsReducer,
  user: userReducer,
  landingPage: landingPageReducer,
  main: mainReducer,
  settings: settingsReducer,
  network: networkReducer,
  editor: editorReducer,
  credentials: credentialsReducer
});
