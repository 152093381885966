import {UFRedux} from "../../../UF-react/tools/UFRedux";
import {ReducerActionType} from "../../ReducerActionType";

export class TestStoreActions {
  /**
   * Shows the test popup
   */
  static show() {
    return UFRedux.reducerAction<ReducerActionType.ShowTest>(ReducerActionType.ShowTest)({});
  }

  /**
   * Hides the test popup.
   */
  static hide() {
    return UFRedux.reducerAction<ReducerActionType.HideTest>(ReducerActionType.HideTest)({});
  }
}