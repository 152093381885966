// region imports

import * as React from 'react';
import {ViewMode} from "../../../constants/ViewMode";
import {LandingPageColors} from "../../../types/LandingPageColors";
import {ApplicationState} from "../../../store/ApplicationState";
import {connect} from "react-redux";
import {Box} from "@mui/material";
import {ModuleBaseProps} from "../../../types/ModuleBaseProps";
import {LandingPageBorder} from "../../../types/LandingPageBorder";
import {LandingPageDropShadow} from "../../../types/LandingPageDropShadow";
import {StylingTools} from "../../../tools/StylingTools";
import {LandingPageImage} from "../parts/LandingPageImage";
import {Config} from "../../../constants/Config";
import {TextBlock} from "../parts/TextBlock";

// endregion

// region local types and functions

/**
 * Props for the component
 */
interface TwoImagesAndTextsModuleProps extends ModuleBaseProps {
  colors: LandingPageColors;
  border: LandingPageBorder;
  dropShadow: LandingPageDropShadow;
  viewMode: ViewMode;
}

/**
 * Maps store state to component properties.
 */
function mapStatsToProps(anApplicationState: ApplicationState) {
  return {
    colors: anApplicationState.landingPage.colors,
    border: anApplicationState.landingPage.border,
    dropShadow: anApplicationState.landingPage.dropShadow,
    viewMode: anApplicationState.editor.viewMode
  }
}

// endregion

// region component

/**
 * The top bar in the landing page.
 */
export const TwoImagesAndTextsModule = connect(mapStatsToProps)(
  ({module, viewMode, moduleBackground, colors, border, dropShadow}: TwoImagesAndTextsModuleProps) => {
    const atDesktop = viewMode == ViewMode.Desktop;
    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: atDesktop ? '1fr 1fr' : '1fr',
          gridTemplateRows: atDesktop ? '358px auto' : '280px auto 280px auto',
          gridTemplateAreas: atDesktop
            ? '"left-image right-image" "left-text right-text"'
            : '"left-image" "left-text" "right-image" "right-text"',
          gap: Config.contentSpacing + 'px',
        }}
      >
        <Box
          sx={{
            gridArea: 'left-image',
            height: '100%',
            borderRadius: border.radius + 'px',
            overflow: 'hidden',
            boxShadow: StylingTools.getBoxShadow(dropShadow)
          }}
        >
          <LandingPageImage/>
        </Box>
        <Box
          sx={{
            gridArea: 'left-text',
          }}
        >
          <TextBlock
            title={module.title}
            text={module.text}
            colors={colors}
            border={border}
            dropShadow={dropShadow}
            moduleBackground={moduleBackground}
          />
        </Box>
        <Box
          sx={{
            gridArea: 'right-image',
            height: '100%',
            borderRadius: border.radius + 'px',
            overflow: 'hidden',
            boxShadow: StylingTools.getBoxShadow(dropShadow)
          }}
        >
          <LandingPageImage/>
        </Box>
        <Box
          sx={{
            gridArea: 'right-text',
          }}
        >
          <TextBlock
            title={module.title2}
            text={module.text2}
            colors={colors}
            border={border}
            dropShadow={dropShadow}
            moduleBackground={moduleBackground}
          />
        </Box>
      </Box>
    )
  }
);

// endregion

