/**
 * {@link StylingTools} defines static methods to support styling
 */
import {ModuleBackgroundType} from "../constants/ModuleBackgroundType";
import {LandingPageColors} from "../types/LandingPageColors";
import {darken, getLuminance, lighten, mix} from "color2k";
import parse from 'html-react-parser';
import {LandingPageDropShadow} from "../types/LandingPageDropShadow";
import {TextAlignType} from "../constants/TextAlignType";
import {Config} from "../constants/Config";
import {DomId} from "../constants/DomId";
import {FontFamily} from "../constants/FontFamily";

export class StylingTools {
  /**
   * Gets the styling for a selected top bar button.
   *
   * @param {boolean} aSelected
   *   True to return styling or false to return empty object.
   *
   * @returns {object} styling
   */
  static getSelectedTopButton(aSelected: boolean = true): object {
    return aSelected
      ? {
        backgroundColor: 'background.default',
        color: 'primary.main',
        borderColor: 'background.default',
        pointerEvents: 'none'
      }
      : {
      };
  }

  /**
   * Gets the background color to use with modules or elements in the module. It is either the default color (white) or
   * a very lightened version of the background color.
   *
   * @param {ModuleBackgroundType} aType
   *   Type to determine color
   * @param {LandingPageColors} aColors
   *   Structure to get background color from
   *
   * @returns {string} Color
   */
  static getModuleBackground(aType: ModuleBackgroundType, aColors: LandingPageColors): string
  {
    return aType == ModuleBackgroundType.Default ? 'white' : mix(aColors.background, '#ffffff', 0.9);
  }

  /**
   * Gets the opposite background color to the background color used in the module.
   *
   * @param {ModuleBackgroundType} aType
   *   Type to determine color
   * @param {LandingPageColors} aColors
   *   Structure to get background color from
   *
   * @returns {string} Color
   */
  static getReverseModuleBackground(aType: ModuleBackgroundType, aColors: LandingPageColors): string
  {
    return aType != ModuleBackgroundType.Default ? 'white' : mix(aColors.background, '#ffffff', 0.9); //transparentize(aColors.background, 0.9);
  }

  /**
   * Gets a text color to use with a background color.
   *
   * @param {string} aBackgroundColor
   *   Color to get text color for.
   *
   * @returns {string} Css color.
   */
  static getTextColor(aBackgroundColor: string): string
  {
    return getLuminance(aBackgroundColor) < 0.18 ? '#ffffff' : '#222222';
  }

  /**
   * Gets the color to use for hover effect.
   *
   * @param {string} aColor
   *
   * @returns {string}
   */
  static getHoverColor(aColor: string): string
  {
    return getLuminance(aColor) < 0.18 ? lighten(aColor, 0.2) : darken(aColor, 0.2);
  }

  /**
   * Gets the background color to use with the bottom area using the current background color.
   *
   * @param {LandingPageColors} aColors
   *   Current colors.
   *
   * @returns {string} Css color.
   */
  static getBottomBackgroundColor(aColors: LandingPageColors): string
  {
    return mix(aColors.background, '#ffffff', 0.4); // transparentize(aColors.background, 0.4);
  }

  /**
   * Parses a html and return structure for use within React.
   *
   * @param {string|null|undefined} aText
   *   Text
   * @param {string} aDefault
   *   Default text to use if Text is null or undefined
   *
   * @returns {ReturnType<typeof domToReact>}
   */
  static parseHtml(aText?: string, aDefault: string = '')
  {
    return parse(aText || aDefault)
  }

  /**
   * Gets the css box shadow text for a drop shadow setting.
   *
   * @param {LandingPageDropShadow} aDropShadow
   * @returns {string}
   */
  static getBoxShadow(aDropShadow: LandingPageDropShadow): string
  {
    if (!aDropShadow.enabled || ((aDropShadow.size + aDropShadow.blurSize) <= 0)) {
      return 'none';
    }
    return `${aDropShadow.left}px ${aDropShadow.top}px ${aDropShadow.blurSize}px ${aDropShadow.size}px ${aDropShadow.color}`;
  }

  /**
   * Gets the css text align value for the text align type.
   *
   * @param {TextAlignType} aTextAlign
   * @returns {string}
   */
  static getTextAlign(aTextAlign: TextAlignType): string
  {
    switch(aTextAlign) {
      case TextAlignType.Center:
        return 'center';
      case TextAlignType.Right:
        return 'right';
      default:
        return 'left';
    }
  }

  /**
   * Returns the current width of the landing page view (without scrollbars).
   *
   * @returns {number}
   */
  static getLandingPageViewWidth(): number {
    const element = document.getElementById(DomId.LandingPageView);
    return element ? element.clientWidth : Config.desktopWidth;
  }

  /**
   * Returns the current width of the landing page view (without scrollbars).
   *
   * @returns {number}
   */
  static getLandingPageViewHeight(): number {
    const element = document.getElementById(DomId.LandingPageView);
    return element ? element.clientHeight : 300;
  }

  /**
   * Gets the font family for use with css.
   *
   * @param {FontFamily} aFamily
   *
   * @returns {string}
   */
  static getFontFamily(aFamily: FontFamily): string {
    switch(aFamily) {
      case FontFamily.Verdana:
        return 'Verdana';
      case FontFamily.Arial:
        return 'Arial';
      case FontFamily.OpenSans:
        return 'Open Sans';
      case FontFamily.Montserrat:
        return 'Montserrat';
      case FontFamily.Roboto:
        return 'Roboto';
      case FontFamily.RobotoSlab:
        return 'Roboto Slab';
      default:
        return '_sans';
    }
  }

}