// region imports

import {PropertyGroup} from "../PropertyGroup";
import * as React from "react";
import {UFFormTextField} from "../../../UF-mui/components/form/UFFormTextField";
import {TT} from "../../TT";
import {connect} from "react-redux";
import {ApplicationState} from "../../../store/ApplicationState";
import {HtmlTextField} from "../../form/HtmlTextField";
import {LandingPageStoreActions} from "../../../store/landingPage/LandingPageStoreActions";
import {LandingPageTemplate} from "../../../types/LandingPageTemplate";
import {FontFamily} from "../../../constants/FontFamily";
import {UFFormSwitch} from "../../../UF-mui/components/form/UFFormSwitch";

// endregion

// region local types and functions

/**
 * Properties for the component.
 */
interface LandingPageTemplatePropertiesProps {
  template: LandingPageTemplate;
  setTemplate: typeof LandingPageStoreActions.setTemplate;
}

/**
 * Maps application state to component properties.
 */
function mapStateToProps(anApplicationState: ApplicationState) {
  return {
    template: anApplicationState.landingPage.template
  };
}

// endregion

// region component

/**
 * The landing page template properties.
 */
export const LandingPageTemplateProperties = connect(
  mapStateToProps,
  {
    setTemplate: LandingPageStoreActions.setTemplate
  }
)(
  ({setTemplate, template}: LandingPageTemplatePropertiesProps) => (
    <PropertyGroup title={<TT ttid="template-properties.title">Template</TT>}>
      <UFFormSwitch
        label={<TT ttid="template-properties.available">Template is available for webmasters</TT>}
        checked={template.available}
        onFieldChange={({value}) => setTemplate({...template, available: value})}
      />
      <UFFormTextField
        label={<TT ttid="template-properties.title-label">Title</TT>}
        value={template.title}
        onFieldChange={({value}) => setTemplate({...template, title: value})}
      />
      <HtmlTextField
        label={<TT ttid="template-properties.description">Description</TT>}
        value={template.description}
        onChange={value => setTemplate({...template, description: value})}
        fontFamily={FontFamily.Roboto}
        fontSize={14}
      />
    </PropertyGroup>
  )
);
