// region imports

import {ApplicationDialogState} from "./ApplicationDialogState";
import {ApplicationDialogType} from "../../../constants/ApplicationDialogType";
import {ApplicationDialogStoreActions} from "./ApplicationDialogStoreActions";
import {ReducerActionType} from "../../ReducerActionType";

// endregion

// region local types and constants

/**
 * Initial application dialog state.
 */
const initialState: ApplicationDialogState = {
  dialog: ApplicationDialogType.None
};

// endregion

// region local types

/**
 * All application dialog actions.
 */
type ApplicationDialogAction = ReturnType<typeof ApplicationDialogStoreActions.show>
  | ReturnType<typeof ApplicationDialogStoreActions.hide>;

// endregion

// region exports

/**
 * Reduces the action with an application dialog store.
 *
 * @param aState
 *   Current state
 * @param anAction
 *   Action to reduce
 *
 * @return new state
 */
export function applicationDialogReducer(
  aState: ApplicationDialogState = initialState, anAction: ApplicationDialogAction
): ApplicationDialogState {
  switch(anAction.type) {
    case ReducerActionType.HideApplicationDialog:
      return {
        ...aState,
        dialog: ApplicationDialogType.None
      };
    case ReducerActionType.ShowApplicationDialog:
      return {
        ...aState,
        dialog: anAction.dialog
      };
    default:
      return aState;
  }
}
