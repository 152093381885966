// region imports

import * as React from 'react';
import {Box, Stack} from "@mui/material";
import {LandingPageContainer} from "../landingPage/LandingPageContainer";
import {PropertiesView} from "./parts/PropertiesView";
import {DomId} from "../../constants/DomId";
import {EditorTopBar} from "../applicationTopBar/EditorTopBar";

// endregion

// region component

/**
 * {@link EditorPage} is the main page.
 *
 * @constructor
 */
export const EditorPage: React.FC = () => (
  <Stack
    direction="column"
    height="100%"
  >
    <EditorTopBar/>
    <Stack
      direction="row"
      spacing={0}
      flex={1}
      height="1%"
    >
      <Box
        id={DomId.LandingPageView}
        sx={{
          flex: 1,
          overflow: 'auto',
          background: '#333333',
          textAlign: 'center'
        }}
      >
        <LandingPageContainer/>
      </Box>
      <Box
        sx={{
          flex: '0 0 400px',
          overflowX: 'hidden',
          overflowY: 'auto'
        }}
      >
        <PropertiesView/>
      </Box>
    </Stack>

  </Stack>

);

// endregion
