// region imports

import * as React from 'react';
import {FontFamily} from "../../constants/FontFamily";
import {UFFormTextField} from "../../UF-mui/components/form/UFFormTextField";
import {connect} from "react-redux";
import {UFText} from "../../UF/tools/UFText";
import {EditStoreActions} from "../../store/dialogs/edit/EditStoreActions";
import {StylingTools} from "../../tools/StylingTools";

// endregion

// region local types and functions

/**
 * Properties for the component
 */
interface HtmlTextFieldProps {
  /**
   * Label to show above input field
   */
  label: React.ReactNode,

  /**
   * Value to edit
   */
  value: string;

  /**
   * Callback that is called whenever the value changes.
   */
  onChange: (value: string) => void;

  /**
   * Color for text in the html editor
   */
  textColor?: string;

  /**
   * Color for background in the html editor
   */
  backgroundColor?: string;

  /**
   * Font family to use in the html editor
   */
  fontFamily: FontFamily;

  /**
   * Size of font in the html editor
   */
  fontSize: number;

  /**
   * When true allow user to change size of font
   */
  showFontSize?: boolean;

  /**
   * Store callback, should not be set directly
   */
  showEdit: typeof EditStoreActions.show;
}

// endregion

// region component

/**
 * {@link HtmlTextField} shows an input field to edit a html formatted text. The input field will show a plain version
 * of the text. Clicking it will show a dialog with rich text editor to change the html formatted text.
 */
export const HtmlTextField = connect(null, {showEdit: EditStoreActions.show})(
  ({
     label, value, onChange, textColor = '#222222', backgroundColor = '#ffffff', fontFamily, fontSize,
     showFontSize = false, showEdit
   }: HtmlTextFieldProps
  ) => (
    <UFFormTextField
      label={label}
      value={UFText.convertToPlain(value)}
      readOnly={true}
      ellipsis={true}
      fontFamily={StylingTools.getFontFamily(fontFamily)}
      fontSize={fontSize + 'px'}
      onClick={() => showEdit(
        onChange,
        value,
        textColor,
        backgroundColor,
        fontFamily,
        fontSize,
        showFontSize
      )}
    />
  )
);

// endregion