// region imports

import * as React from 'react';
import {UFFormTextField} from "../../UF-mui/components/form/UFFormTextField";
import {TT} from "../TT";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

// endregion

// region local types and functions

interface CodeFieldProps {
  readonly value: string;
  readonly onChange: (value: string) => any;
  readonly disabled?: boolean;
  readonly label: React.ReactNode;
  readonly autoFocus?: boolean;
  readonly error?: boolean;
  readonly required?: boolean;
  readonly sx?: SxProps<Theme>;
}

// endregion

// region component

export const CodeField: React.FC<CodeFieldProps> = (
  {
    value,
    onChange,
    label,
    disabled = false,
    autoFocus = false,
    error = false,
    required = false,
    sx = {}
  }
) => (
  <UFFormTextField
    label={label}
    onFieldChange={({value}) => onChange(value.toLowerCase().replace(/[^a-z_\-\d]/g, ''))}
    value={value}
    disabled={disabled}
    autoFocus={autoFocus}
    error={error}
    required={required}
    helperText={
      <TT ttid="code-input.help">
        A code can exist of a mix of lowercase letters, numbers, '-' and '_'.
      </TT>
    }
    sx={sx}
  />
);

// endregion